import School from '@mui/icons-material/School'
import DiscussIcon from '@mui/icons-material/Chat'
import makeStyles from '@mui/styles/makeStyles'
import VoteIcon from '@mui/icons-material/ThumbUpAlt'
import VisibilityIcon from '@mui/icons-material/Visibility'
import {
  MainContainer,
  HR,
  InnerContainer,
  CourseContainer,
  Course,
  FlexStartPlaceholder,
  FlexWrapper,
  DiscussionWidget,
  Count,
  LikesWidget
} from './styled/take-metrics-comps'

const useStyles = makeStyles((theme) => ({
  checkedIcon: {
    color: theme.palette.secondary.main,
    marginTop: '.1rem',
    marginRight: '.4rem',
    fontSize: '1.2rem'
  },
  actionIcon: {
    color: theme.palette.secondary.main,
    marginTop: '.1rem',
    marginRight: '.4rem',
    fontSize: '1.2rem'
  }
}))

function TakeMetrics(props) {
  const { numDiscussions, numVotes, viewCount, courseId, course } = props
  const classes = useStyles()

  const subject = course?.subject || ''
  const number = course?.courseNumber || ''

  return (
    <MainContainer>
      <HR />

      <InnerContainer>
        {courseId !== '' ? (
          <CourseContainer>
            <School className={classes.actionIcon} role="img" />
            <Course>{subject.split('-')[0] + ' ' + number}</Course>
          </CourseContainer>
        ) : (
          <FlexStartPlaceholder />
        )}

        <FlexWrapper>
          {viewCount > 0 && (
            <DiscussionWidget>
              <VisibilityIcon className={classes.checkedIcon} role="img" />
              <Count>{viewCount.toString()}</Count>
            </DiscussionWidget>
          )}
          <DiscussionWidget>
            <DiscussIcon className={classes.checkedIcon} role="img" />
            <Count>{numDiscussions.toString()}</Count>
          </DiscussionWidget>

          <LikesWidget>
            <VoteIcon className={classes.actionIcon} role="img" />
            <Count>{numVotes.toString()}</Count>
          </LikesWidget>
        </FlexWrapper>
      </InnerContainer>
    </MainContainer>
  )
}

export default TakeMetrics
