import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { updateProfileTermsByUserID } from '../../../../redux/thunks/profiles/updateProfileTermsByUserId'
import FormError from '../../../alerts/form-error'
import AppBar from '@mui/material/AppBar'
import Checkbox from '@mui/material/Checkbox'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'

function TermsOfUseForm({ version }) {
  const [checked, setChecked] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [message, setMessage] = useState('')
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleChangeCheckbox = () => {
    setChecked(!checked)
  }

  const handleSubmit = (e) => {
    setLoading(true)
    e.preventDefault()
    dispatch(
      updateProfileTermsByUserID({
        fieldsToUpdate: { hasAcceptedTerms: true, acceptedVersion: version }
      })
    ).then((data) => {
      if (!data.error) {
        console.log(`*************************`)
        console.log(`*** GO HOME      ********`)
        console.log(`*************************`)
        navigate('/home')
      } else {
        console.log('ERROR:', data)
        setError(true)
        setMessage(data.message)
      }
    })
    setLoading(false)
  }

  return (
    <MuiAppBar
      position="fixed"
      color="primary"
      sx={{
        backgroundColor: '#abcab3 !important' // Override any theme colors
      }}>
      {error && <FormError message={message} style={{ textAlign: 'center' }} />}
      <form onSubmit={(e) => handleSubmit(e)}>
        <MuiFormControl component="fieldset" sx={{}}>
          {/* <FormLabel component="legend">Terms of Service Agreement</FormLabel> */}
          <FormGroup aria-label="position" row sx={{ padding: 1 }}>
            <FormControlLabel
              sx={{ fontSize: 1 }}
              value="test"
              control={<Checkbox color="primary" onChange={handleChangeCheckbox} />}
              label={
                <MuiTypography variant="h6">
                  I agree to the terms of use, privacy policy and copyright policy:
                </MuiTypography>
              }
              labelPlacement="end"
            />
          </FormGroup>
          <Button
            // color="primary"
            variant="contained"
            disabled={checked === false}
            // disabled
            type="submit"
            sx={{
              fontSize: '1.25rem',
              fontWeight: 600,
              color: 'text.primary',
              width: '15rem',
              margin: '1rem 0'
            }}>
            Submit
          </Button>
        </MuiFormControl>
      </form>
    </MuiAppBar>
  )
}

export default TermsOfUseForm

// ***************************** //
// *** MUI Styled Components *** //
// ***************************** //
const MuiAppBar = styled(AppBar)(({ theme }) => ({
  top: 'auto',
  bottom: 0,
  padding: '0.5rem 0',
  justifyContent: 'center',
  alignItems: 'center',
  '@media (max-width: 700px)': {
    padding: '0.5rem 0'
  }
}))

const MuiFormControl = styled(FormControl)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0 2rem'
}))

const MuiTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: '1.5rem',
  fontWeight: 600,
  margin: 2,
  '@media (max-width: 700px)': {
    fontSize: '1rem'
  }
}))

const MuiCheckbox = styled(Checkbox)(({ theme }) => ({
  '&.Mui-checked': {
    color: theme.palette.primary.main
  }
}))

const MuiFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  margin: theme.spacing(0.5)
}))

const MuiButton = styled(Button)(({ theme }) => ({}))
