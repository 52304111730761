import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import load from './functions/load'
import { getRandomQuote } from '../../../redux/thunks/quotes/getRandomQuote'
import SkeletonQuote from '../../skeletons/skeleton-quote'
import LeftQuoteIcon from '../../../media/icons/left-quote.png'
import {
  MainContainer,
  HeadingPrimary,
  QuoteContainer,
  QuoteText,
  QuoteName
} from './styled/quote-comps'

const Quote = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [quote, setQuote] = useState(null)
  const dispatch = useDispatch()

  useEffect(() => {
    const loadActions = {
      getRandomQuote: () => dispatch(getRandomQuote())
    }

    load(setQuote, setIsLoading, loadActions)
  }, [dispatch])

  if (!isLoading && !quote) return null

  return (
    <MainContainer>
      <HeadingPrimary>
        Today's <span>Mindful</span> Message
      </HeadingPrimary>

      {isLoading ? (
        <SkeletonQuote style={{ marginTop: '1rem' }} />
      ) : (
        <QuoteContainer>
          <QuoteText>
            <img src={LeftQuoteIcon} alt="Open quotation icon" />"{quote.quote}"
          </QuoteText>

          <QuoteName>
            <i>{quote.author}</i>
          </QuoteName>
        </QuoteContainer>
      )}
    </MainContainer>
  )
}

export default Quote
