import React from 'react'
import { useSelector } from 'react-redux'
import { isLoaded, isEmpty } from 'react-redux-firebase'
// import { useLocation } from "react-router-dom"
import Listeners from './listeners'
import { Badge } from '@mui/material'
import { QuestionAnswer } from '@mui/icons-material'
import makeStyles from '@mui/styles/makeStyles'
import withStyles from '@mui/styles/withStyles'
import createStyles from '@mui/styles/createStyles'
// import { WebView, MobileView } from "./styled/discussions-comps"
import config from '../../../../config'

const useStyles = makeStyles((theme) =>
  createStyles({
    iconButton: {},
    iconButtonActive: {
      color: theme.palette.text.secondary
    },
    icon: {
      fontSize: '1.5rem',
      color: theme.palette.text.primary,
      [`@media (max-width:${config.UI.drawer_breakpoint.max})`]: {
        width: '1.8rem',
        height: '1.8rem'
      }
    },
    displayNone: {
      display: 'none'
    }
  })
)

const StyledBadge = withStyles(() =>
  createStyles({
    badge: {
      position: 'absolute',
      top: 0,
      right: '-0.125rem'
    }
  })
)(Badge)

function DiscussionsMobile() {
  const classes = useStyles()
  const authRef = useSelector((state) => state.firebase.auth)
  const unreadDiscussions = useSelector(
    ({
      firestore: {
        data: { userMeta }
      }
    }) =>
      (userMeta &&
        userMeta[authRef.uid] &&
        userMeta[authRef.uid].unreadDiscussions &&
        Object.values(userMeta[authRef.uid].unreadDiscussions).reduce((t, n) => t + n)) ||
      0
  )

  return (
    <>
      {isLoaded(authRef) && !isEmpty(authRef) && <Listeners auth={authRef} />}

      <StyledBadge
        badgeContent={unreadDiscussions}
        color="primary"
        overlap="circular"
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}>
        <QuestionAnswer className={classes.icon} />
      </StyledBadge>
    </>
  )
}

export default DiscussionsMobile
