import React, { Component } from 'react'
import { withTheme } from 'styled-components'
import { connect } from 'react-redux'
import loadHandler from './functions/load-handler'
import { getFollowing } from '../../../redux/thunks/follows/getFollowing'
import { getTakes } from '../../../redux/thunks/takes/getTakes'
import { getTakesByUserID } from '../../../redux/thunks/takes/getTakesByUserID'
import { getTakesByGuestUserID } from '../../../redux/thunks/takes/getTakesByGuestUserID'
import { getOrganizationsByIDs } from '../../../redux/thunks/organizations/getOrganizationsByIDs'
import { getHiddenTakes } from '../../../redux/thunks/takes/getHiddenTakes'
import { getDiscussions } from '../../../redux/thunks/discussions/getDiscussions'
import { getRebuttals } from '../../../redux/thunks/rebuttals/getRebuttals'
import { getProfilesByUserIDs } from '../../../redux/thunks/profiles/getProfilesByUserIDs'
import loadMoreCheck from '../../../utils/functions/loadMoreCheck'
import ActionBar from './components/action-bar'
import Post from '../post/post'
import SkeletonTake from '../../skeletons/skeleton-take'
import { MainContainer, NoResults } from './styled/posts-comps'
import config from '../../../config'

class Posts extends Component {
  constructor(props) {
    super(props)

    this.state = {
      status: {
        isLoading: true,
        isLoadingMore: false,
        moreToLoad: true,
        sortBy: '',
        filter: '',
        promptId: ''
      },
      tags: []
    }

    this.handleSetState = this.handleSetState.bind(this)
    this.setTags = this.setTags.bind(this)
    this.loadPosts = this.loadPosts.bind(this)
    this.trackScrolling = this.trackScrolling.bind(this)
    this.handleSortingChange = this.handleSortingChange.bind(this)
    this.handleFilterChange = this.handleFilterChange.bind(this)
    this.handleFilterByPrompt = this.handleFilterByPrompt.bind(this)
  }

  componentDidMount() {
    const { tags, initialFilter, preventInfiniteScroll } = this.props

    /**
     * func loadPosts
     * params: isLoadingComponent, afterDate, ""
     */
    this.loadPosts(true, Date.now(), tags, initialFilter)
      .then(() => {
        if (!preventInfiniteScroll) {
          document.addEventListener('scroll', this.trackScrolling)
        }
      })
      .catch((err) => {
        console.log('[Error]: ', 'Failed to load posts component.')
      })
  }

  // New Code - 30 Mar 2025
  componentDidUpdate(prevProps) {
    const { userId, tags, initialFilter } = this.props
    const { userId: prevUserId, tags: prevTags } = prevProps

    // Check if userId or tags have changed
    if (userId !== prevUserId || JSON.stringify(tags) !== JSON.stringify(prevTags)) {
      this.setState({
        ...this.state,
        status: { ...this.state.status, isLoading: true }
      })

      this.loadPosts(true, Date.now(), tags, initialFilter)
        .then(() => {
          if (!this.props.preventInfiniteScroll) {
            document.addEventListener('scroll', this.trackScrolling)
          }
        })
        .catch((err) => {
          console.log('[Error]: ', 'Failed to reload posts after userId change.')
        })
    }
  }

  componentWillUnmount() {
    const { preventInfiniteScroll } = this.props

    if (!preventInfiniteScroll) {
      document.removeEventListener('scroll', this.trackScrolling)
    }
  }

  handleSetState(object) {
    this.setState(object)
  }

  setTags(tags) {
    const { status } = this.state
    if (tags.length <= 5) {
      this.setState({
        ...this.state,
        status: { ...this.state.status, isLoading: true },
        tags: [...tags]
      })

      if (this.props.setTags) {
        this.props.setTags([...tags])
      }

      this.loadPosts(true, Date.now(), [...tags], status.sortBy, status.filter)
        .then(() => {
          document.addEventListener('scroll', this.trackScrolling)
        })
        .catch((error) => {
          console.log('[ERROR]: ', error)
        })
    }
  }

  loadPosts(isLoadingComponent, afterDate, tags, sortKey, filterKey, promptId) {
    const actions = {
      getFollowing: this.props.getFollowing,
      getTakes: this.props.getTakes,
      getTakesByUserID: this.props.getTakesByUserID,
      getTakesByGuestUserID: this.props.getTakesByGuestUserID,
      getOrganizationsByIDs: this.props.getOrganizationsByIDs,
      getHiddenTakes: this.props.getHiddenTakes,
      getDiscussions: this.props.getDiscussions,
      getRebuttals: this.props.getRebuttals,
      getProfilesByUserIDs: this.props.getProfilesByUserIDs
    }

    return loadHandler(
      { ...this.props, isLoadingComponent, afterDate, sortKey, filterKey, tags, promptId },
      this.state,
      this.handleSetState,
      actions
    )
  }

  trackScrolling() {
    const { loadedTakes, loadedDiscussions, variant, userId } = this.props
    const { status, tags } = this.state
    const el = document.getElementById('posts-list')

    if (status.moreToLoad) {
      if (el !== null && typeof el !== 'undefined' && loadedTakes.length > 0) {
        if (!status.isLoading && !status.isLoadingMore) {
          if (loadMoreCheck(el)) {
            document.removeEventListener('scroll', this.trackScrolling)

            let afterDate = loadedTakes[loadedTakes.length - 1].whenCreated

            if (variant == 'user-guest') {
              const profileDiscussions = loadedDiscussions
                .filter((d) => d.guestUserId === userId)
                .sort((a, b) => b - a)

              afterDate = profileDiscussions[profileDiscussions.length - 1].whenCreated
            }

            this.setState({
              ...this.state,
              status: {
                ...this.state.status,
                isLoadingMore: true
              }
            })

            this.loadPosts(false, afterDate, tags, status.sortBy, status.filter)
              .then(() => {
                document.addEventListener('scroll', this.trackScrolling)
              })
              .catch((error) => {
                console.log('[ERROR]: ', error)
              })
          }
        }
      }
    } else {
      document.removeEventListener('scroll', this.trackScrolling)
    }
  }

  handleSortingChange(sortBy) {
    document.removeEventListener('scroll', this.trackScrolling)

    const { status, tags } = this.state

    this.setState({
      ...this.state,
      status: {
        ...this.state.status,
        isLoading: true
      }
    })

    this.loadPosts(true, Date.now(), tags, sortBy, status.filter)
      .then(() => {
        document.addEventListener('scroll', this.trackScrolling)
      })
      .catch((error) => {
        console.log('[ERROR]: ', error)
      })
  }

  handleFilterChange(filter) {
    document.removeEventListener('scroll', this.trackScrolling)

    const { status, tags } = this.state

    this.setState({
      ...this.state,
      status: {
        ...this.state.status,
        isLoading: true
      }
    })

    this.loadPosts(true, Date.now(), tags, status.sortBy, filter)
      .then(() => {
        document.addEventListener('scroll', this.trackScrolling)
      })
      .catch((error) => {
        console.log('[ERROR]: ', error)
      })
  }

  handleFilterByPrompt(promptId) {
    document.removeEventListener('scroll', this.trackScrolling)

    const { status, tags } = this.state

    this.setState({
      ...this.state,
      status: {
        ...this.state.status,
        isLoading: true
      }
    })

    const filter = ''

    this.loadPosts(true, Date.now(), tags, status.sortBy, filter, promptId)
      .then(() => {
        document.addEventListener('scroll', this.trackScrolling)
      })
      .catch((error) => {
        console.log('[ERROR]: ', error)
      })
  }

  render() {
    const {
      isQuestion,
      hideActionBar,
      hideTags,
      hideTagRemove,
      hideTagsSearch,
      hideSorting,
      hideFilters,
      mixinComponents,
      hideOnNoResults,
      searchStyle,
      searchVariant,
      loadedTakes,
      variant,
      prompts
    } = this.props

    const { status, tags } = this.state
    const { isLoading, isLoadingMore } = status
    const compsToMixIn = mixinComponents || []

    const posts = loadedTakes.map((take) => {
      return <Post key={take._id} take={take} />
    })

    compsToMixIn.forEach((comp, i) => {
      if (!isLoading) {
        if (loadedTakes.length < config.loadLimits.posts) {
          posts.splice(Math.floor(posts.length / 2) + i, 0, comp)
        } else {
          posts.splice(Math.floor(config.loadLimits.posts / 2) + i, 0, comp)
        }
      }
    })

    if (!isLoading && hideOnNoResults && loadedTakes.length === 0) return null

    return (
      <MainContainer id="posts-list">
        {!hideActionBar && (
          <ActionBar
            isLoading={status.isLoading}
            hideSorting={hideSorting}
            hideFilters={hideFilters}
            hideTags={hideTags}
            hideTagRemove={hideTagRemove}
            hideTagsSearch={hideTagsSearch}
            searchStyle={searchStyle}
            searchVariant={searchVariant}
            sortBy={status.sortBy}
            filter={status.filter}
            promptId={status.promptId}
            handleSortingChange={this.handleSortingChange}
            handleFilterChange={this.handleFilterChange}
            handleFilterByPrompt={this.handleFilterByPrompt}
            tags={tags}
            setTags={this.setTags}
            variant={variant}
            prompts={prompts}
          />
        )}

        {!isLoading && (
          <div>
            {loadedTakes.length === 0 && (
              <NoResults>
                {isQuestion ? 'No questions found.' : ' No public posts found.'}
              </NoResults>
            )}

            {posts}
          </div>
        )}

        {(isLoading || isLoadingMore) && (
          <SkeletonTake count={3} color={this.props.theme.palette.secondary.light} />
        )}
      </MainContainer>
    )
  }
}

const mapStateToProps = (state) => ({
  currentProfile: state.currentProfile,
  loadedTakes: state.loadedTakes,
  loadedDiscussions: state.loadedDiscussions
})

const mapDispatchToProps = (dispatch) => ({
  getFollowing: () => {
    return dispatch(getFollowing())
  },
  getTakes: (params) => {
    return dispatch(getTakes(params))
  },
  getTakesByUserID: (params) => {
    return dispatch(getTakesByUserID(params))
  },
  getTakesByGuestUserID: (params) => {
    return dispatch(getTakesByGuestUserID(params))
  },
  getOrganizationsByIDs: (params) => {
    return dispatch(getOrganizationsByIDs(params))
  },
  getHiddenTakes: (params) => {
    return dispatch(getHiddenTakes(params))
  },
  getDiscussions: (params) => {
    return dispatch(getDiscussions(params))
  },
  getRebuttals: (params) => {
    return dispatch(getRebuttals(params))
  },
  getProfilesByUserIDs: (params) => {
    return dispatch(getProfilesByUserIDs(params))
  }
})

export default withTheme(connect(mapStateToProps, mapDispatchToProps)(Posts))
