import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import load from './functions/load'
import { getActivePolls } from '../../../redux/thunks/polls/getActivePolls'
import { createPollVote } from '../../../redux/thunks/poll-votes/createPollVote'
import SkeletonPoll from '../../skeletons/skeleton-poll'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import { CircularProgress } from '@mui/material'
import {
  MainContainer,
  HeadingPrimary,
  PollContainer,
  Subheading,
  VotingContainer,
  OptionContainer
} from './styled/polls-comps'
import theme from '../../../res/theme'

const getStyles = (didSubmitVote, results) => {
  let colors = {
    primary: {
      container: {},
      option: {},
      percent: {}
    },
    secondary: {
      container: {},
      option: {},
      percent: {}
    }
  }

  if (didSubmitVote) {
    if (results.isPrimary) {
      colors = {
        primary: {
          container: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.text.primary,
            cursor: 'default'
          },
          option: {
            color: 'white'
          },
          percent: {
            color: 'white'
          }
        },
        secondary: {
          container: {
            backgroundColor: '#eee',
            cursor: 'default'
          },
          option: {
            color: theme.palette.secondary.main
          },
          percent: {
            color: theme.palette.secondary.light
          }
        }
      }
    } else {
      colors = {
        secondary: {
          container: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.text.primary,
            cursor: 'default'
          },
          option: {
            color: 'white'
          },
          percent: {
            color: 'white'
          }
        },
        primary: {
          container: {
            backgroundColor: '#eee',
            color: 'white',
            cursor: 'default'
          },
          option: {
            color: theme.palette.secondary.main
          },
          percent: {
            color: theme.palette.secondary.light
          }
        }
      }
    }
  }

  return colors
}

const useStyles = makeStyles((theme) =>
  createStyles({
    circularProgress: {
      display: 'block',
      margin: '.8rem auto 0 auto',
      width: '24px !important',
      height: '24px !important',
      '& svg': {
        color: theme.palette.secondary.main
      }
    }
  })
)

const Polls = (props) => {
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(true)
  const [poll, setPoll] = useState(null)
  const [processing, setProcessing] = useState(false)
  const [didSubmitVote, setDidSubmitVote] = useState(false)
  const [results, setResults] = useState({
    primaryPercent: 0,
    secondaryPercent: 0,
    isPrimary: false
  })
  const polls = useSelector(({ polls }) => polls)
  const pollVotes = useSelector(({ pollVotes }) => pollVotes)
  const dispatch = useDispatch()
  const styles = getStyles(didSubmitVote, results)

  useEffect(() => {
    const loadActions = {
      getActivePolls: () => dispatch(getActivePolls())
    }

    load(setIsLoading, loadActions)
  }, [dispatch])

  useEffect(() => {
    if (polls.length > 0) {
      const poll = polls[0]

      setPoll({ ...poll })
    }
  }, [polls, pollVotes])

  const handleSubmit = (pollId, isPrimary) => {
    setProcessing(true)

    dispatch(
      createPollVote({
        pollVote: {
          pollId,
          isPrimary
        }
      })
    )
      .then((data) => {
        if (!data.error) {
          console.log('[SUCCESS]: ', 'Successfully created poll vote!')

          setDidSubmitVote(true)
          setResults({
            ...results,
            primaryPercent: data.primaryPercent,
            secondaryPercent: data.secondaryPercent,
            isPrimary
          })
        } else {
          console.log('[FAIL]: ', 'Failed to create poll vote.')
        }

        setProcessing(false)
      })
      .catch((e) => {
        setProcessing(false)
      })
  }

  if (!isLoading && poll === null) return null

  return (
    <MainContainer>
      <HeadingPrimary>
        Community <span>Pulse</span>
      </HeadingPrimary>

      {isLoading ? (
        <SkeletonPoll style={{ marginTop: '1rem' }} />
      ) : (
        <PollContainer>
          <Subheading>{poll.poll}</Subheading>

          <VotingContainer>
            <OptionContainer
              style={styles.primary.container}
              onClick={() => handleSubmit(poll._id, true)}>
              <p style={styles.primary.option}>{poll.primaryOption}</p>

              {didSubmitVote && <p style={styles.primary.percent}>{results.primaryPercent}%</p>}

              {processing && (
                <CircularProgress color="secondary" className={classes.circularProgress} />
              )}
            </OptionContainer>

            <OptionContainer
              style={styles.secondary.container}
              onClick={() => handleSubmit(poll._id, false)}>
              <p style={styles.secondary.option}>{poll.secondaryOption}</p>

              {didSubmitVote && !processing && (
                <p style={styles.secondary.percent}>{results.secondaryPercent}%</p>
              )}

              {processing && (
                <CircularProgress color="secondary" className={classes.circularProgress} />
              )}
            </OptionContainer>
          </VotingContainer>
        </PollContainer>
      )}
    </MainContainer>
  )
}

export default Polls
