import React, { Component } from 'react'
import { useLocation } from 'react-router-dom'

class InnerScrollToTop extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      // Scroll to top center of screen on page change
      window.scrollTo(window.innerWidth / 2 - 634, 0)
    }
  }

  render() {
    return this.props.children
  }
}

// ******************** //
// Wrapping Class component in a function component to get access to useLocation
// ******************** //

function ScrollToTop(props) {
  const location = useLocation()

  return <InnerScrollToTop {...props} location={location} />
}

export default ScrollToTop
