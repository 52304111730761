import { useState, useEffect, useContext, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import checkOrgPermissions from '../../../../../utils/functions/checkPermissions'
import { MainContainer, Header, Step } from './styled/side-nav-desktop-comps'
import LoadOrganizationRolesContext from '../../../../data-loading/load-organization-assigned-roles/context/context'

function SideNavDesktop() {
  const { orgId, tab } = useParams()
  const navigate = useNavigate()
  const { roles, assignedRoles } = useContext(LoadOrganizationRolesContext)
  const [section, setSection] = useState('')
  const currentUser = useSelector(({ currentUser }) => currentUser)

  useEffect(() => {
    if (tab) {
      setSection(tab)
    }
  }, [tab])

  const handleSelectTab = useCallback(
    (e) => {
      const targetId = e.currentTarget.id
      if (targetId) {
        navigate(`/organization/${orgId}/settings/${targetId}`)
      }
    },
    [navigate, orgId]
  )

  const hasAdminPermissions = checkOrgPermissions({
    assignedRoles,
    roles,
    currUserId: currentUser.id,
    permissions: [] // Admin only
  })

  const hasOrganizationDetailsPermissions = checkOrgPermissions({
    assignedRoles,
    roles,
    currUserId: currentUser.id,
    permissions: ['manageOrganizationDetails']
  })

  const hasRoleManagementPermissions = checkOrgPermissions({
    assignedRoles,
    roles,
    currUserId: currentUser.id,
    permissions: ['manageRoles', 'manageAssignedRoles']
  })

  const hasBillingPermissions = checkOrgPermissions({
    assignedRoles,
    roles,
    currUserId: currentUser.id,
    permissions: ['manageBilling']
  })

  return (
    <MainContainer>
      <Header>Organization Account</Header>

      <div>
        {hasAdminPermissions && (
          <Step
            id="account"
            onClick={handleSelectTab}
            style={{ marginTop: 0 }}
            current={section === 'account'}>
            Account Information
          </Step>
        )}

        {hasOrganizationDetailsPermissions && (
          <Step id="picture" onClick={handleSelectTab} current={section === 'picture'}>
            Profile Picture
          </Step>
        )}

        {hasOrganizationDetailsPermissions && (
          <Step id="details" onClick={handleSelectTab} current={section === 'details'}>
            Organization Details
          </Step>
        )}

        {hasOrganizationDetailsPermissions && (
          <Step id="location" onClick={handleSelectTab} current={section === 'location'}>
            Location Settings
          </Step>
        )}

        {hasRoleManagementPermissions && (
          <Step id="roles" onClick={handleSelectTab} current={section === 'roles'}>
            Role Management
          </Step>
        )}

        {/* hasBillingPermissions && (
          <Step id="billing" onClick={handleSelectTab} current={section === 'billing'}>
            Billing & Donations
          </Step>
        )} */}
      </div>
    </MainContainer>
  )
}

export default SideNavDesktop
