import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import NicknameForm from './components/nickname-form/nickname-form'
import EmailForm from './components/email-form/email-form'
import PhoneForm from './components/phone-form/phone-form'
import PasswordForm from './components/password-form/password-form'
import AccountActions from './components/account-actions/account-actions'
import isVerifiedUser from '../../../../../utils/functions/isVerifiedUser'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import { Button } from '@mui/material'

const useStyles = makeStyles((theme) => {
  return createStyles({
    verify: {
      padding: '1rem 1rem',
      width: '100%',
      minHeight: '3.2rem',
      marginTop: '2rem',
      boxSizing: 'border-box',
      fontSize: '1rem',
      fontWeight: 600,
      color: theme.palette.text.primary,
      '&:hover': {
        backgroundColor: theme.palette.primary.light
      },
      '@media (max-width: 450px)': {
        width: '100%'
      },
      '& .material-icons': {
        lineHeight: '1.1rem',
        margin: '.1rem .4rem 0 0',
        fontSize: '1.2rem'
      }
    }
  })
})

function AccountDetails() {
  const classes = useStyles()
  const currentProfile = useSelector(({ currentProfile }) => currentProfile)
  const navigate = useNavigate()
  return (
    <>
      <NicknameForm />

      <EmailForm />
      {/* 
      <PhoneForm />

      <PasswordForm /> */}

      <AccountActions />

      {!isVerifiedUser(currentProfile) && (
        <Button
          variant="contained"
          color="primary"
          className={classes.verify}
          onClick={() => navigate('/verify')}>
          <span className="material-icons">lock_open</span>
          <span>Verify</span>
        </Button>
      )}
    </>
  )
}

export default AccountDetails
