import React, { useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import _ from 'lodash'
import Slider from '../slider/slider'
import load from './functions/load'
import { getSuggestedConnections } from '../../../redux/thunks/profiles/getSuggestedConnections'
import { updateProfileByUserID } from '../../../redux/thunks/profiles/updateProfileByUserID'
import SkeletonConnection from '../../skeletons/skeleton-connection'
import Tags from '../../partials/tags/tags'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import { IconButton } from '@mui/material'
import { Close } from '@mui/icons-material'
import {
  MainContainer,
  HeadingPrimary,
  HeadingSecondary,
  Subheading
} from './styled/suggested-connections-comps'

const useStyles = makeStyles(() =>
  createStyles({
    iconButton: {
      position: 'absolute',
      top: '.5rem',
      right: '.5rem'
    }
  })
)

function SuggestedConnections(props) {
  const {
    tagIds,
    disableFromInterests,
    disableFromInsights,
    disableRandomSuggestions,
    hideOnNoResults,
    hidePrimaryHeading,
    hideSecondaryHeading,
    // hideSubheading,
    customHeading,
    customSecondaryHeading,
    customSubheading,
    showCloseButton,
    style
  } = props
  const classes = useStyles()
  const newStyles = style || {}
  const [tags, setTags] = useState(tagIds && [...tagIds])
  const [userIds, setUserIds] = useState({ random: [], fromInterests: [], fromInsights: [] })
  const [profiles, setProfiles] = useState({ random: [], fromInterests: [], fromInsights: [] })
  const [isLoading, setIsLoading] = useState(true)
  const currentProfile = useSelector(({ currentProfile }) => currentProfile)
  const suggestedProfiles = useSelector(({ suggestedProfiles }) => suggestedProfiles)
  const dispatch = useDispatch()
  const showFromInterests =
    !disableFromInterests &&
    currentProfile.tagsOfInterest.length > 0 &&
    userIds.fromInterests.length > 0
  const showFromInsights =
    !disableFromInsights && currentProfile.tagsOfKnowledge && userIds.fromInsights.length > 0
  const showRandom =
    !disableRandomSuggestions &&
    userIds.random.length > 0 &&
    (userIds.fromInterests.length === 0 || userIds.fromInsights.length === 0)

  const loadConnections = useCallback(
    (tags) => {
      const loadActions = {
        getSuggestedConnections: (params) => dispatch(getSuggestedConnections(params))
      }

      load(
        tags,
        disableFromInterests,
        disableFromInsights,
        disableRandomSuggestions,
        setIsLoading,
        setUserIds,
        setTags,
        loadActions
      )
    },
    [disableFromInsights, disableFromInterests, disableRandomSuggestions, dispatch]
  )

  useEffect(() => {
    if (isLoading) {
      loadConnections(tags)
    }
  }, [isLoading, loadConnections, tags])

  useEffect(() => {
    const newProfiles = {
      random: userIds.random.map((id) => {
        const profile = _.find(suggestedProfiles, { userId: id })

        if (typeof profile === 'undefined') return null

        return profile
      }),
      fromInterests: userIds.fromInterests.map((id) => {
        const profile = _.find(suggestedProfiles, { userId: id })

        if (typeof profile === 'undefined') return null

        return profile
      }),
      fromInsights: userIds.fromInsights.map((id) => {
        const profile = _.find(suggestedProfiles, { userId: id })

        if (typeof profile === 'undefined') return null

        return profile
      })
    }

    setProfiles(newProfiles)
  }, [userIds, suggestedProfiles])

  useEffect(() => {
    if (!isLoading) {
      loadConnections(tagIds && [...tagIds])
    }
  }, [isLoading, loadConnections, setTags, tagIds])

  const handleUpdateProfile = () => {
    dispatch(
      updateProfileByUserID({
        fieldsToUpdate: {
          showProfileSuggestedConnections: false
        }
      })
    ).then((data) => {
      if (!data.error) {
        console.log('[SUCCESS]: ', 'Successfully updated profile!')
      } else {
        console.log('[FAIL]: ', 'Failed to update profile.')
      }
    })
  }

  // Hide if no results or nothing to show
  if (hideOnNoResults && !isLoading && !showFromInterests && !showFromInsights && !showRandom) {
    return null
  }

  return (
    <MainContainer style={{ ...newStyles }}>
      {showCloseButton && (
        <IconButton className={classes.iconButton} onClick={handleUpdateProfile} size="large">
          <Close />
        </IconButton>
      )}

      {!hidePrimaryHeading && <HeadingPrimary>Suggested Members</HeadingPrimary>}
      {customHeading && <HeadingPrimary>{customHeading}</HeadingPrimary>}

      {isLoading ? (
        <SkeletonConnection count={3} style={{ marginTop: '1.5rem' }} />
      ) : (
        <>
          {showFromInterests && (
            <>
              {tagIds ? (
                <>
                  {!hideSecondaryHeading && (
                    <HeadingSecondary>Learn from others knowledgeable in:</HeadingSecondary>
                  )}
                  {customSecondaryHeading && (
                    <HeadingSecondary>{customSecondaryHeading}</HeadingSecondary>
                  )}

                  <Tags tagIds={tags || []} tagStyle={{ padding: '.4rem .5rem' }} />
                </>
              ) : (
                <>
                  {!hideSecondaryHeading && (
                    <HeadingSecondary>Based on your interests in:</HeadingSecondary>
                  )}
                  {/* !hideSecondaryHeading && <HeadingSecondary>Learn from others:</HeadingSecondary> */}
                  {/* !hideSubheading && <Subheading>Based on your interests in:</Subheading> */}
                  {customSecondaryHeading && (
                    <HeadingSecondary>{customSecondaryHeading}</HeadingSecondary>
                  )}
                  {customSubheading && <Subheading>{customSubheading}</Subheading>}

                  <Tags
                    tagIds={currentProfile.tagsOfInterest}
                    tagStyle={{ padding: '.4rem .5rem' }}
                  />
                </>
              )}

              <Slider
                dataType={'profile'}
                profiles={profiles.fromInterests}
                theme={'light'}
                displayFollowActions={true}
                enableFollowLoading={true}
              />
            </>
          )}

          {showFromInsights && (
            <>
              {tagIds ? (
                <>
                  {!hideSecondaryHeading && (
                    <HeadingSecondary>Share with others interested in:</HeadingSecondary>
                  )}
                  {customSecondaryHeading && (
                    <HeadingSecondary>{customSecondaryHeading}</HeadingSecondary>
                  )}

                  <Tags tagIds={tags || []} tagStyle={{ padding: '.4rem .5rem' }} />
                </>
              ) : (
                <>
                  {!hideSecondaryHeading && (
                    <HeadingSecondary>Based on your insights on:</HeadingSecondary>
                  )}
                  {/* !hideSecondaryHeading && <HeadingSecondary>Share with others:</HeadingSecondary> */}
                  {/* !hideSubheading && <Subheading>Based on your insights on:</Subheading> */}

                  {customSecondaryHeading && (
                    <HeadingSecondary>{customSecondaryHeading}</HeadingSecondary>
                  )}
                  {customSubheading && <Subheading>{customSubheading}</Subheading>}

                  <Tags
                    tagIds={currentProfile.tagsOfKnowledge}
                    tagStyle={{ padding: '.4rem .5rem' }}
                  />
                </>
              )}

              <Slider
                dataType={'profile'}
                profiles={profiles.fromInsights}
                theme={'light'}
                displayFollowActions={true}
                enableFollowLoading={true}
              />
            </>
          )}

          {showRandom && (
            <>
              {!hideSecondaryHeading && <HeadingSecondary>New Members:</HeadingSecondary>}
              {/* !hideSubheading && <Subheading>Connect with some new community members:</Subheading> */}
              {customSecondaryHeading && (
                <HeadingSecondary>{customSecondaryHeading}</HeadingSecondary>
              )}
              {customSubheading && <Subheading>{customSubheading}</Subheading>}

              <Slider
                dataType={'profile'}
                profiles={profiles.random}
                theme={'light'}
                displayFollowActions={true}
                enableFollowLoading={true}
              />
            </>
          )}

          {!showFromInterests && !showFromInsights && !showRandom && (
            <Subheading style={{ marginTop: '1.5rem' }}>No suggested connections found</Subheading>
          )}
        </>
      )}
    </MainContainer>
  )
}

export default SuggestedConnections
