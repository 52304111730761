import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'

import { editTakeByID } from '../../../../../redux/thunks/takes/editTakeByID'
import EditTakeValidation from './validations/edit-take'
import arraysEqual from '../../../../../utils/functions/arraysEqual'
import FormError from '../../../../alerts/form-error'
import PostImage from '../../../post-image/post-image'
import TagsSearch from '../../../tags-search/tags-search'
import Tags from '../../../tags/tags'
// import CourseSelect from './components/course-select/course-select'
import PostVisibilityForm from '../../../post-form/components/post-visibility-form/post-visibility-form'
import usePostVisibility from '../../../post-form/hooks/usePostVisibility'
import EmotionPicker, {
  finalEmotionForEmotion,
  subEmotionForEmotion,
  topEmotionForEmotion
} from '../../../emotion-picker/emotion-picker'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import {
  Button,
  FormControl,
  InputLabel,
  Select,
  ListSubheader,
  MenuItem,
  CircularProgress
} from '@mui/material'
import {
  TakeEditContainer,
  TextareaContainer,
  StyledTextarea,
  TextCount,
  EditSelectContainer,
  SubmitContainer,
  EmotionMessage,
  Label
} from './styled/edit-form-comps'

const useStyles = makeStyles((theme) =>
  createStyles({
    formControl: {
      display: 'inline-block',
      marginTop: '.5rem'
    },
    label: {
      color: '#555 !important',
      fontWeight: '400',
      fontSize: '.8rem'
    },
    select: {
      width: '18rem'
    },
    category: {
      color: '#333333',
      fontSize: '1rem',
      fontWeight: '600',
      background: theme.palette.secondary.light
    },
    menuItem: {
      fontSize: '1rem'
    },
    cancel: {
      display: 'inline-block',
      minHeight: '2.3rem',
      height: '2.3rem',
      width: '10rem',
      margin: '1rem 0 0 0',
      verticalAlign: 'middle',
      fontSize: '1rem',
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.cancelButton.light,
      '&:hover': {
        backgroundColor: theme.palette.cancelButton.dark
      }
    },
    edit: {
      fontSize: '1rem',
      color: theme.palette.text.primary,
      display: 'inline-block',
      minHeight: '2.3rem',
      height: '2.3rem',
      width: '10rem',
      margin: '1rem 0 0 1.5rem',
      verticalAlign: 'middle',
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.light
      }
    },
    circularProgress: {
      display: 'block',
      width: '17px !important',
      height: '17px !important',
      margin: '.3rem auto 0 auto',
      '& svg': {
        color: 'white'
      }
    }
  })
)

const EditForm = (props) => {
  const { take, handleSetEditing } = props
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(true)
  const [takeEdit, setTakeEdit] = useState('')
  const [postImage, setPostImage] = useState('')
  const [postImagePreview, setPostImagePreview] = useState('')
  const [isImageDifferent, setIsImageDifferent] = useState(false)
  const [tagIds, setTagIds] = useState([])
  const [controlType, setControlType] = useState('everyone')
  const [courseId, setCourseId] = useState('')
  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState('')
  const currentProfile = useSelector(({ currentProfile }) => currentProfile)
  const dispatch = useDispatch()
  const isTextEmpty = takeEdit.trim().length === 0
  const isTextDifferent = take.take !== takeEdit
  const areTagsDifferent = !arraysEqual(tagIds, take.tagIds)
  const isControlTypeDifferent = take.visibleTo.key !== controlType

  const [visibility, handleVisibilityChange] = usePostVisibility(
    take.visibleTo.key === 'course' ? 'course' : 'community'
  )

  /** EMOTIONS HOOKS */
  const [topEmotion, setTopEmotion] = useState('')
  const [subEmotion, setSubEmotion] = useState('')
  const [finalEmotion, setFinalEmotion] = useState('')

  const postEmotion = React.useMemo(() => {
    if (finalEmotion) {
      return finalEmotion
    } else if (subEmotion) {
      return subEmotion
    }
    return topEmotion
  }, [topEmotion, subEmotion, finalEmotion])

  /**
   * Load original emotion into state
   * Only run on first render
   * */
  useEffect(() => {
    if (take.emotion) {
      setTopEmotion(topEmotionForEmotion(take.emotion))
      setSubEmotion(subEmotionForEmotion(take.emotion))
      setFinalEmotion(finalEmotionForEmotion(take.emotion))
    }
  }, [])

  useEffect(() => {
    function defaultCourseState() {
      if (take.courseId === '') {
        setCourseId('no_course')
      } else {
        setCourseId(take.courseId)
      }
    }

    defaultCourseState()
  }, [])

  useEffect(() => {
    if (isLoading) {
      setTakeEdit(take.take)
      // setPostImage(take.image)
      setTagIds([...take.tagIds])
      setControlType(take.visibleTo.key === '' ? 'everyone' : take.visibleTo.key)
      setIsLoading(false)
    }
  }, [isLoading, take.image, take.tagIds, take.take, take.visibleTo.key])

  const submitEdit = () => {
    setProcessing(true)

    const { personalAddress, courses } = currentProfile
    const { city, county, state, zipCode } = personalAddress
    const { error } = EditTakeValidation({ takeEdit, postEmotion })

    const actualCourseId = courses.length && courseId === 'no_course' ? '' : courseId
    console.log('courseId', courseId)
    console.log('actualCourseId', actualCourseId === '')

    // Check for validation errors and then call action to create take
    if (error) {
      console.log('[VALIDATION ERROR]: ', error)

      setError(error)
      setProcessing(false)
    } else {
      const fields = {
        takeId: take._id,
        take: takeEdit,
        file: postImage,
        image: postImage,
        tagIds,
        emotion: postEmotion,
        location: { city, county, state, zipCode },
        courseId: actualCourseId,
        visibleTo: {
          key: visibility === 'course' ? 'course' : '',
          value: visibility === 'course' ? courseId : ''
        }
      }

      dispatch(editTakeByID(fields))
        .then((data) => {
          if (!data.error) {
            console.log('[SUCCESS]: ', 'Successfully updated take!')

            handleSetEditing(false)
          } else {
            console.log('[FAIL]: ', 'Failed to update take.')

            setError(data.message)
          }

          setProcessing(false)
        })
        .catch((e) => {
          setProcessing(false)
        })
    }
  }

  return (
    <TakeEditContainer>
      <FormError message={error} />
      <div style={{ height: '1rem' }}></div>
      <Label id="controlType">Edit Post:</Label>

      <TextareaContainer style={{ marginTop: '1rem' }}>
        <StyledTextarea
          name="take"
          value={takeEdit}
          placeholder="Please enter your take in 3000 characters or less..."
          wrap="soft"
          maxLength="3000"
          onChange={(e) => setTakeEdit(e.target.value)}
        />
      </TextareaContainer>
      <TextCount>{`${takeEdit.length} / 3000`}</TextCount>

      <Label id="controlType">Edit Tags:</Label>

      <TagsSearch
        tagIds={tagIds}
        setTagIds={setTagIds}
        setError={setError}
        variant={'transparent'}
      />

      <Tags tagIds={tagIds} setTagIds={setTagIds} hideTagAdd={true} />

      <div style={{ height: '1rem' }}></div>
      <Label id="controlType">Edit Emotion Tag:</Label>

      <EmotionPicker
        topEmotion={topEmotion}
        subEmotion={subEmotion}
        finalEmotion={finalEmotion}
        setTopEmotion={setTopEmotion}
        setSubEmotion={setSubEmotion}
        setFinalEmotion={setFinalEmotion}
      />

      <div style={{ height: '0.25rem' }}></div>
      <Label id="controlType">Edit Photo:</Label>
      <div style={{ height: '0.25rem' }}></div>
      {/** Image Upload Form */}
      <PostImage
        postImage={take.image}
        setPostImage={setPostImage}
        takeImage={take.image}
        isEditing={true}
        isImageDifferent={isImageDifferent}
        setIsImageDifferent={setIsImageDifferent}
        postImagePreview={postImagePreview}
        setPostImagePreview={setPostImagePreview}
      />

      <PostVisibilityForm
        postFormType={take.courseId ? 'coursePrompt' : 'communityPrompt'}
        visibility={visibility}
        handleVisibilityChange={handleVisibilityChange}
        courseId={courseId}
      />

      {/* <CourseSelect
        courses={currentProfile.courses}
        courseId={courseId}
        setCourseId={setCourseId}
      /> */}

      {/* <EditSelectContainer>
        <Label id="controlType">Who Can See This Post:</Label>
        <FormControl className={classes.formControl}>
          <Select
            labelId="controlType"
            name="controlType"
            value={controlType}
            onChange={(e) => setControlType(e.target.value)}
            className={classes.select}>
            <ListSubheader className={classes.category}>By Connection</ListSubheader>

            <MenuItem value={'everyone'} className={classes.menuItem}>
              Everyone
            </MenuItem>

            <ListSubheader className={classes.category}>People in my</ListSubheader>

            <MenuItem value={'city'} className={classes.menuItem}>
              City
            </MenuItem>
            <MenuItem value={'county'} className={classes.menuItem}>
              County
            </MenuItem>
            <MenuItem value={'state'} className={classes.menuItem}>
              State
            </MenuItem>
            <MenuItem value={'zipCode'} className={classes.menuItem}>
              Zip Code
            </MenuItem>
          </Select>
        </FormControl>
      </EditSelectContainer> */}

      <SubmitContainer>
        <Button
          variant="contained"
          color="secondary"
          className={classes.cancel}
          onClick={() => handleSetEditing(false)}>
          Cancel
        </Button>

        <Button
          variant="contained"
          color="primary"
          className={classes.edit}
          onClick={submitEdit}
          disabled={
            processing ||
            isTextEmpty ||
            (!isTextDifferent && !areTagsDifferent && !isControlTypeDifferent && !isImageDifferent)
          }>
          {processing ? (
            <CircularProgress color="secondary" className={classes.circularProgress} />
          ) : (
            'Save'
          )}
        </Button>
      </SubmitContainer>
    </TakeEditContainer>
  )
}

export default EditForm
