//////////////////
// ACTION TYPES //
//////////////////

export const termsVersionsTypes = {
  SET_TERMS: 'SET_TERMS',
  CLEAR_TERMS: 'CLEAR_TERMS'
}

/////////////
// ACTIONS //
/////////////

const set = (termsVersion) => {
  return {
    type: termsVersionsTypes.SET_TERMS,
    termsVersion
  }
}

const clear = () => {
  return {
    type: termsVersionsTypes.CLEAR_TERMS
  }
}

export const termsVersionsActions = {
  set,
  clear
}
