import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { analytics } from '../../../../firebase/firebase'
import { useNavigate, useParams } from 'react-router-dom'
import { createUserFollow } from '../../../../redux/thunks/follows/createUserFollow'
import { deleteFollowByUserID } from '../../../../redux/thunks/follows/deleteFollowByUserID'
import { createOrganizationFollow } from '../../../../redux/thunks/follows/createOrganizationFollow'
import { deleteFollowByOrganizationID } from '../../../../redux/thunks/follows/deleteFollowByOrganizationID'
// import getAvatar from '../../../../utils/functions/getAvatar'
import getName from '../../../../utils/functions/getName'
import UserAvatar from '../../../partials/user-avatar/user-avatar'
import getOrganizationImage from '../../../../utils/functions/getOrganizationImage'
import withStyles from '@mui/styles/withStyles'
import PropTypes from 'prop-types'
import { Button } from '@mui/material'
import {
  Header,
  TabContainer,
  Tab,
  ResultContainer,
  //ProfilePicture,
  AvatarContainer,
  ProfileInfoContainer,
  ProfileName,
  ProfileLocation,
  ActionContainer,
  NoResults
} from './styled/center-comps'

const styles = (theme) => ({
  unfollow: {
    minWidth: '2rem',
    '& span': {
      fontSize: '1.3rem !important'
    },
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.cancelButton.light,
    '&:hover': {
      backgroundColor: theme.palette.cancelButton.dark
    },
    borderRadius: '.2rem',
    cursor: 'pointer',
    '& > *': {
      pointerEvents: 'none'
    }
  },
  follow: {
    minWidth: '2rem',
    '& span': {
      fontSize: '1.3rem !important'
    },
    borderRadius: '.2rem',
    color: theme.palette.text.primary,
    cursor: 'pointer',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.light
    },
    '& > *': {
      pointerEvents: 'none'
    }
  }
})

class InnerCenter extends Component {
  constructor(props) {
    super(props)

    this.handleSelectTab = this.handleSelectTab.bind(this)
    this.handleGoToProfile = this.handleGoToProfile.bind(this)
    this.handleGoToTag = this.handleGoToTag.bind(this)
    this.handleGoToOrganization = this.handleGoToOrganization.bind(this)
    this.handleFollowUser = this.handleFollowUser.bind(this)
    this.handleUnfollowUser = this.handleUnfollowUser.bind(this)
    this.handleFollowOrganization = this.handleFollowOrganization.bind(this)
    this.handleUnfollowOrganization = this.handleUnfollowOrganization.bind(this)
  }

  componentDidMount() {
    let { profiles, tags } = this.props.searchResults

    if (profiles.length === 0 && tags.length !== 0) {
      this.props.setState({
        ...this.props.state,
        status: {
          ...this.props.state.status,
          tab: 1
        }
      })
    }
  }

  handleSelectTab(tab) {
    this.props.setState({
      ...this.props.state,
      status: {
        ...this.props.state.status,
        tab
      }
    })
  }

  handleGoToProfile(userId) {
    analytics.logEvent('click_search_item')

    this.props.navigate(`/profile/${userId}`)
  }

  handleGoToTag(e) {
    analytics.logEvent('click_search_item')

    this.props.navigate(`/topic/${e.target.id}`)
  }

  handleGoToOrganization(id) {
    analytics.logEvent('click_search_item')

    this.props.navigate(`/organization/${id}`)
  }

  handleFollowUser(event) {
    const profile = _.find(this.props.searchResults.profiles, {
      'userId': event.target.id
    })

    if (typeof profile === 'undefined' || profile === null) {
      console.log('[FAIL]: ', 'Failed to find profile for createFollow.')
    } else {
      this.props
        .createUserFollow({
          userId: event.target.id,
          name: getName(profile)
        })
        .then((data) => {
          if (!data.error) {
            console.log('[SUCCESS]: ', 'Successfully followed user!')
          } else {
            console.log('[FAIL]: ', 'Failed to follow user.')
          }
        })
    }
  }

  handleUnfollowUser(event) {
    const profile = _.find(this.props.searchResults.profiles, {
      'userId': event.target.id
    })

    if (typeof profile === 'undefined' || profile === null) {
      console.log('[FAIL]: ', 'Failed to find profile for deleteFollowByUserID.')
    } else {
      this.props
        .deleteFollowByUserID({
          userId: event.target.id,
          currUserId: this.props.currentUser.id,
          name: getName(profile)
        })
        .then((data) => {
          if (!data.error) {
            console.log('[SUCCESS]: ', 'Successfully unfollowed user!')
          } else {
            console.log('[FAIL]: ', 'Failed to unfollow user.')
          }
        })
    }
  }

  handleFollowOrganization(event) {
    const organization = _.find(this.props.searchResults.organizations, {
      '_id': event.target.id
    })

    if (typeof organization === 'undefined' || organization === null) {
      console.log('[FAIL]: ', 'Failed to find organization for createOrganizationFollow.')
    } else {
      this.props
        .createOrganizationFollow({
          organizationId: event.target.id,
          name: organization.publicName
        })
        .then((data) => {
          if (!data.error) {
            console.log('[SUCCESS]: ', 'Successfully followed organization!')
          } else {
            console.log('[FAIL]: ', 'Failed to follow organization.')
          }
        })
    }
  }

  handleUnfollowOrganization(event) {
    const organization = _.find(this.props.searchResults.organizations, {
      '_id': event.target.id
    })

    if (typeof organization === 'undefined' || organization === null) {
      console.log('[FAIL]: ', 'Failed to find organization for deleteFollowByOrganizationID.')
    } else {
      this.props
        .deleteFollowByOrganizationID({
          organizationId: event.target.id,
          currUserId: this.props.currentUser.id,
          name: organization.publicName
        })
        .then((data) => {
          if (!data.error) {
            console.log('[SUCCESS]: ', 'Successfully unfollowed organization!')
          } else {
            console.log('[FAIL]: ', 'Failed to unfollow organization.')
          }
        })
    }
  }

  renderProfiles() {
    const { classes, searchResults, currentUser, follows } = this.props

    if (typeof searchResults.profiles !== 'undefined' && searchResults.profiles.length > 0) {
      return (
        <div>
          {searchResults.profiles.map((profile, i) => {
            const condStyles = {}
            const { avatar, userId, personalAddress, privacySettings, documentVerified } = profile
            const isCurrentUser = userId === currentUser.id
            const showLocation =
              personalAddress.city && personalAddress.state && privacySettings.showLocation
            const verified = documentVerified ? 1 : 0
            const isFollowing = _.some(follows, (follow) => {
              return follow.follower === currentUser.id && follow.userId === userId
            })

            if (i === 0) {
              condStyles.borderTopLeftRadius = '.3rem'
              condStyles.borderTopRightRadius = '.3rem'
            }

            if (i === searchResults.profiles.length - 1) {
              condStyles.borderBottomLeftRadius = '.3rem'
              condStyles.borderBottomRightRadius = '.3rem'
            }

            return (
              <ResultContainer key={userId} style={condStyles}>
                <AvatarContainer onClick={() => this.handleGoToProfile(userId)}>
                  <UserAvatar
                    src={avatar}
                    width={'3.05rem'}
                    height={'3.05rem'}
                    borderWidth={'0.2rem'}
                    verified={verified}
                  />
                </AvatarContainer>

                {!isCurrentUser && (
                  <ActionContainer>
                    {isFollowing ? (
                      <Button
                        id={userId}
                        variant="contained"
                        color="primary"
                        className={classes.unfollow}
                        onClick={this.handleUnfollowUser}>
                        <span className="material-icons">person_remove</span>
                      </Button>
                    ) : (
                      <Button
                        id={userId}
                        variant="contained"
                        color="primary"
                        className={classes.follow}
                        onClick={this.handleFollowUser}>
                        <span className="material-icons">person_add</span>
                      </Button>
                    )}
                  </ActionContainer>
                )}

                <ProfileInfoContainer>
                  <ProfileName onClick={() => this.handleGoToProfile(userId)}>
                    {getName(profile)}
                  </ProfileName>

                  {showLocation && (
                    <ProfileLocation>
                      {personalAddress.city + ', ' + personalAddress.state}
                    </ProfileLocation>
                  )}
                </ProfileInfoContainer>
              </ResultContainer>
            )
          })}
        </div>
      )
    } else {
      return <NoResults>No profiles to display</NoResults>
    }
  }

  renderOrganizations() {
    const { classes, searchResults, currentUser, follows } = this.props

    if (
      typeof searchResults.organizations !== 'undefined' &&
      searchResults.organizations.length > 0
    ) {
      return (
        <div>
          {searchResults.organizations.map((organization, i) => {
            const condStyles = {}
            const { _id, image, publicName, address } = organization
            const { cityName, state } = address
            const isFollowing = _.some(follows, (follow) => {
              return follow.follower === currentUser.id && follow.organizationId === _id
            })

            if (i === 0) {
              condStyles.borderTopLeftRadius = '.3rem'
              condStyles.borderTopRightRadius = '.3rem'
            }

            if (i === searchResults.organizations.length - 1) {
              condStyles.borderBottomLeftRadius = '.3rem'
              condStyles.borderBottomRightRadius = '.3rem'
            }

            return (
              <ResultContainer key={_id} style={condStyles}>
                <AvatarContainer onClick={() => this.handleGoToOrganization(_id)}>
                  <UserAvatar
                    src={getOrganizationImage(image)}
                    width={'3.05rem'}
                    height={'3.05rem'}
                    borderWidth={'0.2rem'}
                  />
                </AvatarContainer>

                <ActionContainer>
                  {isFollowing ? (
                    <Button
                      id={_id}
                      variant="contained"
                      color="primary"
                      className={classes.unfollow}
                      onClick={this.handleUnfollowOrganization}>
                      <span className="material-icons">person_remove</span>
                    </Button>
                  ) : (
                    <Button
                      id={_id}
                      variant="contained"
                      color="primary"
                      className={classes.follow}
                      onClick={this.handleFollowOrganization}>
                      <span className="material-icons">person_add</span>
                    </Button>
                  )}
                </ActionContainer>

                <ProfileInfoContainer>
                  <ProfileName onClick={() => this.handleGoToOrganization(_id)}>
                    {publicName}
                  </ProfileName>

                  {cityName && state && (
                    <ProfileLocation>{cityName + ', ' + state}</ProfileLocation>
                  )}
                </ProfileInfoContainer>
              </ResultContainer>
            )
          })}
        </div>
      )
    } else {
      return <NoResults>No profiles to display</NoResults>
    }
  }

  renderTags() {
    const { searchResults } = this.props

    if (typeof searchResults.tags !== 'undefined' || searchResults !== null) {
      return (
        <div>
          {searchResults.tags.map((tag, i) => {
            const condStyles = {}
            const { _id } = tag

            if (i === 0) {
              condStyles.borderTopLeftRadius = '.3rem'
              condStyles.borderTopRightRadius = '.3rem'
            }

            if (i === searchResults.tags.length - 1) {
              condStyles.borderBottomLeftRadius = '.3rem'
              condStyles.borderBottomRightRadius = '.3rem'
            }

            return (
              <ResultContainer id={_id} style={condStyles}>
                <ProfileName id={_id} onClick={this.handleGoToTag}>
                  {_id}
                </ProfileName>
              </ResultContainer>
            )
          })}
        </div>
      )
    } else {
      return <noResults>No topics to display</noResults>
    }
  }

  render() {
    const { match, searchResults, state, params } = this.props
    const { tab, pageLoaded } = state.status
    const query = typeof params.query === 'undefined' ? '' : params.query

    return (
      <div>
        <Header>Search Results</Header>

        <TabContainer>
          <Tab selected={tab === 'people'} onClick={() => this.handleSelectTab('people')}>
            People
          </Tab>

          <Tab selected={tab === 'tags'} onClick={() => this.handleSelectTab('tags')}>
            Topics
          </Tab>

          <Tab
            selected={tab === 'organizations'}
            onClick={() => this.handleSelectTab('organizations')}>
            Organizations
          </Tab>
        </TabContainer>

        {!pageLoaded ? (
          <NoResults>Loading Search Results</NoResults>
        ) : (
          <>
            {tab === 'people' && (
              <>
                {searchResults.profiles.length === 0 ? (
                  <NoResults>
                    {query === '' ? (
                      'No search term found'
                    ) : (
                      <span>
                        No profiles matching
                        <b>{" '" + query + "'"}</b>
                      </span>
                    )}
                  </NoResults>
                ) : (
                  this.renderProfiles()
                )}
              </>
            )}

            {tab === 'tags' && (
              <>
                {searchResults.tags.length === 0 ? (
                  <NoResults>
                    {query === '' ? (
                      'No search term found'
                    ) : (
                      <span>
                        No topics matching
                        <b>{" '" + query + "'"}</b>
                      </span>
                    )}
                  </NoResults>
                ) : (
                  this.renderTags()
                )}
              </>
            )}

            {tab === 'organizations' && (
              <>
                {searchResults.organizations.length === 0 ? (
                  <NoResults>
                    {query === '' ? (
                      'No search term found'
                    ) : (
                      <span>
                        No organizations matching
                        <b>{" '" + query + "'"}</b>
                      </span>
                    )}
                  </NoResults>
                ) : (
                  this.renderOrganizations()
                )}
              </>
            )}
          </>
        )}
      </div>
    )
  }
}

// *************************************************************************************
// *** Wrapper Component - to pass in navigate prop
// *************************************************************************************
function Center(props) {
  const navigate = useNavigate()
  const params = useParams()

  return <InnerCenter {...props} navigate={navigate} params={params} />
}
// *************************************************************************************

InnerCenter.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
  currentUser: state.currentUser,
  searchResults: state.searchResults,
  follows: state.follows,
  loadedProfiles: state.loadedProfiles
})

const mapDispatchToProps = (dispatch) => ({
  createUserFollow: (params) => {
    return dispatch(createUserFollow(params))
  },
  deleteFollowByUserID: (params) => {
    return dispatch(deleteFollowByUserID(params))
  },
  createOrganizationFollow: (params) => {
    return dispatch(createOrganizationFollow(params))
  },
  deleteFollowByOrganizationID: (params) => {
    return dispatch(deleteFollowByOrganizationID(params))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Center))
