import { createTheme } from '@mui/material/styles'
import fontFamily from './fonts.js'
import CTTheme from './theme.js'
import config from '../config.js'

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: parseInt(config.UI.drawer_breakpoint.min, 10), // 901
      lg: 1280,
      xl: 1920
    }
  },
  typography: {
    fontFamily: fontFamily,
    fontStyle: 'normal',
    fontWeight: 100,
    fontSize: 8,
    htmlFontSize: 10
  },
  palette: {
    primary: {
      main: '#edb801', // gold
      light: '#f0c633' // use for hover state
    },
    secondary: {
      main: '#6e8c76', // dark olive
      light: '#abcab3' // light olive,
    },
    link: {
      primary: '#4f5c88',
      secondary: '#555'
    },
    text: {
      primary: '#333333', // coal
      secondary: '#ffffff' // white
      // disabled: '#' TODO: decide if we want a custom color here
    },
    common: {
      white: '#ffffff',
      black: '#000000'
    },
    background: {
      gray: '#eaeaea'
    },
    cancelButton: {
      light: '#dcdddd',
      dark: '#aaabab'
    },
    Joy: {
      light: 'rgba(82, 255, 140, 1)',
      dark: 'rgba(82, 255, 140, 1)'
    },
    Love: {
      light: 'rgba(245, 237, 166, 1)',
      dark: 'rgba(245, 237, 166, 1)'
    },
    Surprise: {
      light: 'rgba(49, 207, 234, 1)',
      dark: 'rgba(49, 207, 234, 1)'
    },
    Sadness: {
      light: 'rgba(157, 169, 189, 1)',
      dark: 'rgba(157, 169, 189, 1)'
    },
    Fear: {
      light: 'rgba(210, 145, 113, 1)',
      dark: 'rgba(210, 145, 113, 1)'
    },
    Anger: {
      light: 'rgba(195,133, 169, 1)',
      dark: 'rgba(195,133, 169, 1)'
    },
    // JOY
    Enthralled: {
      light: 'rgba(82, 255, 140, 1)',
      dark: 'rgba(82, 255, 140, 1)'
    },
    Elation: {
      light: 'rgba(82, 255, 140, 1)',
      dark: 'rgba(82, 255, 140, 1)'
    },
    Enthusiastic: {
      light: 'rgba(82, 255, 140, 1)',
      dark: 'rgba(82, 255, 140, 1)'
    },
    Optimistic: {
      light: 'rgba(82, 255, 140, 1)',
      dark: 'rgba(82, 255, 140, 1)'
    },
    Proud: {
      light: 'rgba(82, 255, 140, 1)',
      dark: 'rgba(82, 255, 140, 1)'
    },
    Cheerful: {
      light: 'rgba(82, 255, 140, 1)',
      dark: 'rgba(82, 255, 140, 1)'
    },
    Happy: {
      light: 'rgba(82, 255, 140, 1)',
      dark: 'rgba(82, 255, 140, 1)'
    },
    Content: {
      light: 'rgba(82, 255, 140, 1)',
      dark: 'rgba(82, 255, 140, 1)'
    },
    //LOVE
    Peaceful: {
      light: 'rgba(156, 196, 197, 1)',
      dark: 'rgba(156, 196, 197, 1)'
    },
    Tenderness: {
      light: 'rgba(156, 196, 197, 1)',
      dark: 'rgba(156, 196, 197, 1)'
    },
    Desire: {
      light: 'rgba(156, 196, 197, 1)',
      dark: 'rgba(156, 196, 197, 1)'
    },
    Longing: {
      light: 'rgba(156, 196, 197, 1)',
      dark: 'rgba(156, 196, 197, 1)'
    },
    Affectionate: {
      light: 'rgba(156, 196, 197, 1)',
      dark: 'rgba(156, 196, 197, 1)'
    },
    // SURPRISE
    Stunned: {
      light: 'rgba(49, 207, 234, 1)',
      dark: 'rgba(49, 207, 234, 1)'
    },
    Confused: {
      light: 'rgba(49, 207, 234, 1)',
      dark: 'rgba(49, 207, 234, 1)'
    },
    Amazed: {
      light: 'rgba(49, 207, 234, 1)',
      dark: 'rgba(49, 207, 234, 1)'
    },
    Overcome: {
      light: 'rgba(49, 207, 234, 1)',
      dark: 'rgba(49, 207, 234, 1)'
    },
    Moved: {
      light: 'rgba(49, 207, 234, 1)',
      dark: 'rgba(49, 207, 234, 1)'
    },
    // Sadness
    Suffering: {
      light: 'rgba(157, 169, 189, 1)',
      dark: 'rgba(157, 169, 189, 1)'
    },
    Sadness: {
      light: 'rgba(157, 169, 189, 1)',
      dark: 'rgba(157, 169, 189, 1)'
    },
    Disappointed: {
      light: 'rgba(157, 169, 189, 1)',
      dark: 'rgba(157, 169, 189, 1)'
    },
    Shameful: {
      light: 'rgba(157, 169, 189, 1)',
      dark: 'rgba(157, 169, 189, 1)'
    },
    Neglected: {
      light: 'rgba(157, 169, 189, 1)',
      dark: 'rgba(157, 169, 189, 1)'
    },
    Despair: {
      light: 'rgba(157, 169, 189, 1)',
      dark: 'rgba(157, 169, 189, 1)'
    },
    // FEAR
    Horror: {
      light: 'rgba(210, 145, 113, 1)',
      dark: 'rgba(210, 145, 113, 1)'
    },
    Nervous: {
      light: 'rgba(210, 145, 113, 1)',
      dark: 'rgba(210, 145, 113, 1)'
    },
    Insecure: {
      light: 'rgba(210, 145, 113, 1)',
      dark: 'rgba(210, 145, 113, 1)'
    },
    Terror: {
      light: 'rgba(210, 145, 113, 1)',
      dark: 'rgba(210, 145, 113, 1)'
    },
    Scared: {
      light: 'rgba(210, 145, 113, 1)',
      dark: 'rgba(210, 145, 113, 1)'
    },
    // Anger
    Rage: {
      light: 'rgba(195,133, 169, 1)',
      dark: 'rgba(195,133, 169, 1)'
    },
    Exasperated: {
      light: 'rgba(195,133, 169, 1)',
      dark: 'rgba(195,133, 169, 1)'
    },
    Irritable: {
      light: 'rgba(195,133, 169, 1)',
      dark: 'rgba(195,133, 169, 1)'
    },
    Envy: {
      light: 'rgba(195,133, 169, 1)',
      dark: 'rgba(195,133, 169, 1)'
    },
    Disgust: {
      light: 'rgba(195,133, 169, 1)',
      dark: 'rgba(195,133, 169, 1)'
    },
    // ANGER FINAL EMOTIONS
    Hostile: {
      light: 'rgba(195,133, 169, 1)',
      dark: 'rgba(195,133, 169, 1)'
    },
    Hate: {
      light: 'rgba(195,133, 169, 1)',
      dark: 'rgba(195,133, 169, 1)'
    },
    Agitated: {
      light: 'rgba(195,133, 169, 1)',
      dark: 'rgba(195,133, 169, 1)'
    },
    Frustrated: {
      light: 'rgba(195,133, 169, 1)',
      dark: 'rgba(195,133, 169, 1)'
    },
    Irritable: {
      light: 'rgba(195,133, 169, 1)',
      dark: 'rgba(195,133, 169, 1)'
    },
    Annoyed: {
      light: 'rgba(195,133, 169, 1)',
      dark: 'rgba(195,133, 169, 1)'
    },
    Aggravated: {
      light: 'rgba(195,133, 169, 1)',
      dark: 'rgba(195,133, 169, 1)'
    },
    Resentful: {
      light: 'rgba(195,133, 169, 1)',
      dark: 'rgba(195,133, 169, 1)'
    },
    Jealous: {
      light: 'rgba(195,133, 169, 1)',
      dark: 'rgba(195,133, 169, 1)'
    },
    Contempt: {
      light: 'rgba(195,133, 169, 1)',
      dark: 'rgba(195,133, 169, 1)'
    },
    Revolted: {
      light: 'rgba(195,133, 169, 1)',
      dark: 'rgba(195,133, 169, 1)'
    },
    // SADNESS FINAL
    Agony: {
      light: 'rgba(157, 169, 189, 1)',
      dark: 'rgba(157, 169, 189, 1)'
    },
    Hurt: {
      light: 'rgba(157, 169, 189, 1)',
      dark: 'rgba(157, 169, 189, 1)'
    },
    Depressed: {
      light: 'rgba(157, 169, 189, 1)',
      dark: 'rgba(157, 169, 189, 1)'
    },
    Sorrow: {
      light: 'rgba(157, 169, 189, 1)',
      dark: 'rgba(157, 169, 189, 1)'
    },
    Dismayed: {
      light: 'rgba(157, 169, 189, 1)',
      dark: 'rgba(157, 169, 189, 1)'
    },
    Displeased: {
      light: 'rgba(157, 169, 189, 1)',
      dark: 'rgba(157, 169, 189, 1)'
    },
    Regretful: {
      light: 'rgba(157, 169, 189, 1)',
      dark: 'rgba(157, 169, 189, 1)'
    },
    Guilty: {
      light: 'rgba(157, 169, 189, 1)',
      dark: 'rgba(157, 169, 189, 1)'
    },
    Isolated: {
      light: 'rgba(157, 169, 189, 1)',
      dark: 'rgba(157, 169, 189, 1)'
    },
    Lonely: {
      light: 'rgba(157, 169, 189, 1)',
      dark: 'rgba(157, 169, 189, 1)'
    },
    Grief: {
      light: 'rgba(157, 169, 189, 1)',
      dark: 'rgba(157, 169, 189, 1)'
    },
    Powerless: {
      light: 'rgba(157, 169, 189, 1)',
      dark: 'rgba(157, 169, 189, 1)'
    },
    // SURPRISE FINAL
    Shocked: {
      light: 'rgba(49, 207, 234, 1)',
      dark: 'rgba(49, 207, 234, 1)'
    },
    Dismayed: {
      light: 'rgba(49, 207, 234, 1)',
      dark: 'rgba(49, 207, 234, 1)'
    },
    Disillusioned: {
      light: 'rgba(49, 207, 234, 1)',
      dark: 'rgba(49, 207, 234, 1)'
    },
    Perplexed: {
      light: 'rgba(49, 207, 234, 1)',
      dark: 'rgba(49, 207, 234, 1)'
    },
    Astonished: {
      light: 'rgba(49, 207, 234, 1)',
      dark: 'rgba(49, 207, 234, 1)'
    },
    AweStruck: {
      light: 'rgba(49, 207, 234, 1)',
      dark: 'rgba(49, 207, 234, 1)'
    },
    Speechless: {
      light: 'rgba(49, 207, 234, 1)',
      dark: 'rgba(49, 207, 234, 1)'
    },
    Astounded: {
      light: 'rgba(49, 207, 234, 1)',
      dark: 'rgba(49, 207, 234, 1)'
    },
    Stimulated: {
      light: 'rgba(49, 207, 234, 1)',
      dark: 'rgba(49, 207, 234, 1)'
    },
    Touched: {
      light: 'rgba(49, 207, 234, 1)',
      dark: 'rgba(49, 207, 234, 1)'
    },
    // JOY FINAL
    Rapture: {
      light: 'rgba(82, 255, 140, 1)',
      dark: 'rgba(82, 255, 140, 1)'
    },
    Enchanted: {
      light: 'rgba(82, 255, 140, 1)',
      dark: 'rgba(82, 255, 140, 1)'
    },
    Jubilation: {
      light: 'rgba(82, 255, 140, 1)',
      dark: 'rgba(82, 255, 140, 1)'
    },
    Euphoric: {
      light: 'rgba(82, 255, 140, 1)',
      dark: 'rgba(82, 255, 140, 1)'
    },
    Zeal: {
      light: 'rgba(82, 255, 140, 1)',
      dark: 'rgba(82, 255, 140, 1)'
    },
    Excited: {
      light: 'rgba(82, 255, 140, 1)',
      dark: 'rgba(82, 255, 140, 1)'
    },
    Hopeful: {
      light: 'rgba(82, 255, 140, 1)',
      dark: 'rgba(82, 255, 140, 1)'
    },
    Eager: {
      light: 'rgba(82, 255, 140, 1)',
      dark: 'rgba(82, 255, 140, 1)'
    },
    Illustrious: {
      light: 'rgba(82, 255, 140, 1)',
      dark: 'rgba(82, 255, 140, 1)'
    },
    Triumphant: {
      light: 'rgba(82, 255, 140, 1)',
      dark: 'rgba(82, 255, 140, 1)'
    },
    Jovial: {
      light: 'rgba(82, 255, 140, 1)',
      dark: 'rgba(82, 255, 140, 1)'
    },
    Blissful: {
      light: 'rgba(82, 255, 140, 1)',
      dark: 'rgba(82, 255, 140, 1)'
    },
    Amused: {
      light: 'rgba(82, 255, 140, 1)',
      dark: 'rgba(82, 255, 140, 1)'
    },
    Delighted: {
      light: 'rgba(82, 255, 140, 1)',
      dark: 'rgba(82, 255, 140, 1)'
    },
    Pleased: {
      light: 'rgba(82, 255, 140, 1)',
      dark: 'rgba(82, 255, 140, 1)'
    },
    Satisfied: {
      light: 'rgba(82, 255, 140, 1)',
      dark: 'rgba(82, 255, 140, 1)'
    },
    // LOVE FINAL
    Satisfied: {
      light: 'rgba(245, 237, 166, 1)',
      dark: 'rgba(245, 237, 166, 1)'
    },
    Relieved: {
      light: 'rgba(245, 237, 166, 1)',
      dark: 'rgba(245, 237, 166, 1)'
    },
    Compassionate: {
      light: 'rgba(245, 237, 166, 1)',
      dark: 'rgba(245, 237, 166, 1)'
    },
    Caring: {
      light: 'rgba(245, 237, 166, 1)',
      dark: 'rgba(245, 237, 166, 1)'
    },
    Infatuation: {
      light: 'rgba(245, 237, 166, 1)',
      dark: 'rgba(245, 237, 166, 1)'
    },
    Passion: {
      light: 'rgba(245, 237, 166, 1)',
      dark: 'rgba(245, 237, 166, 1)'
    },
    Attracted: {
      light: 'rgba(245, 237, 166, 1)',
      dark: 'rgba(245, 237, 166, 1)'
    },
    Sentimental: {
      light: 'rgba(245, 237, 166, 1)',
      dark: 'rgba(245, 237, 166, 1)'
    },
    Fondness: {
      light: 'rgba(245, 237, 166, 1)',
      dark: 'rgba(245, 237, 166, 1)'
    },
    Romantic: {
      light: 'rgba(245, 237, 166, 1)',
      dark: 'rgba(245, 237, 166, 1)'
    },
    // FEAR FINAL
    Dread: {
      light: 'rgba(210, 145, 113, 1)',
      dark: 'rgba(210, 145, 113, 1)'
    },
    Mortified: {
      light: 'rgba(210, 145, 113, 1)',
      dark: 'rgba(210, 145, 113, 1)'
    },
    Anxious: {
      light: 'rgba(210, 145, 113, 1)',
      dark: 'rgba(210, 145, 113, 1)'
    },
    Worried: {
      light: 'rgba(210, 145, 113, 1)',
      dark: 'rgba(210, 145, 113, 1)'
    },
    Inadequate: {
      light: 'rgba(210, 145, 113, 1)',
      dark: 'rgba(210, 145, 113, 1)'
    },
    Inferior: {
      light: 'rgba(210, 145, 113, 1)',
      dark: 'rgba(210, 145, 113, 1)'
    },
    Hysterical: {
      light: 'rgba(210, 145, 113, 1)',
      dark: 'rgba(210, 145, 113, 1)'
    },
    Panic: {
      light: 'rgba(210, 145, 113, 1)',
      dark: 'rgba(210, 145, 113, 1)'
    },
    Helpless: {
      light: 'rgba(210, 145, 113, 1)',
      dark: 'rgba(210, 145, 113, 1)'
    },
    Frightened: {
      light: 'rgba(210, 145, 113, 1)',
      dark: 'rgba(210, 145, 113, 1)'
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          height: '2.7rem',
          textTransform: 'none',
          fontSize: CTTheme.typography.fontSizes.regular
        }
      }
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontSize: CTTheme.typography.fontSizes.regular
        }
      }
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          height: 'auto !important'
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          borderColor: '#ffffff'
        }
      }
    },
    MuiInput: {
      styleOverrides: {
        root: {
          color: '#333333'
        }
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        colorPrimary: {
          color: '#edb801' // gold
        }
      }
    }
  }
})

export default theme
