import React, { useEffect, useState, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import _ from 'lodash'
import { analytics } from '../../../firebase/firebase'
import { getProfileByUserID } from '../../../redux/thunks/profiles/getProfileByUserID'
import isVerifiedUser from '../../../utils/functions/isVerifiedUser'
import Header from './components/header/header'
import Details from './components/details/details'
import Tabs from './components/tabs/tabs'
import Posts from '../../partials/posts/posts'
// import VerificationWidget from '../../partials/verification-widget/verification-widget'
import ConnectWith from '../../partials/connect-with/connect-with'
import SuggestedConnections from '../../partials/suggested-connections/suggested-connections'
import FirstPostPrompt from './components/first-post-prompt/first-post-prompt'
import InviteFriends from '../../partials/invite-friends/invite-friends'
// import DonateTokens from '../../partials/donate-tokens/donate-tokens'
// import Tooltip from '../../partials/tooltip/tooltip'
import { useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import {
  ProfileContainer,
  ContentContainer,
  FeedContainer,
  FeedListContainer
} from './styled/profile-comps'

function Profile() {
  const { userId: userIdParam } = useParams()
  const [isLoading, setIsLoading] = useState(false)
  const [profileFound, setProfileFound] = useState(true)
  const [isCurrentUser, setIsCurrentUser] = useState(false)
  // const mainRef = useRef(null)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const [tab, setTab] = useState('public')
  const currentUser = useSelector(({ currentUser }) => currentUser)
  const currentProfile = useSelector(({ currentProfile }) => currentProfile)
  const loadedProfiles = useSelector(({ loadedProfiles }) => loadedProfiles)
  const follows = useSelector(({ follows }) => follows)
  const dispatch = useDispatch()
  const mixinComponents = [<InviteFriends key={'1'} />]

  useEffect(() => {
    const mobileView = window.innerWidth <= 1022
    // const userId = match.params.userId

    if (mobileView) {
      setTab('details')
    }

    // Fetch profile if not current profile
    if (userIdParam !== currentUser.id) {
      dispatch(getProfileByUserID({ userId: userIdParam, isCurrentUser: false }))
    } else {
      setIsCurrentUser(true)
    }

    analytics.logEvent('view_profile')
  }, [userIdParam])

  const profile = userIdParam === currentUser.id ? currentProfile : loadedProfiles[userIdParam]

  if (!profile) return null

  const {
    userId,
    showProfileSuggestedConnections,
    numPublicTakes,
    numDiscussionReq,
    numHiddenTakes,
    tagsOfInterest
  } = profile

  const newTagsOfInterest = tagsOfInterest || []

  const isFollowing = follows.some((follower) => follower.follower === currentUser.id)

  const showSuggestedConnections = isCurrentUser && showProfileSuggestedConnections
  const showFirstPostPrompt =
    isCurrentUser && tab === 'public' && numPublicTakes + numHiddenTakes === 0
  const showConnectWith = !isCurrentUser && !isFollowing

  const sharedInterests = []
  currentProfile.tagsOfInterest.forEach((id) => {
    if (_.includes(newTagsOfInterest, id)) {
      sharedInterests.push(id)
    }
  })

  return (
    <ProfileContainer>
      <Header profile={profile} isCurrentUser={isCurrentUser} />

      <ContentContainer>
        {!isMobile && <Details profile={profile} isCurrentUser={isCurrentUser} />}

        {/** Can we create a new component <FEED />  ??? 
          props: profile, showConnectWith, sharedInterests, 
        */}
        <FeedContainer>
          {showConnectWith && sharedInterests.length > 0 && (
            <ConnectWith profile={profile} sharedInterests={sharedInterests} />
          )}

          <Tabs
            tab={tab}
            setTab={setTab}
            isCurrentUser={isCurrentUser}
            numPublicTakes={numPublicTakes}
            numDiscussionReq={numDiscussionReq}
            profile={profile}
            numHiddenTakes={numHiddenTakes}
          />

          {/* {isCurrentUser && <VerificationWidget />} */}

          {showSuggestedConnections && (
            <SuggestedConnections
              disableFromInterests={true}
              disableFromInsights={true}
              hidePrimaryHeading={true}
              hideSecondaryHeading={true}
              hideSubheading={true}
              customSecondaryHeading={'Welcome to CivilTalk.'}
              customSubheading={"Let's connect you with others to better your experience."}
              showCloseButton={true}
              hideOnNoResults={true}
              style={{ paddingTop: '.1rem' }}
            />
          )}

          {showFirstPostPrompt && <FirstPostPrompt />}

          <FeedListContainer>
            {tab === 'details' && isMobile && (
              <Details profile={profile} isCurrentUser={isCurrentUser} />
            )}

            {tab === 'public' && (
              <Posts
                variant={'user-host'}
                tags={[]}
                userId={userId}
                hideSorting={true}
                hideFilters={true}
                searchStyle={{ backgroundColor: 'white', boxShadow: 'none !important' }}
                searchVariant={'no-shadow'}
                hideOnNoResults={isCurrentUser}
                mixinComponents={mixinComponents}
              />
            )}

            {tab === 'guest' && (
              <Posts variant={'user-guest'} userId={userId} hideActionBar={true} />
            )}

            {tab === 'hidden' && isCurrentUser && (
              <Posts variant={'user-hidden'} tags={[]} userId={userId} hideActionBar={true} />
            )}
          </FeedListContainer>
        </FeedContainer>
      </ContentContainer>
    </ProfileContainer>
  )
}

export default Profile
