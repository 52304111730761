import React, { useRef, useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Header from './components/header/header'
import Activity from './components/activity/activity'
import Discover from './components/discover/discover'
import MyCourses from '../my-courses/my-courses'
import InviteFriends from '../../partials/invite-friends/invite-friends'
import Quote from '../../partials/quote/quote'
import WelcomeDialog from './components/welcome/welcome'
import { MainContainer, LeftContainer, RightContainer } from './styled/home-private-comps'
import { getResponsiveStyles } from './responsive-styles/home-private'
import Connect from './components/connect/connect'

const HomePrivate = () => {
  const { action: paramAction, tagId } = useParams()
  const navigate = useNavigate()
  const mainRef = useRef(null)
  const [didRecognizeRef, setDidRecognizeRef] = useState(false)
  const [componentWidth, setComponentWidth] = useState(null)
  const [responsiveStyles, setResponsiveStyles] = useState(getResponsiveStyles(componentWidth))
  const showWelcome = useSelector(({ currentProfile }) => currentProfile.showWelcome)

  const handleSetComponentWidth = () => {
    const width = mainRef.current ? mainRef.current.clientWidth : window.innerWidth

    setComponentWidth(width)
  }

  useEffect(() => {
    window.addEventListener('resize', handleSetComponentWidth)

    return () => {
      window.removeEventListener('resize', handleSetComponentWidth)
    }
  }, [])

  useEffect(() => {
    if (!didRecognizeRef) {
      if (mainRef.current !== null && typeof mainRef.current !== 'undefined') {
        setComponentWidth(mainRef.current.clientWidth)
        setDidRecognizeRef(true)
      }
    }
  }, [mainRef, didRecognizeRef])

  useEffect(() => {
    setResponsiveStyles(getResponsiveStyles(componentWidth))
  }, [componentWidth])

  useEffect(() => {
    const possibleActions = ['learn', 'post', 'activity', 'discover', 'connect', 'my-courses']

    if (paramAction && !possibleActions.includes(paramAction)) {
      navigate('/page-not-found', { replace: true })
      return
    }

    if (!paramAction) {
      navigate('/home/activity', { replace: true })
    }
  }, [paramAction, navigate])

  const handleActionChange = (newAction) => {
    if (newAction === paramAction) {
      return // Don't navigate if we're already on this action
    }
    navigate(`/home/${newAction}`, { replace: true })
  }

  const ActivityProps = {
    action: paramAction || 'activity',
    setAction: handleActionChange
  }

  return (
    <MainContainer
      ref={mainRef}
      marginTop={responsiveStyles.mainContainer.marginTop}
      padding={responsiveStyles.mainContainer.padding}>
      <LeftContainer width={responsiveStyles.leftContainer.width}>
        <Header action={paramAction || 'activity'} setAction={handleActionChange} />

        {(paramAction || 'activity') === 'activity' && <Activity {...ActivityProps} />}
        {/* action === 'learn' && <Learn /> **/}
        {/* action === 'post' && <Post /> **/}
        {paramAction === 'my-courses' && <MyCourses />}
        {paramAction === 'discover' && <Discover />}
        {paramAction === 'connect' && <Connect />}
      </LeftContainer>
      <RightContainer display={responsiveStyles.rightContainer.display}>
        {/* <InviteFriends /> */}
        <Quote />
      </RightContainer>
      {showWelcome && <WelcomeDialog showWelcome={showWelcome} />}
    </MainContainer>
  )
}

export default HomePrivate
