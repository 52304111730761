import React, { useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { actionResponseActions } from '../../../../../redux/action-creators/action-response'
import { hideTakeByID } from '../../../../../redux/thunks/takes/hideTakeByID'
import { showTakeByID } from '../../../../../redux/thunks/takes/showTakeByID'
import { archiveTakeByID } from '../../../../../redux/thunks/takes/archiveTakeByID'
import { createView } from '../../../../../redux/thunks/views/createView'
import isVerifiedUser from '../../../../../utils/functions/isVerifiedUser'
import ReportForm from '../../../../partials/report-form/report-form'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import { Button, ClickAwayListener, Grow, Paper, Popper, MenuList, MenuItem } from '@mui/material'
import { MoreHoriz, ExitToApp, Link, Create, Visibility, Delete, Flag } from '@mui/icons-material'
import { NavItemContainer, NavItem } from './styled/dropdown-menu-comps'
import config from '../../../../../config'
// import theme from '../../../../../res/theme'

const useStyles = makeStyles((theme) =>
  createStyles({
    dropdownButton: {
      borderRadius: '.2rem',
      minHeight: '1.5rem',
      height: '1.5rem',
      minWidth: '2.5rem',
      width: '2.5rem',
      position: 'absolute',
      top: '1rem',
      right: '1rem',
      boxShadow: 'none',
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.common.white,
      '&:hover': {
        backgroundColor: theme.palette.secondary.light,
        boxShadow: 'none'
      },
      '& svg': {
        // color: 'white',
        fontSize: '1.8rem',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translateX(-50%) translateY(-50%)'
      }
      // [theme.breakpoints.down('xs')]: {
      //   minHeight: '1.25rem',
      //   height: '1.25rem'
      // }
    },
    menuList: {
      padding: '.4rem 0',
      width: '12rem'
    },
    dropdownItem: {
      padding: '.4rem 1rem'
    },
    divider: {
      margin: '.4rem 0'
    },
    dropdownIcon: {
      width: '1rem',
      height: 'auto',
      marginRight: '.7rem',
      marginTop: '1px',
      verticalAlign: 'middle'
    }
  })
)

const DropdownMenu = (props) => {
  const { take, hasDiscussions, handleSetEditing, viewsParams } = props
  const navigate = useNavigate()
  const location = useLocation()
  const classes = useStyles()
  const ref = useRef(null)
  const [open, setOpen] = useState(false)
  const [reportFormOpen, setReportFormOpen] = useState(false)
  const currentUser = useSelector(({ currentUser }) => currentUser)
  const currentProfile = useSelector(({ currentProfile }) => currentProfile)
  const dispatch = useDispatch()
  const isHostedByUser = currentUser.id === take.userId

  const goToTake = (e) => {
    e.preventDefault()
    e.stopPropagation()

    viewsParams && dispatch(createView(viewsParams))

    navigate(`/post/${take._id}`)
  }

  const copyToClipboard = () => {
    dispatch(actionResponseActions.createActionResponse('Copied to Clipboard!'))

    setOpen(false)
  }

  const edit = () => {
    handleSetEditing(true)
    setOpen(false)
  }

  const hideTake = () => {
    dispatch(
      hideTakeByID({
        takeId: take._id,
        currentProfile
      })
    ).then((data) => {
      if (!data.error) {
        console.log('[SUCCESS]: ', 'Successfully hid take!')
      } else {
        console.log('[FAIL]: ', 'Failed to hide take.')
      }

      setOpen(false)
    })
  }

  const showTake = () => {
    dispatch(
      showTakeByID({
        takeId: take._id,
        currentProfile
      })
    ).then((data) => {
      if (!data.error) {
        console.log('[SUCCESS]: ', 'Successfully displayed take!')
      } else {
        console.log('[FAIL]: ', 'Failed to display take.')
      }

      setOpen(false)
    })
  }

  const archiveTake = () => {
    dispatch(
      archiveTakeByID({
        takeId: take._id,
        currentProfile,
        wasPublic: !take.hidden
      })
    ).then((data) => {
      if (!data.error) {
        console.log('[SUCCESS]: ', 'Successfully archived take!')
      } else {
        console.log('[FAIL]: ', 'Failed to archive take.')
      }
    })
  }

  const report = () => {
    setReportFormOpen(true)
    setOpen(false)
  }

  return (
    <>
      {reportFormOpen && <ReportForm for={take.userId} setOpen={setReportFormOpen} />}

      <Button
        variant="contained"
        color="primary"
        className={classes.dropdownButton}
        ref={ref}
        aria-owns={open ? 'menu-dropdown' : undefined}
        aria-haspopup="true"
        aria-label="Post Menu Options"
        onClick={() => setOpen(!open)}>
        <MoreHoriz fontSize="large" role="img" />
      </Button>

      <Popper
        open={open}
        anchorEl={ref.current}
        placement="bottom-end"
        transition
        style={{ zIndex: 100 }}>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            id="menu-dropdown"
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
            }}>
            <Paper>
              <ClickAwayListener onClickAway={() => setOpen(false)}>
                <MenuList className={classes.menuList}>
                  {!location.pathname.includes('/take/') && (
                    <MenuItem className={classes.dropdownItem} onClick={goToTake}>
                      <NavItemContainer>
                        <ExitToApp className={classes.dropdownIcon} />
                        <NavItem>Visit Take</NavItem>
                      </NavItemContainer>
                    </MenuItem>
                  )}

                  <CopyToClipboard
                    text={`${config.baseURL}/post/${take._id}`}
                    onCopy={copyToClipboard}>
                    <MenuItem className={classes.dropdownItem}>
                      <NavItemContainer>
                        <Link className={classes.dropdownIcon} />
                        <NavItem>Copy URL</NavItem>
                      </NavItemContainer>
                    </MenuItem>
                  </CopyToClipboard>

                  {isHostedByUser && (
                    <div>
                      <MenuItem
                        className={classes.dropdownItem}
                        onClick={edit}
                        disabled={hasDiscussions && take.userId === currentUser.id}>
                        <NavItemContainer>
                          <Create className={classes.dropdownIcon} />
                          <NavItem>Edit</NavItem>
                        </NavItemContainer>
                      </MenuItem>

                      {!take.hidden && (
                        <MenuItem className={classes.dropdownItem} onClick={hideTake}>
                          <NavItemContainer>
                            <Visibility className={classes.dropdownIcon} />
                            <NavItem>Move to History</NavItem>
                          </NavItemContainer>
                        </MenuItem>
                      )}

                      {take.hidden && (
                        <MenuItem className={classes.dropdownItem} onClick={showTake}>
                          <NavItemContainer>
                            <Visibility className={classes.dropdownIcon} />
                            <NavItem>Show to Public</NavItem>
                          </NavItemContainer>
                        </MenuItem>
                      )}

                      <MenuItem className={classes.dropdownItem} onClick={archiveTake}>
                        <NavItemContainer>
                          <Delete className={classes.dropdownIcon} />
                          <NavItem>Delete</NavItem>
                        </NavItemContainer>
                      </MenuItem>
                    </div>
                  )}

                  {!isHostedByUser && (
                    <MenuItem
                      className={classes.dropdownItem}
                      onClick={report}
                      disabled={!isVerifiedUser(currentProfile)}>
                      <NavItemContainer>
                        <Flag className={classes.dropdownIcon} />
                        <NavItem>Report</NavItem>
                      </NavItemContainer>
                    </MenuItem>
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  )
}

export default DropdownMenu
