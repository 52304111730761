import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { useEffect, useMemo, useReducer } from 'react'
import { stableSort, getSorting } from './utils'
import {
  CourseContainer,
  CustomTableCell,
  Form,
  FormData,
  FormLabel,
  MainHeaderContainer,
  MainHeaderTitle,
  PageContainer,
  styles
} from './styles'
import './styles/style.css'
import withStyles from '@mui/styles/withStyles'
import { getCourseByID } from '../../../redux/thunks/courses/getCoursesByID'
import { useParams } from 'react-router-dom'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import TablePagination from '@mui/material/TablePagination'
import Tooltip from '@mui/material/Tooltip'
import { CSVLink } from 'react-csv'
import { Button, Typography, Box } from '@mui/material'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'

const headers = [
  { key: 'email', label: 'Email' },
  { key: 'lname', label: 'Last Name' },
  { key: 'fname', label: 'First Name' },
  { key: 'numDiscussionReq', label: 'Initiated Discussions' },
  { key: 'numPublicTakes', label: 'Public Posts' },
  { key: 'ratingCount', label: 'Rating Count' },
  { key: 'ratingScore', label: 'Rating Score' }
]

const initState = {
  order: 'desc',
  orderBy: 'whenCreated',
  selected: [],
  page: 0,
  rowsPerPage: 10,
  error: '',
  report: {}
}

const EnhancedTableHead = ({ onRequestSort, order, orderBy }) => {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }
  return (
    <TableHead>
      <TableRow>
        {headers?.map((row) => {
          return (
            <CustomTableCell
              sx={{
                '&:hover': {
                  background: 'secondary.main'
                }
              }}
              key={row.key}
              align={'left'}
              padding={'normal'}
              sortDirection={orderBy === row.key ? order : false}>
              <Tooltip title={'Sort by ' + row.label} placement="top-end" enterDelay={300}>
                <TableSortLabel
                  sx={{
                    fontWeight: 600,
                    color: '#000',
                    '&:hover': {
                      color: '#000'
                    },
                    '&:active': {
                      color: '#000'
                    },
                    '& .MuiTableSortLabel-root': {
                      color: '#000',
                      '& .MuiTableSortLabel-icon': {
                        fill: '#000',
                        color: '#000',
                        background: 'green'
                      }
                    }
                  }}
                  active={orderBy === row.key}
                  direction={order}
                  onClick={createSortHandler(row.key)}>
                  {row.label}
                </TableSortLabel>
              </Tooltip>
            </CustomTableCell>
          )
        })}
      </TableRow>
    </TableHead>
  )
}
EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired
}

function Report(props) {
  const classes = props.classes
  const { courseId } = useParams()
  const dispatch = useDispatch()

  const [state, updateEvent] = useReducer((prev, next) => {
    return { ...prev, ...next }
  }, initState)

  function handleChangePage(event, page) {
    updateEvent({
      page
    })
  }

  function handleChangeRowsPerPage(event) {
    updateEvent({
      rowsPerPage: event.target.value
    })
  }

  function checkIsSelected(id) {
    return state.selected.indexOf(id) !== -1
  }

  function handleRequestSort(event, property) {
    const orderBy = property
    let order = 'desc'

    if (state.orderBy === property && state.order === 'desc') {
      order = 'asc'
    }

    updateEvent({
      order,
      orderBy
    })
  }

  useEffect(() => {
    dispatch(getCourseByID({ courseId }))
      .then((result) => {
        if (result.success) {
          result?.data && updateEvent({ report: result?.data })
          console.log('[SUCCESS]: ', 'Successfully retrieved report by course ID!')
        } else {
          updateEvent({ error: result?.message })
          console.log('[FAIL]: ', 'Failed to retrieve report by course ID.')
        }
      })
      .catch((e) => {
        updateEvent({ error: e?.message })
      })
  }, [courseId])

  function createData(
    email,
    lname,
    fname,
    numDiscussionReq,
    numPublicTakes,
    ratingCount,
    ratingScore
  ) {
    return {
      email,
      lname,
      fname,
      numDiscussionReq,
      numPublicTakes,
      ratingCount,
      ratingScore
    }
  }

  const members = useMemo(() => {
    return state.report?.members?.map((member) => {
      const { email, lname, fname, numDiscussionReq, numPublicTakes, rating } = member
      return createData(
        email,
        lname,
        fname,
        numDiscussionReq,
        numPublicTakes,
        rating?.count,
        rating?.score
      )
    })
  }, [state.report?.members])

  return (
    <PageContainer>
      <MainHeaderContainer>
        <Typography
          sx={{
            marginBottom: '1rem',
            lineHeight: '2rem',
            fontSize: '2rem',
            fontWeight: 400,
            color: 'text.primary',
            '@media (max-width: 600px)': {
              lineHeight: '1.6rem',
              fontSize: '1.6rem'
            }
          }}>
          Course Report
        </Typography>
      </MainHeaderContainer>

      <CourseContainer>
        <Box
          sx={{
            padding: '1rem 0',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around'
          }}>
          <Typography sx={{ fontSize: '1.2rem', marginBottom: '0.5rem' }}>Course Title:</Typography>
          <Typography sx={{ fontSize: '1.2rem', marginBottom: '0.5rem' }}>
            {state.report?.title}
          </Typography>
        </Box>

        {members?.length > 0 && (
          <Button variant="contained" className={classes.button}>
            <CSVLink
              className={'report-csv'}
              filename={'civiltalk-report.csv'}
              data={members}
              headers={headers}>
              Download CSV
            </CSVLink>
          </Button>
        )}
        <Paper className={classes.root}>
          <div className={classes.tableWrapper}>
            <Table className={classes.table} aria-labelledby="tableTitle">
              <EnhancedTableHead
                numSelected={state.selected.length}
                order={state.order}
                orderBy={state.orderBy}
                onRequestSort={handleRequestSort}
                rowCount={headers.length}
              />

              <TableBody>
                {members &&
                  stableSort(members, getSorting(state.order, state.orderBy))
                    .slice(
                      state.page * state.rowsPerPage,
                      state.page * state.rowsPerPage + state.rowsPerPage
                    )
                    .map((n) => {
                      const isSelected = checkIsSelected(n.id)
                      return (
                        <TableRow
                          hover
                          aria-checked={isSelected}
                          tabIndex={-1}
                          key={n.key}
                          className={classes.row}
                          selected={isSelected}>
                          <CustomTableCell component="th" scope="row">
                            {n.email}
                          </CustomTableCell>
                          <CustomTableCell>{n?.lname}</CustomTableCell>
                          <CustomTableCell>{n?.fname}</CustomTableCell>
                          <CustomTableCell>{n?.numDiscussionReq}</CustomTableCell>
                          <CustomTableCell>{n?.numPublicTakes}</CustomTableCell>
                          <CustomTableCell>{n?.ratingCount}</CustomTableCell>
                          <CustomTableCell>{n?.ratingScore}</CustomTableCell>
                        </TableRow>
                      )
                    })}
              </TableBody>
            </Table>
          </div>

          <TablePagination
            sx={{
              '& .MuiTablePagination-selectLabel': {
                fontSize: '1rem'
              },
              '& .MuiTablePagination-displayedRows': {
                fontSize: '1rem'
              },
              '& .MuiTablePagination-actions': {
                '& .MuiButtonBase-root': {}
              }
            }}
            rowsPerPageOptions={[5, 10, 25, 50]}
            component="div"
            count={state?.report?.members?.length || 0}
            rowsPerPage={state.rowsPerPage}
            page={state.page}
            backIconButtonProps={{
              'aria-label': 'Previous Page'
            }}
            nextIconButtonProps={{
              'aria-label': 'Next Page'
            }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </CourseContainer>
    </PageContainer>
  )
}

Report.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(Report)
