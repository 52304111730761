import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import load from './functions/load'
import { getAssignedRoles } from '../../../redux/thunks/assigned-roles/getAssignedRoles'
import { getOrganizationRolesByID } from '../../../redux/thunks/roles/getOrganizationRolesByID'
import { getFollowing } from '../../../redux/thunks/follows/getFollowing'
import { getOrganizationsByIDs } from '../../../redux/thunks/organizations/getOrganizationsByIDs'
import Center from './components/center/center'
import { OuterContainer } from './styled/organizations-comps'

const Organizations = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [managingOrganizationIds, setManagingOrganizationIds] = useState([])
  const currentUser = useSelector(({ currentUser }) => currentUser)
  const dispatch = useDispatch()

  const handleLoad = useCallback(() => {
    const actions = {
      getAssignedRoles,
      getOrganizationRolesByID,
      getFollowing,
      getOrganizationsByIDs
    }

    load(currentUser.id, setIsLoading, setManagingOrganizationIds, dispatch, actions)
  }, [currentUser.id, dispatch])

  useEffect(() => {
    if (isLoading && currentUser.id) {
      handleLoad()
    }
  }, [currentUser.id, handleLoad, isLoading])

  return (
    <OuterContainer id="organizations-list">
      <Center isLoading={isLoading} managingOrganizationIds={managingOrganizationIds} />
    </OuterContainer>
  )
}

export default Organizations
