import React from 'react'
import StartConversation from '../start-conversation/start-conversation'
import Posts from '../../../../partials/posts/posts'
import Polls from '../../../../partials/polls/polls'
// import DonateTokens from '../../../../partials/donate-tokens/donate-tokens'
import MyOrganizations from '../../../../partials/my-organizations/my-organizations'
import InviteFriends from '../../../../partials/invite-friends/invite-friends'
import Quote from '../../../../partials/quote/quote'
import { MainContainer, HeadingPrimary } from './styled/activity-comps'

const Activity = ({ action, setAction }) => {
  const fullscreen = window.innerWidth <= 875
  const mixinComponents = [<Polls key={'2'} />, <InviteFriends key={'3'} />, <Quote key={'4'} />]

  return (
    <>
      <MainContainer
        style={fullscreen ? { borderRadius: '0', padding: '1rem 0.5rem 1.5rem 0.5rem' } : {}}>
        <StartConversation />

        <HeadingPrimary>Let's see what the community is discussing:</HeadingPrimary>
        <Posts
          variant={'general'}
          tags={[]}
          mixinComponents={fullscreen ? mixinComponents : []}
          // isQuestion={false}
          // excludeCurrentUser={true}
          // hideActionBar={true}
          // hideTags={true}
          // hideTagRemove={true}
          // hideTagsSearch={true}
          // hideSorting={true}
          // hideFilters={true}
          // preventInfiniteScroll={true}
          initialFilter={''}
        />
      </MainContainer>
    </>
  )
}

export default Activity
