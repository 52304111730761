import React, { Component } from 'react'
import PropTypes from 'prop-types'
import withStyles from '@mui/styles/withStyles'
import { Skeleton } from '@mui/material'

const animation = 'pulse'

const styles = () => ({
  accolades: {
    display: 'inline-block',
    backgroundColor: 'rgba(255,255,255,.2)',
    width: '4rem',
    height: '2.8rem',
    borderRadius: '500px'
  }
})

class SkeletonAccolades extends Component {
  render() {
    const { classes, style } = this.props
    const customStyles = style || {}

    return (
      <div className="user-accolades" style={customStyles}>
        <Skeleton
          animation={animation}
          variant="rectangular"
          height={'2.8rem'}
          className={classes.accolades}
        />
      </div>
    )
  }
}

SkeletonAccolades.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(SkeletonAccolades)
