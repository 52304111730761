import styled from 'styled-components'

export const LogoCondensed = styled.img`
  cursor: pointer;
  display: block;
  height: 7rem;
  width: auto;
  margin: 0 auto;
  text-align: center;

  @media (max-width: 359px) {
    height: 4rem;
    width: 4rem;
  }
`
