import { useNavigate } from 'react-router-dom'
import UserAvatar from '../../../partials/user-avatar/user-avatar'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { styled } from '@mui/material/styles'
import {
  OuterContainer,
  Header,
  TabContainer,
  Tab,
  ResultContainer,
  AvatarContainer,
  ProfileInfoContainer,
  CourseName,
  CourseSeason,
  ProfileLocation,
  NoResults
} from '../styled/my-courses-comps'

const StyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  width: '100%',
  boxShadow: '0px 7px 5px -2px rgba(100, 100, 100, 0.1)',
  backgroundColor: theme.palette.common.white,
  borderRadius: '0.25rem',
  padding: '0.8rem 1rem',
  boxSizing: 'border-box',
  position: 'relative',
  marginBottom: '1rem',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    marginBottom: '0.75rem'
  }
}))

const StyledButton = styled(Button)(({ theme }) => ({
  minWidth: '9rem'
}))

function AddCourseCard({ mode }) {
  const navigate = useNavigate()

  const redirect = () => {
    navigate(`/course-setup`)
  }

  if (mode !== 'report') return null

  return (
    <StyledCard
      key={Math.random()}
      sx={{
        // display: 'flex',
        // flexDirection: 'column',
        // alignItems: 'flexStart',
        // justifyContent: 'center',
        // height: '6rem'
        display: 'flex',
        justifyContent: 'space-around',
        flex: 3,
        '@media (max-width:600px)': {
          paddingBottom: '1rem'
        }
      }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-around',
          flex: 3,
          '@media (max-width:600px)': {
            paddingBottom: '1rem'
          }
        }}>
        <AvatarContainer>
          <UserAvatar
            src={`https://res.cloudinary.com/headliner/image/upload/v1673995817/badges/husky-dog-logo-circleR.jpg`}
            width={'3.05rem'}
            height={'3.05rem'}
            borderWidth={'0.2rem'}
            verified={1}
          />
        </AvatarContainer>
        <ProfileInfoContainer>
          <Typography
            sx={{
              fontSize: '1.05rem',
              fontWeight: 600,
              color: 'text.primary'
            }}>
            {mode === 'report' ? 'Add a new Course' : 'Add a new Course'}
          </Typography>
        </ProfileInfoContainer>
      </Box>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          flex: 1
        }}>
        <StyledButton
          variant="contained"
          startIcon={<AddCircleOutlineIcon />}
          // id={}
          onClick={redirect}>
          {mode === 'report' ? 'Add course' : 'Add course'}
        </StyledButton>
      </div>
    </StyledCard>
  )
}

export default AddCourseCard
