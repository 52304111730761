import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import LogRocket from 'logrocket'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import './App.css'
import { appLoadedActions } from '../redux/action-creators/app-loaded'
import { checkAuth } from '../redux/thunks/users/checkAuth'
import AppLoader from './app-loader/app-loader'
import SkipLink from './skip-link/skip-link'
import Header from './header/header'
import Main from './main/main'
import InviteFriendsModal from './modal/invite-friends-modal'
import TokensTransactionModal from './modal/tokens-transaction-modal/tokens-transaction-modal'
import Loader from './loader/loader'
import Toaster from './toaster/toaster'

class App extends Component {
  constructor(props) {
    super(props)

    this.state = {
      fireBaseError: ''
    }

    this.handleSetState = this.handleSetState.bind(this)
  }

  componentDidMount() {
    // Try to automatically log user in if possible
    this.props.checkAuth().then((data) => {
      this.props.setAppLoaded()
    })

    if (import.meta.env.VITE_NODE_ENV !== 'local') {
      console.log(`[DEBUG]: loading logRocket in App`)
      LogRocket.init('nywuku/civiltalk')

      if (this.props.isAuthenticated) {
        console.log(`[DEBUG]: user is authenticated, update logrocket meta.`)

        const { currentUser } = this.props

        const uid = currentUser._id.toString()

        LogRocket.identify(uid, {
          name: `${currentUser.fname} ${currentUser.lname}`,
          email: `${currentUser.email}`
        })
      }
    }

    if (import.meta.env.VITE_NODE_ENV === 'production') {
      const robots = document.createElement('meta')
      robots.setAttribute('name', 'robots')
      robots.setAttribute('content', 'index')
      document.getElementsByTagName('head')[0].appendChild(robots)

      // const siteVerificationMeta = document.createElement('meta')
      // siteVerificationMeta.setAttribute('name', 'google-site-verification')
      // siteVerificationMeta.setAttribute('content', 'CiMV-vxA0U_HrH3FnT78IEnhiMh_zvvsJ6Xf4LnqEgA')
      // document.getElementsByTagName('head')[0].appendChild(siteVerificationMeta)
    } else {
      const robots = document.createElement('meta')
      robots.setAttribute('name', 'robots')
      robots.setAttribute('content', 'noindex')
      document.getElementsByTagName('head')[0].appendChild(robots)
    }
  }

  handleSetState(object) {
    this.setState(object)
  }

  render() {
    const { appLoaded, inviteFriends, tokensTransactionModal } = this.props

    if (!appLoaded) {
      return <AppLoader />
    }

    return (
      <div className="App">
        {import.meta.env.VITE_NODE_ENV === 'production' && (
          <Helmet>
            <script>
              {`;(function (w, d, s, l, i) {
        w[l] = w[l] || []
        w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm' })
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != 'dataLayer' ? '&l=' + l : ''
        j.async = true
        j.src =
          'https://www.googletagmanager.com/gtm?id=' +
          i +
          dl +
          '&gtm_auth=vbhmKu9YmYr7laeD7RDu0Q&gtm_preview=env-1&gtm_cookies_win=x'
        f.parentNode.insertBefore(j, f)
      })(window, document, 'script', 'dataLayer', 'GTM-KDRCQXJ')`}
            </script>
          </Helmet>
        )}

        {tokensTransactionModal.open && <TokensTransactionModal />}
        {inviteFriends && <InviteFriendsModal />}
        <SkipLink />
        <Header />
        <Main className="Main" />
        <Loader />
        <Toaster />
        <ReactQueryDevtools initialIsOpen={false} />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  appLoaded: state.appLoaded,
  inviteFriends: state.inviteFriends,
  tokensTransactionModal: state.tokensTransactionModal,
  currentUser: state.currentUser,
  isAuthenticated: state.isAuthenticated
})

const mapDispatchToProps = (dispatch) => ({
  setAppLoaded: () => {
    return dispatch(appLoadedActions.set())
  },
  checkAuth: () => {
    return dispatch(checkAuth())
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(App)
