import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import { useNavigate } from 'react-router-dom'
import { getPopularTags } from '../../../redux/thunks/tags/getPopularTags'
import load from './functions/load'
import { TagCardsContainer, TagCard } from './styled/popular-tags-comps'
import { getResponsiveStyles } from './responsive-styles/popular-tags'
import Button from '@mui/material/Button'
import useMediaQuery from '@mui/material/useMediaQuery'

function PopularTags(props) {
  const { tagIds, setTagIds, setError, days, limit, hideTagAdd, activateLinks, style, tagStyle } =
    props

  const mainRef = useRef(null)
  const navigate = useNavigate()
  const [didRecognizeRef, setDidRecognizeRef] = useState(false)
  const [componentWidth, setComponentWidth] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [tagsExist, setTagsExist] = useState(true)
  const [responsiveStyles, setResponsiveStyles] = useState(getResponsiveStyles(componentWidth))
  const popularTags = useSelector(({ popularTags }) => popularTags)
  const dispatch = useDispatch()
  const isMobile = useMediaQuery('(max-width:400px)')

  const handleSetComponentWidth = () => {
    const width = mainRef.current ? mainRef.current.clientWidth : window.innerWidth

    setComponentWidth(width)
  }

  useEffect(() => {
    window.addEventListener('resize', handleSetComponentWidth)

    return () => {
      window.removeEventListener('resize', handleSetComponentWidth)
    }
  }, [])

  useEffect(() => {
    if (isLoading && days && limit) {
      const loadActions = { getPopularTags: (params) => dispatch(getPopularTags(params)) }

      load(days, limit, setIsLoading, setTagsExist, loadActions)
    }
  }, [days, dispatch, isLoading, limit])

  useEffect(() => {
    if (!didRecognizeRef) {
      if (mainRef.current !== null && typeof mainRef.current !== 'undefined') {
        setComponentWidth(mainRef.current.clientWidth)
        setDidRecognizeRef(true)
      }
    }
  }, [mainRef, didRecognizeRef])

  useEffect(() => {
    setResponsiveStyles(getResponsiveStyles(componentWidth))
  }, [componentWidth])

  const handleSelectTag = (e) => {
    e.preventDefault()

    const { id } = e.target

    if (_.includes(tagIds, id)) {
      return setError(`${id} has already been tagged.`)
    }

    setTagIds([...tagIds, id])

    if (setError) {
      setError('')
    }
  }

  const handleGoToTag = (tag) => {
    navigate(`/topic/${tag}`)
  }

  const filteredTags = _.filter(popularTags, (tag) => {
    if (_.includes(tagIds, tag.tagId)) {
      return false
    } else {
      return true
    }
  })

  const tags = filteredTags.map((popularTag) => {
    const {
      tagId
      // numPosts
    } = popularTag

    return (
      <Button
        variant="contained"
        size={isMobile ? 'small' : 'medium'}
        color={'secondary'}
        sx={{
          margin: '0.25rem',
          bgcolor: 'secondary.light',
          color: 'text.primary',
          boxShadow: 3,
          '@media (max-width: 400px)': {
            fontSize: '0.9375rem',
            margin: '0.25rem',
            height: '2.25rem'
          }
        }}
        key={tagId}
        onClick={activateLinks ? () => handleGoToTag(tagId) : () => {}}>
        {tagId}
      </Button>
    )
  })

  if (isLoading) return null
  if (!isLoading && !tagsExist) return null

  return (
    <TagCardsContainer
      ref={mainRef}
      style={style || {}}
      marginRight={responsiveStyles.tagCardsContainer.marginRight}>
      {tags}
    </TagCardsContainer>
  )
}

export default PopularTags
