import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { updateNickname } from '../../../../../../../redux/thunks/users/updateNickname'
import getName from '../../../../../../../utils/functions/getName'
import updateNicknameValidation from './validations/update-nickname'
import ConfirmationDialog from '../../../../../../dialogs/dialog-confirmation'
import FormError from '../../../../../../alerts/form-error'
import PropTypes from 'prop-types'
import withStyles from '@mui/styles/withStyles'
import { Button, Tooltip } from '@mui/material'
import { Help } from '@mui/icons-material'
import {
  ItemContainer,
  InputLabel,
  InputsContainer,
  InputPreprop,
  Input
} from './styled/nickname-form-comps'
import config from '../../../../../../../config'

const getTimeUntilNextUpdate = (lastNicknameChange, returnDateString) => {
  if (lastNicknameChange === 0) {
    return 0
  } else if (Date.now() - lastNicknameChange > config.timeLimits.updateNickname) {
    if (returnDateString) {
      const lastUpdateDate = new Date(lastNicknameChange)
      const futureDate = new Date(lastUpdateDate.getTime() + config.timeLimits.updateNickname)

      return `${moment(futureDate).format('MMM Do YYYY')} at ${moment(futureDate).format('h:mm a')}`
    }

    return 0
  }

  const lastUpdateDate = new Date(lastNicknameChange)
  const futureDate = new Date(lastUpdateDate.getTime() + config.timeLimits.updateNickname)

  return `${moment(futureDate).format('MMM Do YYYY')} at ${moment(futureDate).format('h:mm a')}`
}

const styles = (theme) => ({
  actionButton: {
    textTransform: 'capitalize',
    width: '15rem',
    margin: '1rem 0 0 35%',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.light
    },
    [`@media (max-width: ${config.UI.drawer_breakpoint.max})`]: {
      width: '65%'
    },
    '@media (max-width: 600px)': {
      width: '100%',
      margin: '1rem 0 0 0'
    }
  },
  helpIcon: {
    display: 'inline-block',
    verticalAlign: 'middle',
    margin: '0 0 .2rem .9rem',
    fontSize: '1.1rem',
    color: theme.palette.secondary.main,
    cursor: 'pointer'
  },
  arrow: {
    color: theme.palette.secondary.main
  },
  tooltip: {
    backgroundColor: theme.palette.secondary.light,
    padding: '1rem',
    color: theme.palette.text.primary,
    fontSize: '.925rem',
    fontWeight: 400,
    marginBottom: '1rem'
  }
})

class NicknameForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      fields: {
        nickname: ''
      },
      status: {
        processing: false,
        dialog: false
      },
      error: ''
    }

    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleUpdateNickname = this.handleUpdateNickname.bind(this)
    this.handleDialog = this.handleDialog.bind(this)
  }

  componentDidMount() {
    const { currentUser } = this.props

    this.setState({
      ...this.state,
      fields: {
        ...this.state.fields,
        nickname: currentUser.nickname
      }
    })
  }

  handleInputChange(event) {
    const target = event.target
    const name = target.name

    this.setState({
      ...this.state,
      fields: {
        ...this.state.fields,
        [name]: target.value
      }
    })
  }

  handleUpdateNickname() {
    this.setState({
      ...this.state,
      status: { ...this.state.status, processing: true }
    })

    const { error } = updateNicknameValidation(this.state.fields)

    if (error !== '') {
      console.log('[VALIDATION ERROR]: ', error)

      this.setState({
        ...this.state,
        status: { ...this.state.status, processing: false },
        error
      })
    } else {
      this.props.updateNickname({ nickname: this.state.fields.nickname }).then((data) => {
        if (!data.error) {
          console.log('[SUCCESS]: ', 'Successfully updated nickname!')

          this.setState({
            ...this.state,
            status: { ...this.state.status, processing: false },
            error: ''
          })
        } else {
          console.log('[FAIL]: ', 'Failed to update nickname.')
          this.setState({
            ...this.state,
            status: { ...this.state.status, processing: false },
            error: data.message
          })
        }
      })
    }
  }

  handleDialog(dialog) {
    this.setState({
      ...this.state,
      status: { ...this.state.status, dialog }
    })
  }

  render() {
    const { classes, currentUser, currentProfile } = this.props
    const { fields, status, error } = this.state
    const timeUntilNextUpdate = getTimeUntilNextUpdate(currentUser.lastNicknameChange)

    return (
      <div>
        <ConfirmationDialog
          open={status.dialog}
          primaryAction={
            fields.nickname.trim() !== '' && timeUntilNextUpdate !== 0
              ? undefined
              : this.handleUpdateNickname
          }
          secondaryAction={() => this.handleDialog(false)}
          primaryText={'Confirm'}
          secondaryText={
            fields.nickname.trim() !== '' && timeUntilNextUpdate !== 0 ? 'I Understand' : 'Cancel'
          }
          title={
            fields.nickname.trim() !== '' && timeUntilNextUpdate !== 0
              ? "We're Sorry"
              : 'Are you sure?'
          }
          body={
            <div>
              {fields.nickname.trim() === '' && (
                <>
                  <p>
                    Are you sure you want to clear your nickname? Your name will now be publicly
                    viewable as <b>{`${currentUser.fname} ${currentUser.lname}`}</b>
                  </p>
                  <br />
                  <p>
                    <b>Please Note:</b> You are allowed to update your nickname again on{' '}
                    <b>{getTimeUntilNextUpdate(currentUser.lastNicknameChange, true)}</b>.
                  </p>
                </>
              )}

              {fields.nickname.trim() !== '' && timeUntilNextUpdate !== 0 && (
                <>
                  <p>
                    In order to enforce the security of our community, we only allow 1 nickname
                    change every 6 months.
                  </p>
                  <br />
                  <p>
                    You can change your nickname again on: <b>{timeUntilNextUpdate}</b>
                  </p>
                </>
              )}

              {fields.nickname.trim() !== '' && timeUntilNextUpdate === 0 && (
                <>
                  <p>
                    Are you sure you want to update your nickname? Your name will now be publicly
                    viewable as <b>{`${fields.nickname} ${currentUser.lname}`}</b>
                  </p>
                  <br />
                  <p>
                    <b>Please Note:</b> You are allowed to clear your nickname at any time. However,
                    you will be allowed to update your nickname again in <b>6 months</b>.
                  </p>
                </>
              )}
            </div>
          }
        />

        <FormError message={error} />

        <ItemContainer style={{ marginTop: '0' }}>
          <InputLabel>Public Name:</InputLabel>

          <InputsContainer>
            <InputPreprop>{getName(currentUser)}</InputPreprop>
          </InputsContainer>
        </ItemContainer>

        {currentProfile.documentVerified && (
          <ItemContainer>
            <InputLabel>
              Nickname:
              <Tooltip
                arrow
                classes={{
                  arrow: classes.arrow,
                  tooltip: classes.tooltip
                }}
                title={
                  <p>
                    Nicknames are used in cases where you would like to be referred to by a name
                    other than the one from your identity provider.
                    <br />
                    <br />
                    Your nickname will replace only your first and middle names. Your last name will
                    still be viewable to the public.
                    <br />
                    <br />
                    <b>Please Note: </b> You are only allowed one nickname every 6 months. However,
                    you are allowed to clear your nickname at any time.
                    {timeUntilNextUpdate !== 0 && (
                      <>
                        <br />
                        You can change your nickname again on: <b>{timeUntilNextUpdate}</b>
                      </>
                    )}
                  </p>
                }
                placement="top"
                enterDelay={400}>
                <Help className={classes.helpIcon} />
              </Tooltip>
            </InputLabel>

            <InputsContainer>
              <Input
                type="text"
                name="nickname"
                placeholder="Enter new nickname"
                value={fields.nickname}
                width="15rem"
                onChange={this.handleInputChange}
              />
            </InputsContainer>

            <Button
              variant="contained"
              color="primary"
              className={classes.actionButton}
              onClick={() => this.handleDialog(true)}
              disabled={fields.nickname.trim() === currentUser.nickname || status.processing}>
              Submit
            </Button>
          </ItemContainer>
        )}
      </div>
    )
  }
}

NicknameForm.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
  currentUser: state.currentUser,
  currentProfile: state.currentProfile
})

const mapDispatchToProps = (dispatch) => ({
  updateNickname: (params) => {
    return dispatch(updateNickname(params))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(NicknameForm))
