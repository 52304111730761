import React, { Component } from 'react'
import { connect } from 'react-redux'
import { analytics } from '../../../../firebase/firebase'
import { endDiscussionByID } from '../../../../redux/thunks/discussions/endDiscussionByID'
import { acceptDiscussionByID } from '../../../../redux/thunks/discussions/acceptDiscussionByID'
import { declineDiscussionByID } from '../../../../redux/thunks/discussions/declineDiscussionByID'
import { counterDiscussionByID } from '../../../../redux/thunks/discussions/counterDiscussionByID'
import { createRating } from '../../../../redux/thunks/ratings/createRating'
import getName from '../../../../utils/functions/getName'
import PropTypes from 'prop-types'
import withStyles from '@mui/styles/withStyles'
import { Button } from '@mui/material'
import Rating from '@mui/material/Rating'
import StarBorderIcon from '@mui/icons-material/StarBorder'
import {
  AcceptDeclineContainer,
  ButtonSubText,
  RatingContainer,
  RatingMessage,
  RatingContentContainer,
  RatingColumnContainer,
  RatingCategoryLabel
} from './styled/action-bar-comps'

const didRateDiscussion = (discussion, currentUserId) => {
  const ratings = discussion.ratings

  if (ratings[currentUserId]) {
    return true
  }

  return false
}

const styles = (theme) => ({
  requestDecline: {
    minHeight: '2.5rem',
    height: '2.5rem',
    flexGrow: '1',
    flexBasis: '0',
    marginRight: '1rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.875rem',
      marginRight: '0.5rem',
      height: '3.5rem',
      alignItems: 'flex-start',
      '& span': {
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '0.5rem'
      }
    }
  },
  counterDecline: {
    minHeight: '2.5rem',
    height: '2.5rem',
    flexGrow: '1',
    flexBasis: '0',
    marginRight: '1rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.875rem',
      marginRight: '0.5rem',
      height: '3.5rem',
      '$:label': {
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start'
      }
    }
  },
  requestAccept: {
    minHeight: '2.5rem',
    height: '2.5rem',
    flexGrow: '1',
    flexBasis: '0',
    marginRight: '1rem',
    '& span': {
      color: '#ffffff'
    },
    '&:hover span': {
      color: '#ffffff'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.875rem',
      marginRight: '0.5rem',
      height: '3.5rem',
      '& span': {
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start'
      }
    }
  },
  counterAccept: {
    minHeight: '2.5rem',
    height: '2.5rem',
    flexGrow: '1',
    flexBasis: '0',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.875rem',
      height: '3.5rem',
      '& span': {
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start'
      }
    }
  },
  counter: {
    minHeight: '2.5rem',
    height: '2.5rem',
    flexGrow: '1',
    flexBasis: '0',
    borderColor: theme.palette.text.primary,
    color: theme.palette.text.primary,
    '&:hover': {
      backgroundColor: theme.palette.cancelButton.light,
      color: theme.palette.text.primary,
      borderColor: theme.palette.text.primary
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.875rem',
      height: '3.5rem',
      '& span': {
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start'
      }
    }
  },
  // end: {
  //   display: 'inline-block',
  //   width: '15rem',
  //   margin: '1.5rem 2rem 0 0',
  //   [theme.breakpoints.down('xs')]: {
  //     fontSize: '0.875rem',
  //     height: '3.5rem',
  //     '& span': {
  //       flexDirection: 'column',
  //       alignItems: 'center',
  //       justifyContent: 'flex-start'
  //     }
  //   }
  // },
  // send: {
  //   display: 'inline-block',
  //   width: '15rem',
  //   marginTop: '1.5rem',
  //   '&:disabled': {
  //     backgroundColor: 'rgba(250, 250, 250, .7)',
  //     color: '#555'
  //   },
  //   [theme.breakpoints.down('xs')]: {
  //     fontSize: '0.875rem',
  //     height: '3.5rem',
  //     '& span': {
  //       flexDirection: 'column',
  //       alignItems: 'center',
  //       justifyContent: 'flex-start'
  //     }
  //   }
  // },
  rating: {
    display: 'block',
    width: '20rem',
    margin: '1.5rem auto 0 auto',
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.light
    },
    '&:disabled': {
      backgroundColor: '#ffffff',
      color: '#777'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.875rem',
      height: '3.5rem',
      width: '11rem'
    }
  },
  stars: {
    color: theme.palette.text.primary
  }
})

class ActionBar extends Component {
  constructor(props) {
    super(props)

    this.state = {
      fields: {
        rating: 0.0
      },
      error: ''
    }

    this.onStarClick = this.onStarClick.bind(this)
    this.handleEnd = this.handleEnd.bind(this)
    this.handleDecline = this.handleDecline.bind(this)
    this.handleAccept = this.handleAccept.bind(this)
    this.handleCounter = this.handleCounter.bind(this)
    this.handleRating = this.handleRating.bind(this)
  }

  onStarClick(event, newValue) {
    this.setState({
      ...this.state,
      fields: {
        ...this.state.fields,
        rating: newValue
      }
    })
  }

  handleEnd() {
    const { currentUser, discussion } = this.props

    this.props
      .endDiscussionByID({
        discussionId: discussion._id,
        endedBy: currentUser.id
      })
      .then((data) => {
        if (!data.error) {
          console.log('[SUCCESS]: ', 'Successfully ended discussion!')
        } else {
          console.log('[FAIL]: ', 'Failed to end discussion.')
        }
      })

    analytics.logEvent('end_discussion')
  }

  handleDecline(event) {
    const { discussion } = this.props

    this.props.declineDiscussionByID({ discussionId: discussion._id }).then((data) => {
      if (!data.error) {
        console.log('[SUCCESS]: ', 'Successfully declined discussion!')
      } else {
        console.log('[FAIL]: ', 'Failed to decline discussion.')
      }
    })
  }

  handleAccept(event) {
    const { currentProfile, discussion } = this.props

    this.props
      .acceptDiscussionByID({
        discussionId: discussion._id,
        currentProfile
      })
      .then((data) => {
        if (!data.error) {
          console.log('[SUCCESS]: ', 'Successfully accepted discussion!')
        } else {
          console.log('[FAIL]: ', 'Failed to accept discussion.')
        }
      })
  }

  handleCounter(event) {
    const { discussion } = this.props

    this.props
      .counterDiscussionByID({
        discussionId: discussion._id
      })
      .then((data) => {
        if (!data.error) {
          console.log('[SUCCESS]: ', 'Successfully countered discussion!')
        } else {
          console.log('[FAIL]: ', 'Failed to counter discussion.')
        }
      })
  }

  handleRating() {
    const { discussion, currentUser } = this.props
    const fromUser = currentUser.id
    const forUser =
      discussion.guestUserId === currentUser.id ? discussion.hostUserId : discussion.guestUserId

    this.props
      .createRating({
        discussionId: discussion._id,
        for: forUser,
        from: fromUser,
        rating: parseFloat(this.state.fields.rating),
        isPrivate: discussion.private
      })
      .then((data) => {
        if (!data.error) {
          console.log('[SUCCESS]: ', 'Successfully created rating!')

          this.setState({
            ...this.state,
            fields: {
              ...this.state.fields,
              rating: 0.0
            }
          })
        } else {
          console.log('[FAIL]: ', 'Failed to create rating.')
        }
      })

    analytics.logEvent('submit_rating')
  }

  render() {
    const { classes, currentUser, discussion, loadedProfiles, theme } = this.props
    const { fields } = this.state
    const { hostUserId, guestUserId, isRequest, isCounter, complete } = discussion

    // Determine display logic from selected Discussion
    const isPartOfDiscussion = hostUserId === currentUser.id || guestUserId === currentUser.id
    const isHosted = hostUserId === currentUser.id
    const request = isRequest && isHosted
    const counter = isCounter && !isHosted
    const privacyText = discussion.private ? 'private' : 'public'
    const oppositePrivacyText = !discussion.private ? 'private' : 'public'
    const participantProfile = isHosted ? loadedProfiles[guestUserId] : loadedProfiles[hostUserId]

    if (!isPartOfDiscussion) return null

    return (
      <div>
        {request && (
          <AcceptDeclineContainer>
            <Button
              variant="contained"
              color="secondary"
              className={classes.requestDecline}
              onClick={this.handleDecline}>
              Decline
            </Button>

            <Button
              variant="contained"
              color="primary"
              className={classes.requestAccept}
              onClick={this.handleAccept}>
              {`Accept (${privacyText})`}
            </Button>

            <Button
              variant="outlined"
              color="primary"
              className={classes.counter}
              onClick={this.handleCounter}>
              {`Counter (${oppositePrivacyText})`}
            </Button>
          </AcceptDeclineContainer>
        )}

        {counter && (
          <AcceptDeclineContainer>
            <Button
              variant="contained"
              color="secondary"
              className={classes.counterDecline}
              onClick={this.handleDecline}>
              Decline
            </Button>

            <Button
              variant="contained"
              color="primary"
              className={classes.counterAccept}
              onClick={this.handleAccept}>
              {`Accept (${privacyText})`}
            </Button>
          </AcceptDeclineContainer>
        )}

        {!didRateDiscussion(discussion, currentUser.id) && complete && (
          <RatingContainer>
            <RatingMessage theme={theme}>
              {'In order to improve our community, please take a moment to rate ' +
                getName(participantProfile, 'first')}
            </RatingMessage>

            <RatingContentContainer>
              <RatingColumnContainer>
                <RatingCategoryLabel theme={theme}>EQ:</RatingCategoryLabel>
              </RatingColumnContainer>

              <RatingColumnContainer>
                <Rating
                  name="rating"
                  value={fields.rating}
                  precision={0.5}
                  size="large"
                  classes={{
                    iconEmpty: classes.stars
                  }}
                  style={{
                    textAlign: 'left'
                  }}
                  emptyIcon={<StarBorderIcon fontSize="inherit" />}
                  onChange={this.onStarClick}
                />
              </RatingColumnContainer>

              <Button
                variant="contained"
                color="primary"
                className={classes.rating}
                onClick={this.handleRating}
                disabled={fields.rating === 0}>
                Submit Rating
              </Button>
            </RatingContentContainer>
          </RatingContainer>
        )}
      </div>
    )
  }
}

ActionBar.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
  currentProfile: state.currentProfile,
  currentUser: state.currentUser,
  loadedProfiles: state.loadedProfiles
})

const mapDispatchToProps = (dispatch) => ({
  acceptDiscussionByID: (params) => {
    return dispatch(acceptDiscussionByID(params))
  },
  endDiscussionByID: (params) => {
    return dispatch(endDiscussionByID(params))
  },
  declineDiscussionByID: (params) => {
    return dispatch(declineDiscussionByID(params))
  },
  counterDiscussionByID: (params) => {
    return dispatch(counterDiscussionByID(params))
  },
  createRating: (params) => {
    return dispatch(createRating(params))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ActionBar))
