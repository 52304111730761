import React, { useRef, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import isVerifiedUser from '../../../../../utils/functions/isVerifiedUser'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import { Button } from '@mui/material'
import {
  MainContainer,
  InnerContainer,
  // BlueCircle,
  ContentContainer,
  Header,
  Subheader,
  ButtonContainer
} from './styled/first-post-prompt-comps'
import { getResponsiveStyles } from './responsive-styles/first-post-prompt'

const useStyles = makeStyles((theme) =>
  createStyles({
    buttonLight: {
      display: 'inline-block',
      color: theme.palette.text.primary,
      margin: '1.5rem .5rem 0 .5rem',
      fontSize: '1rem',
      width: '15rem',
      verticalAlign: 'bottom',
      textTransform: 'capitalize',
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.light
      }
    }
  })
)

function FirstPostPrompt() {
  const classes = useStyles()
  const navigate = useNavigate()
  const mainRef = useRef(null)
  const [didRecognizeRef, setDidRecognizeRef] = useState(false)
  const [componentWidth, setComponentWidth] = useState(0)
  const [responsiveStyles, setResponsiveStyles] = useState(getResponsiveStyles(componentWidth))
  const currentProfile = useSelector(({ currentProfile }) => currentProfile)

  const handleSetComponentWidth = () => {
    const width = mainRef.current ? mainRef.current.clientWidth : window.innerWidth

    setComponentWidth(width)
  }

  useEffect(() => {
    window.addEventListener('resize', handleSetComponentWidth)

    return () => {
      window.removeEventListener('resize', handleSetComponentWidth)
    }
  }, [])

  useEffect(() => {
    if (!didRecognizeRef) {
      if (mainRef.current !== null && typeof mainRef.current !== 'undefined') {
        setComponentWidth(mainRef.current.clientWidth)
        setDidRecognizeRef(true)
      }
    }
  }, [mainRef, didRecognizeRef])

  useEffect(() => {
    setResponsiveStyles(getResponsiveStyles(componentWidth))
  }, [componentWidth])

  const goToURL = (url) => {
    navigate(url)
  }

  if (!isVerifiedUser(currentProfile)) {
    return null
  }

  return (
    <MainContainer ref={mainRef}>
      <InnerContainer>
        <ContentContainer margin={responsiveStyles.contentContainer.margin}>
          <Header textAlign={responsiveStyles.header.textAlign}>
            Ready to share your first post?
          </Header>

          <Subheader textAlign={responsiveStyles.subheader.textAlign}>
            To get started, share some thoughts with the community. Or, explore the community's
            latest activity and get inspired by what others are sharing.
          </Subheader>

          <ButtonContainer textAlign={responsiveStyles.buttonContainer.textAlign}>
            <Button
              variant="contained"
              className={classes.buttonLight}
              onClick={() => goToURL('/home')}
              style={componentWidth < 550 ? {} : { marginLeft: '0' }}>
              Start a Conversation
            </Button>
          </ButtonContainer>
        </ContentContainer>
      </InnerContainer>
    </MainContainer>
  )
}

export default FirstPostPrompt
