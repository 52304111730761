import React, { Component } from 'react'
import { connect } from 'react-redux'
import loadSearchResults from './functions/load-searchResults'
import { searchResultsActions } from '../../../redux/action-creators/search-results'
import { getFollowsByFollowingIDs } from '../../../redux/thunks/follows/getFollowsByFollowingIDs'
import { search } from '../../../redux/thunks/search/search'
import Center from './components/center'
import { OuterContainer } from './components/styled/searchResults-comps'

class InnerSearchResults extends Component {
  constructor(props) {
    super(props)

    this.state = {
      status: {
        tab: '',
        pageLoaded: false
      }
    }

    this.handleSetState = this.handleSetState.bind(this)
  }

  componentDidMount() {
    const searchResultsActions = {
      search: this.props.search,
      getFollowsByFollowingIDs: this.props.getFollowsByFollowingIDs
    }

    loadSearchResults(
      this.props.params.query,
      this.state,
      this.handleSetState,
      searchResultsActions
    )
  }

  handleSetState(object) {
    this.setState(object)
  }

  render() {
    return (
      <OuterContainer>
        <Center state={this.state} setState={this.handleSetState} />
      </OuterContainer>
    )
  }
}

// ******************** //
// Wrapping Class component in a function component to get access to useParams
// ******************** //
import { useParams } from 'react-router-dom'

function SearchResults(props) {
  const params = useParams()

  return <InnerSearchResults {...props} params={params} />
}

const mapStateToProps = (state) => ({
  searchResults: state.searchResults
})

const mapDispatchToProps = (dispatch) => ({
  search: (params) => {
    return dispatch(search(params))
  },
  getFollowsByFollowingIDs: (params) => {
    return dispatch(getFollowsByFollowingIDs(params))
  },
  clearSearchResults: () => {
    return dispatch(searchResultsActions.clearSearchResults())
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(SearchResults)
