import React from 'react'
import { useNavigate } from 'react-router-dom'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import { Button } from '@mui/material'
import { SectionHeader, DetailContainer, DetailLabel, Detail } from './styled/unsubscribed-comps'

const useStyles = makeStyles((theme) =>
  createStyles({
    button: {
      display: 'inline-block',
      width: '15rem',
      marginTop: '1.5rem',
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.light
      },
      '@media (max-width: 450px)': {
        width: '100%'
      }
    }
  })
)

const Unsubscribed = ({ style }) => {
  const classes = useStyles()
  const navigate = useNavigate()

  return (
    <div style={style || {}}>
      <SectionHeader>Subscription Details</SectionHeader>

      <DetailContainer style={{ marginTop: '2rem' }}>
        <DetailLabel>Subscription Status:</DetailLabel>

        <Detail>
          <span className="active-flag" style={{ backgroundColor: '#cc0000' }}>
            Not Active
          </span>
        </Detail>
      </DetailContainer>

      <Button
        variant="contained"
        color="primary"
        onClick={() => navigate('/subscribe')}
        className={classes.button}>
        Subscribe
      </Button>
    </div>
  )
}

export default Unsubscribed
