import React, { Component } from 'react'
import { connect } from 'react-redux'
import config from '../../../config'
import _ from 'lodash'
import moment from 'moment'
import logEventAsync from '../../../utils/functions/logEventAsync'
import { tokensTransactionModalActions } from '../../../redux/action-creators/tokens-transaction-modal'
import { createVote } from '../../../redux/thunks/votes/createVote'
import { createView } from '../../../redux/thunks/views/createView'
import { removeVoteByID } from '../../../redux/thunks/votes/removeVoteByID'
import { getNumDiscussionsByTakeId } from '../../../redux/thunks/discussions/getNumDiscussionsByTakeId'
import getName from '../../../utils/functions/getName'
import { getOrganizationImageWithFallback } from '../../../utils/functions/getOrganizationImage'
import isVerifiedUser from '../../../utils/functions/isVerifiedUser'
// *** Components *** //
import PostHeader from './components/post-header/post-header'
import UserAvatar from '../user-avatar/user-avatar'
import DropdownMenu from './components/dropdown-menu/dropdown-menu'
import EditForm from './components/edit-form/edit-form'
import CollapsedContent from '../../partials/collapsed-content/collapsed-content'
import Prompt from '../../main/course/components/prompts/components/prompt'
import { colorForEmotion } from '../emotion-picker/emotion-picker'
import Tags from '../../partials/tags/tags'
import MediaGallery from '../../partials/media-gallery/media-gallery'
import TakeMetrics from './components/take-metrics/take-metrics'
import DiscussionSlider from './components/discussion-slider/discussion-slider'
import CreateDiscussionForm from './components/create-discussion-form/create-discussion-form'
import FormError from '../../alerts/form-error'
import TokenIconGreen from '../../../media/icons/svg/token-green.svg'
import TokenIconGray from '../../../media/icons/svg/token-gray.svg'
import PropTypes from 'prop-types'
import withStyles from '@mui/styles/withStyles'
import Button from '@mui/material/Button'
import LinearProgress from '@mui/material/LinearProgress'
import DiscussIcon from '@mui/icons-material/Chat'
import VoteIcon from '@mui/icons-material/ThumbUpAlt'
import InfoIcon from '@mui/icons-material/Info'
import PublicIcon from '@mui/icons-material/Public'
import PublicOffIcon from '@mui/icons-material/PublicOff'
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer'
import {
  MainContainer,
  PaddedContainer,
  InfoBarContainer,
  TakeHeaderContainer,
  AvatarContainer,
  TakeHeaderInnerContainer,
  Name,
  DateString,
  PromptTitleContainer,
  PromptContentContainer,
  UserTake,
  TakeImageContainer,
  TakeImage,
  HR,
  ActionsContainer,
  ActionButtonText,
  DiscussionLimitMessageContainer,
  DiscussionLimitMessage
} from './styled/post-comps' // Separator
import { EmotionCardsContainer, EmotionMessage, EmotionCard } from './styled/emotion-comps'
import DiscussButton from './components/discuss'
import { authenticatedFetch } from '../../../utils/functions/authenticatedFetch'

const canRequestDiscussion = (involvedDiscussions) => {
  return (
    _.filter(involvedDiscussions, (discussion) => {
      if (!discussion.complete && discussion.accepted) {
        return true
      } else if (discussion.isCounter) {
        return true
      } else if (discussion.isRequest) {
        return true
      } else if (discussion.rejected) {
        return true
      } else {
        return false
      }
    }).length === 0
  )
}

const styles = (theme) => ({
  actionButton: {
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.grey[50]}`,
    flexGrow: '1',
    flexBasis: '0',
    minHeight: '2.2rem',
    height: '2.2rem',
    margin: '0 .1rem',
    color: theme.palette.text.primary,
    '& svg': {
      // marginTop: '.2rem'
    },
    '& img': {
      height: '1.2rem',
      marginTop: '.1rem',
      marginRight: '.5rem'
    },
    '&:hover': {
      backgroundColor: 'rgba(171,202,179,.2)'
    }
  },
  actionButtonActive: {
    fontSize: '1rem',
    fontWeight: 700,
    lineHeight: 0,
    backgroundColor: 'rgba(171,202,179,.5)',
    flexGrow: '1',
    flexBasis: '0',
    minHeight: '2.2rem',
    height: '2.2rem',
    margin: '0 .1rem',
    color: theme.palette.text.primary,
    '& svg': {
      marginTop: '.2rem'
    },
    '&:hover': {
      backgroundColor: 'rgba(171,202,179,.2)'
    }
  },
  actionIcon: {
    color: theme.palette.secondary.main,
    marginTop: '.1rem',
    marginRight: '.4rem',
    fontSize: '1.2rem'
  },
  discussionLimitIcon: {
    color: theme.palette.text.primary,
    margin: '0.1rem 0 0 0.5rem',
    fontSize: '1.3rem'
  }
})

class Post extends Component {
  constructor(props) {
    super(props)

    this.state = {
      orgPostInfo: {},
      status: {
        isCreatingDiscussion: false,
        isReadingMore: false,
        isEditingTake: false,
        loadingDiscussionForm: false,
        showDiscussionLimitMessage: false
      },
      defaultDiscussionId: '',
      error: ''
    }

    // Functions
    this.handleSetState = this.handleSetState.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSetEditing = this.handleSetEditing.bind(this)
    this.toggleCreateDiscussionForm = this.toggleCreateDiscussionForm.bind(this)
    this.handleTokenTransaction = this.handleTokenTransaction.bind(this)
    this.handleVote = this.handleVote.bind(this)
  }

  componentDidMount() {
    if (this.props.discussionId) {
      this.setState({
        ...this.state,
        defaultDiscussionId: this.props.discussionId
      })
    }
    const fetchData = async () => {
      const isOrgTake = this.props.take?.organizationId !== ''

      if (isOrgTake && this.props.take?.organizationId) {
        const { organizationId } = this.props.take
        const baseUrlString = config.baseURL ? config.baseURL : ''

        try {
          const response = await authenticatedFetch(
            `${baseUrlString}/api/organization/${JSON.stringify([organizationId])}`,
            {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json'
              },
              credentials: 'include'
            }
          )

          if (!response.ok) {
            throw new Error('Failed to fetch organization data')
          }

          const { organizations } = await response.json()
          const organization = organizations?.find((n) => n._id === this.props.take?.organizationId)

          if (organization) {
            const newOrg = {
              ...organization,
              ...this.props.take,
              profileImage: organization.image
            }
            this.setState({
              ...this.state,
              orgPostInfo: { ...newOrg }
            })
          }
        } catch (error) {
          console.error('Error fetching organization data:', error)
        }
      }
    }
    fetchData()
  }

  handleSetState(object) {
    this.setState(object)
  }

  handleInputChange(event) {
    const target = event.target
    const name = target.name

    if (name === 'private') {
      this.setState({
        ...this.state,
        fields: {
          ...this.state.fields,
          [name]: !this.state.fields.private
        }
      })
    } else {
      this.setState({
        ...this.state,
        fields: {
          ...this.state.fields,
          [name]: target.value
        }
      })
    }
  }

  handleSetEditing(isEditingTake) {
    this.setState({
      ...this.state,
      status: {
        ...this.state.status,
        isEditingTake
      }
    })
  }

  toggleCreateDiscussionForm(isCreatingDiscussion) {
    const viewParams = { takeId: this.props.take._id, userId: this.props.currentUser.id }

    this.props
      .createView(viewParams)
      .then((data) => {
        console.log('createView return in toggleCreateDiscussionForm')

        this.setState({
          ...this.state,
          status: {
            ...this.state.status,
            isCreatingDiscussion,
            loadingDiscussionForm: true
          }
        })

        console.log('[Debug]: calling getNumDiscussionsByTakeId')
        return this.props.getNumDiscussionsByTakeId({ takeId: this.props.take._id })
      })
      .then((data) => {
        console.log('results of getNumDiscussionsByTakeId ', data)

        if (data.error) {
          console.log('API error')
          this.setState({
            ...this.state,
            status: {
              ...this.state.status,
              isCreatingDiscussion: false,
              loadingDiscussionForm: false
            },
            error: data.message
          })
        }

        if (data.numDiscussions < this.props.take.discussionLimit) {
          console.log('show discussion form')
          this.setState({
            ...this.state,
            status: {
              ...this.state.status,
              loadingDiscussionForm: false
            }
          })
        } else {
          console.log('show discussion limit message')
          this.setState({
            ...this.state,
            status: {
              ...this.state.status,
              isCreatingDiscussion: false,
              loadingDiscussionForm: false,
              showDiscussionLimitMessage: true
            }
          })
        }
      })
      .catch((err) => console.log(err))
  }

  handleTokenTransaction() {
    const { take } = this.props
    const viewParams = { takeId: take._id, userId: this.props.currentUser.id }

    this.props
      .createView(viewParams)
      .then((data) => {
        this.props.setTokensTransactionModal(true, {
          initialState: take.organizationId ? 'donate' : 'transfer',
          userId: take.userId,
          organizationId: take.organizationId,
          takeId: take._id
        })
      })
      .catch((err) => console.log(err))
  }

  handleVote() {
    const { currentUser, take } = this.props
    // const viewParams = { takeId: take._id, userId: currentUser.id }
    const voteIndex = _.findIndex(take.votes, {
      userId: currentUser.id
    })

    if (voteIndex === -1) {
      this.props.createVote({ takeId: take._id }).then((data) => {
        if (!data.error) {
          console.log('[SUCCESS]: ', 'Successfully created vote!')
        } else {
          console.log('[FAIL]: ', 'Failed to create vote.')
        }
      })
    } else {
      // LOGIC TO REMOVE A VOTE ALREADY CREATED
      let voteId = take.votes[voteIndex]._id

      this.props.removeVoteByID({ voteId: voteId }).then((data) => {
        if (!data.error) {
          console.log('[SUCCESS]: ', 'Successfully removed vote!')
        } else {
          console.log('[FAIL]: ', 'Failed to remove vote.')
        }
      })
    }

    // this.props.createView(viewParams).then((data) => {
    //   if (voteIndex === -1) {
    //     this.props.createVote({ takeId: take._id }).then((data) => {
    //       if (!data.error) {
    //         console.log('[SUCCESS]: ', 'Successfully created vote!')
    //       } else {
    //         console.log('[FAIL]: ', 'Failed to create vote.')
    //       }
    //     })
    //   } else {
    //     // LOGIC TO REMOVE A VOTE ALREADY CREATED
    //     let voteId = take.votes[voteIndex]._id

    //     this.props.removeVoteByID({ voteId: voteId }).then((data) => {
    //       if (!data.error) {
    //         console.log('[SUCCESS]: ', 'Successfully removed vote!')
    //       } else {
    //         console.log('[FAIL]: ', 'Failed to remove vote.')
    //       }
    //     })
    //   }
    // }).catch(err => console.log(err))
  }

  render() {
    const { classes, currentProfile, currentUser, loadedDiscussions, loadedProfiles, take } =
      this.props
    const { status, defaultDiscussionId, orgPostInfo, error } = this.state
    const isHostedByUser = take.userId === currentUser.id
    const hostProfile = isHostedByUser ? currentProfile : loadedProfiles[take?.userId]
    const organization = orgPostInfo

    if (typeof hostProfile === 'undefined') {
      return null
    }

    const { documentVerified, avatar } = hostProfile

    const verified = documentVerified ? 1 : 0
    const takeProfileImage = take?.organizationId
      ? getOrganizationImageWithFallback(organization?.profileImage)
      : avatar
    const takeUserName = take?.organizationId ? organization?.publicName : getName(hostProfile)
    // Check if current user has voted on the take

    const didVote = _.some(take.votes, { userId: currentUser.id })
    const discussions = _.filter(loadedDiscussions, (discussion) => {
      return discussion.takeId === take._id
    })
    const involvedDiscussions = _.filter(discussions, (discussion) => {
      return discussion.guestUserId === currentUser.id && discussion.takeId === take._id
    })

    /** TAKE BEGINS HERE */
    return (
      <MainContainer>
        {/** *** SHOW QUESTION PROMPT *** */}
        {take.isQuestion && (
          <InfoBarContainer>
            <span>Can you help answer this question?</span>
          </InfoBarContainer>
        )}

        {/** *** SHOW PROMPT *** */}
        {take.prompt && (
          <InfoBarContainer>
            <QuestionAnswerIcon
              sx={{
                color: '#fff',
                marginRight: '0.5rem',
                '& .MuiSvgIcon-root': {
                  fill: '#fff'
                }
              }}
            />
            <span>{takeUserName} responded to a prompt</span>
          </InfoBarContainer>
        )}

        <PaddedContainer>
          <DropdownMenu
            take={take}
            hasDiscussions={discussions.length > 0}
            handleSetEditing={this.handleSetEditing}
            viewsParams={!isHostedByUser && { takeId: take._id, userId: currentUser.id }}
          />

          <PostHeader
            take={take}
            hostProfile={hostProfile}
            takeProfileImage={takeProfileImage}
            verified={verified}
            takeUserName={takeUserName}
            colorForEmotion={colorForEmotion}
          />

          {/** *** SHOW EDIT POST FORM *** */}
          {status.isEditingTake && (
            <EditForm take={take} handleSetEditing={this.handleSetEditing} />
          )}

          {/** *** SHOW POST CONTENT *** */}
          {!status.isEditingTake && (
            <section>
              {take.prompt && (
                <div
                  style={{
                    background: '#abcab3',
                    borderRadius: '0.25rem',
                    padding: '0.25rem',
                    margin: '0.5rem 0'
                  }}>
                  <Prompt
                    prompt={take.prompt}
                    responseFormView={true}
                    contentRows={2}
                    postDisplay={true}
                  />
                </div>
              )}

              <UserTake>
                <CollapsedContent
                  color={'#333'}
                  fontWeight={500}
                  fontSize={'1rem'}
                  lineHeight={'1.5rem'}
                  linkify={true}
                  content={take.take}
                  viewsParams={!isHostedByUser && { takeId: take._id, userId: currentUser.id }}
                />
              </UserTake>

              {take?.image && (
                <TakeImageContainer>
                  <TakeImage alt="take" src={take?.image} />
                </TakeImageContainer>
              )}

              {/** *** SHOW TAGS *** */}
              {/*
               */}
              <div style={{ marginBottom: '0.25rem' }}>
                <Tags tagIds={take.tagIds} tagStyle={{ marginTop: '0', marginBottom: '.8rem' }} />
              </div>
            </section>
          )}

          {/** *** SHOW MEDIA GALLERY *** */}
          <MediaGallery media={take.media} />

          {/** *** POST METRICS *** */}
          <TakeMetrics
            organizationId={take.organizationId}
            numDiscussions={discussions.length}
            numVotes={take.numVotes || 0}
            totalDonationsCount={take.totalDonationsCount || 0}
            totalTransferedCount={take.totalTransferedCount || 0}
            viewCount={take.viewCount}
            courseId={take.courseId}
            course={take?.course || null}
          />

          {/** *** DISCUSSIONS *** */}
          <DiscussionSlider take={take} defaultDiscussionId={defaultDiscussionId} />

          {/** *** SHOW CREATE DISCUSSION FORM  *** */}
          {status.isCreatingDiscussion && (
            <>
              {status.loadingDiscussionForm ? (
                <div>
                  <LinearProgress />
                </div>
              ) : (
                <CreateDiscussionForm
                  take={take}
                  toggleCreateDiscussionForm={this.toggleCreateDiscussionForm}
                  type={'coursePrompt'}
                  courseId={take.courseId}
                />
              )}
            </>
          )}

          <FormError message={error} style={{ margin: '1rem 0' }} />

          {status.showDiscussionLimitMessage && (
            <DiscussionLimitMessageContainer>
              <InfoIcon className={classes.discussionLimitIcon} />
              <DiscussionLimitMessage>
                This take is no longer accepting discussion requests
              </DiscussionLimitMessage>
            </DiscussionLimitMessageContainer>
          )}

          {/** *** ACTION BUTTONS *** */}
          {(!isHostedByUser || take.organizationId) && (
            <>
              <HR />

              <ActionsContainer>
                {canRequestDiscussion(involvedDiscussions) && (
                  <DiscussButton
                    classes={classes}
                    currentProfile={currentProfile}
                    status={status}
                    isVerifiedUser={isVerifiedUser}
                    toggleCreateDiscussionForm={this.toggleCreateDiscussionForm}
                  />
                )}

                <Button
                  // disableElevation
                  variant="contained"
                  className={didVote ? classes.actionButtonActive : classes.actionButton}
                  onClick={this.handleVote}>
                  <VoteIcon className={classes.actionIcon} role="img" />
                  <ActionButtonText>Insightful</ActionButtonText>
                </Button>
              </ActionsContainer>
            </>
          )}
        </PaddedContainer>
      </MainContainer>
    )
  }
}

Post.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
  currentProfile: state.currentProfile,
  currentUser: state.currentUser,
  loadedDiscussions: state.loadedDiscussions,
  loadedProfiles: state.loadedProfiles,
  loadedTopics: state.loadedTopics,
  organizations: state.organizations
})

const mapDispatchToProps = (dispatch) => ({
  setTokensTransactionModal: (open, initialState) => {
    return dispatch(tokensTransactionModalActions.set(open, initialState))
  },
  createVote: (params) => {
    return dispatch(createVote(params))
  },
  createView: (params) => {
    return dispatch(createView(params))
  },
  removeVoteByID: (params) => {
    return dispatch(removeVoteByID(params))
  },
  getNumDiscussionsByTakeId: (params) => {
    return dispatch(getNumDiscussionsByTakeId(params))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Post))
