import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { refreshVerificationAttempt } from '../../../../redux/thunks/verification-attempts/refreshVerificationAttempt'
import copyVerificationAttempt from '../functions/copy-verification-attempt'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import { CircularProgress } from '@mui/material'
import { Subheader } from '../styled/verify-comps'
import theme from '../../../../res/theme'

const useStyles = makeStyles((them) =>
  createStyles({
    circularProgress: {
      display: 'block',
      margin: '2rem auto 0 auto',
      '& svg': {
        color: theme.palette.primary.main
      }
    }
  })
)

function Processing(props) {
  const {
    setStatus,
    setVerificationAttempt,
    verificationAttemptId,
    setVerificationAttemptId,
    setLastError
  } = props
  const [processing, setProcessing] = useState(false)
  const classes = useStyles()
  const dispatch = useDispatch()

  const handleRefresh = () => {
    console.log('Canceling Attempt')

    setProcessing(true)

    dispatch(refreshVerificationAttempt({ verificationAttemptId })).then((data) => {
      if (data.error) {
        console.log('[FAIL]', 'Failed to refresh verification attempt.', data.error)
      } else {
        console.log('[SUCCESS]', 'Verification attempt refreshed!')

        setVerificationAttemptId(data.verificationAttempt._id)
        setVerificationAttempt({ ...copyVerificationAttempt(data.verificationAttempt) })
        setStatus(data.verificationAttempt.status)
        setLastError(data.verificationAttempt.lastError)
      }

      setProcessing(false)
    })
  }

  return (
    <>
      <Subheader>
        Please wait while we process your identification submission. This should take around 1 to 5
        minutes.
      </Subheader>

      <CircularProgress color="primary" className={classes.circularProgress} />

      <Subheader>
        Taking too long?{' '}
        {processing ? (
          <span
            style={{
              margin: '.5rem 0 0 .2rem'
            }}>
            Refresh
          </span>
        ) : (
          <u
            style={{
              color: theme.palette.link.primary,
              cursor: 'pointer',
              margin: '.5rem 0 0 .2rem'
            }}
            onClick={handleRefresh}>
            Refresh
          </u>
        )}
      </Subheader>
    </>
  )
}

export default Processing
