import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

// import { analytics } from "../../../firebase/firebase"
import isVerifiedUser from '../../../utils/functions/isVerifiedUser'
import Start from './components/start/start'
import Verify from '../../partials/verify/verify'
import Finish from './components/finish/finish'
import PropTypes from 'prop-types'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
// import withStyles from '@mui/styles/withStyles'
import clsx from 'clsx'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import Check from '@mui/icons-material/Check'
import StepConnector from '@mui/material/StepConnector'
// import { styled } from '@mui/system'
import { MainContainer, SubscribeContainer } from './styled/verification-steps-comps'

// const QontoConnector = styled(StepConnector)(({ theme }) => ({
//   alternativeLabel: {
//     top: 10,
//     left: 'calc(-50% + 16px)',
//     right: 'calc(50% + 16px)'
//   },
//   active: {
//     '& $line': {
//       borderColor: '#2eb2fa'
//     }
//   },
//   completed: {
//     '& $line': {
//       borderColor: '#2eb2fa'
//     }
//   },
//   line: {
//     borderColor: '#eaeaf0',
//     borderTopWidth: 2,
//     borderRadius: 1
//   }
// }))

// const QontoConnector = withStyles({
//   alternativeLabel: {
//     top: 10,
//     left: 'calc(-50% + 16px)',
//     right: 'calc(50% + 16px)'
//   },
//   active: {
//     '& $line': {
//       borderColor: '#2eb2fa'
//     }
//   },
//   completed: {
//     '& $line': {
//       borderColor: '#2eb2fa'
//     }
//   },
//   line: {
//     borderColor: '#eaeaf0',
//     borderTopWidth: 2,
//     borderRadius: 1
//   }
// })(StepConnector)

const useQontoStepIconStyles = makeStyles((theme) => ({
  root: {
    color: '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center'
  },
  active: {
    color: theme.palette.primary.main
  },
  circle: {
    width: 12,
    height: 12,
    borderRadius: '50%',
    backgroundColor: 'currentColor'
  },
  completed: {
    color: theme.palette.secondary.main,
    zIndex: 1,
    fontSize: 24
  }
}))

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles()
  const { active, completed } = props

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active
      })}>
      {completed ? <Check className={classes.completed} /> : <div className={classes.circle} />}
    </div>
  )
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool
}

const useStyles = makeStyles(() =>
  createStyles({
    stepper: {
      background: 'none',
      color: '#eee'
    }
  })
)

function VerificationSteps() {
  const classes = useStyles()
  const [step, setStep] = useState(0)
  const currentProfile = useSelector(({ currentProfile }) => currentProfile)

  useEffect(() => {
    if (isVerifiedUser(currentProfile)) {
      setStep(2)
    }
  }, [currentProfile, step])

  const getSteps = () => {
    return ['Start', 'Verify ID', 'Finish']
  }

  return (
    <MainContainer>
      <SubscribeContainer>
        <Stepper
          alternativeLabel
          activeStep={step}
          connector={<StepConnector />}
          className={classes.stepper}>
          {getSteps().map((label) => (
            <Step key={label}>
              <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        {step === 0 && <Start setStep={setStep} />}
        {step === 1 && <Verify setStep={setStep} />}
        {step === 2 && <Finish setStep={setStep} />}
      </SubscribeContainer>
    </MainContainer>
  )
}

export default VerificationSteps
