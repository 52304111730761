import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { debounce } from '@mui/material/utils'
import { searchTags } from '../../../redux/thunks/search/searchTags'
import normalizeTagSearch from './functions/normalize-tag-search'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import { ClickAwayListener, Grow, Paper, Popper, MenuList, MenuItem } from '@mui/material'
import { MainContainer, Input, Result } from './styled/tags-search-comps'

const useStyles = makeStyles(() =>
  createStyles({
    popper: {
      marginTop: '.1rem'
    },
    menuList: {
      padding: '.2rem 0',
      maxWidth: '25rem'
    },
    menuItem: {
      padding: '0 ',
      height: 'auto',
      minHeight: 'auto',
      whiteSpace: 'normal'
    }
  })
)

const TagsSearch = (props) => {
  const { tagIds, setTagIds, setError, disableNewTags, style, variant } = props
  const inputRef = useRef(null)
  const [didRecognizeRef, setDidRecognizeRef] = useState(false)
  const [tagSearch, setTagSearch] = useState('')
  const [tagResultsOpen, setTagResultsOpen] = useState(false)
  const tagResults = useSelector(({ tagResults }) => tagResults)
  const classes = useStyles()
  const dispatch = useDispatch()
  const [focusedIndex, setFocusedIndex] = useState(-1)
  const menuListRef = useRef(null)

  const handleSearch = useMemo(
    () =>
      debounce((query) => {
        if (!query) return
        dispatch(searchTags({ query, limit: 8 })).then((data) => {
          if (!data.error) {
            console.log('[SUCCESS]: ', 'Tag search succeeded!')
          } else {
            console.log('[FAIL]: ', 'Failed to search tags.')
          }
          setTagResultsOpen(true)
        })
      }, 300),
    [dispatch]
  )

  // Cleanup debounce on unmount
  useEffect(() => {
    return () => {
      handleSearch.clear()
    }
  }, [handleSearch])

  const handleTagInputChange = (e) => {
    const { value } = e.target
    setTagSearch(value) // Set the value immediately for responsive input

    const { error, tag, normalizedTag } = normalizeTagSearch(value)

    if (!error) {
      handleSearch(tag) // Only debounce the actual search
    } else if (error && normalizedTag.length === 0) {
      setTagResultsOpen(false)
    }
  }

  const handleSelectTag = (e) => {
    e.preventDefault()

    const id = e.currentTarget.id
    const newTags = [...tagIds]

    if (tagIds.includes(id)) {
      if (setError) setError(`${id} has already been tagged.`)

      setTagSearch('')
      setTagResultsOpen(false)
      return
    }

    newTags.push(id)

    setTagIds(newTags)
    setTagSearch('')
    setTagResultsOpen(false)

    if (setError) setError('')
  }

  const handleSubmit = (event) => {
    event.preventDefault()
  }

  useEffect(() => {
    if (!didRecognizeRef) {
      if (inputRef.current !== null && typeof inputRef.current !== 'undefined') {
        setDidRecognizeRef(true)
      }
    }
  }, [inputRef, didRecognizeRef])

  // Reset focused index when menu opens/closes
  useEffect(() => {
    if (tagResultsOpen) {
      setFocusedIndex(0)
    } else {
      setFocusedIndex(-1)
    }
  }, [tagResultsOpen])

  const handleKeyDown = useCallback(
    (event) => {
      if (!tagResultsOpen) return

      const items = menuListRef.current?.querySelectorAll('[role="option"]')
      if (!items?.length) return

      switch (event.key) {
        case 'ArrowDown':
          event.preventDefault()
          setFocusedIndex((prev) => (prev + 1) % items.length)
          break
        case 'ArrowUp':
          event.preventDefault()
          setFocusedIndex((prev) => (prev - 1 + items.length) % items.length)
          break
        case 'Enter':
          event.preventDefault()
          if (focusedIndex >= 0) {
            items[focusedIndex].click()
          }
          break
        case 'Escape':
          event.preventDefault()
          setTagResultsOpen(false)
          inputRef.current?.focus()
          break
        case 'Tab':
          if (event.shiftKey) {
            event.preventDefault()
            setTagResultsOpen(false)
            inputRef.current?.focus()
          }
          break
        default:
          break
      }
    },
    [tagResultsOpen, focusedIndex]
  )

  const handleInputKeyDown = useCallback(
    (event) => {
      if (event.key === 'Tab' && !event.shiftKey && tagResultsOpen) {
        event.preventDefault()
        const firstItem = menuListRef.current?.querySelector('[role="option"]')
        if (firstItem) {
          firstItem.focus()
        }
      }
    },
    [tagResultsOpen]
  )

  const handleInputFocus = useCallback(() => {
    if (tagResultsOpen) {
      setTagSearch('')
      setTagResultsOpen(false)
    }
  }, [tagResultsOpen])

  // Focus the first item when menu opens
  useEffect(() => {
    if (tagResultsOpen && menuListRef.current) {
      const firstItem = menuListRef.current.querySelector('[role="option"]')
      if (firstItem) {
        firstItem.focus()
      }
    }
  }, [tagResultsOpen])

  const searchResults = tagResults.map((tag) => {
    // Here we bold only the search term in the returned tag result
    const normalizedSearchTerm = normalizeTagSearch(tagSearch).normalizedTag
    const replacedStr = tag._id.replace(normalizedSearchTerm, `,${normalizedSearchTerm},`)
    const splitStr = replacedStr.split(',')
    const searchTermIndex = splitStr.indexOf(normalizedSearchTerm)
    const resultArr = []

    splitStr.forEach((str, i) => {
      if (i === searchTermIndex) {
        resultArr.push(<b key={str}>{str}</b>)
      } else {
        resultArr.push(str)
      }
    })

    return (
      <MenuItem
        id={tag._id}
        key={tag._id}
        className={classes.menuItem}
        onClick={handleSelectTag}
        role="option">
        <Result>
          <span className="material-icons">add</span>
          <span>{resultArr}</span>
        </Result>
      </MenuItem>
    )
  })

  return (
    <MainContainer variant={variant} style={style || {}}>
      <span className="material-icons" aria-hidden="true">
        search
      </span>

      <Input
        ref={inputRef}
        type="text"
        placeholder={tagIds?.length >= 5 ? 'No more tags allowed' : 'e.g. do-good'}
        value={tagSearch}
        onChange={handleTagInputChange}
        onKeyDown={handleInputKeyDown}
        onFocus={handleInputFocus}
        disabled={tagIds?.length >= 5}
        variant={variant}
        aria-label="Search for tags input"
        autocomplete="off"
        aria-expanded={tagResultsOpen}
        aria-controls="tag-search-results"
        aria-haspopup="listbox"
      />

      {didRecognizeRef && (
        <Popper
          id="tag-search-results"
          open={tagResultsOpen}
          anchorEl={inputRef.current}
          placement="bottom-start"
          transition
          disablePortal
          className={classes.popper}
          style={{ zIndex: 100 }}>
          {({ TransitionProps }) => (
            <Grow {...TransitionProps} id="nav-menu-dropdown">
              <Paper>
                <ClickAwayListener onClickAway={() => setTagResultsOpen(false)}>
                  <MenuList
                    ref={menuListRef}
                    className={classes.menuList}
                    role="listbox"
                    tabIndex={0}
                    onKeyDown={handleKeyDown}
                    aria-live="polite"
                    aria-atomic="true"
                    aria-label="Tag search results">
                    {searchResults}

                    {searchResults.length === 0 &&
                      disableNewTags &&
                      !normalizeTagSearch(tagSearch).error && (
                        <MenuItem
                          id={normalizeTagSearch(tagSearch).normalizedTag}
                          className={classes.menuItem}
                          onClick={handleSelectTag}
                          role="option"
                          tabIndex={focusedIndex === searchResults.length ? 0 : -1}
                          onFocus={() => setFocusedIndex(searchResults.length)}
                          onKeyDown={(e) => {
                            if (e.key === 'Escape') {
                              e.preventDefault()
                              setTagResultsOpen(false)
                              inputRef.current?.focus()
                            }
                          }}>
                          <Result>
                            <span>
                              No results found for{' '}
                              <b>{normalizeTagSearch(tagSearch).normalizedTag}</b>
                            </span>
                          </Result>
                        </MenuItem>
                      )}

                    {!tagResults.some(
                      (tag) => tag._id === normalizeTagSearch(tagSearch).normalizedTag
                    ) && (
                      <MenuItem
                        id={normalizeTagSearch(tagSearch).normalizedTag}
                        className={classes.menuItem}
                        onClick={handleSelectTag}
                        role="option"
                        tabIndex={focusedIndex === searchResults.length + 1 ? 0 : -1}
                        onFocus={() => setFocusedIndex(searchResults.length + 1)}
                        onKeyDown={(e) => {
                          if (e.key === 'Escape') {
                            e.preventDefault()
                            setTagResultsOpen(false)
                            inputRef.current?.focus()
                          }
                        }}>
                        {!disableNewTags && (
                          <Result>
                            <span className="material-icons">add</span>
                            <span>
                              Create new tag <b>{normalizeTagSearch(tagSearch).normalizedTag}</b>
                            </span>
                          </Result>
                        )}
                      </MenuItem>
                    )}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      )}
    </MainContainer>
  )
}

export default TagsSearch
