import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import _ from 'lodash'
import { useNavigate } from 'react-router-dom'
import { createOrganizationFollow } from '../../../../../redux/thunks/follows/createOrganizationFollow'
import { deleteFollowByOrganizationID } from '../../../../../redux/thunks/follows/deleteFollowByOrganizationID'
// import checkPermissions from '../../../../../utils/functions/checkPermissions'
import UserAvatar from '../../../../partials/user-avatar/user-avatar'
import getOrganizationImage from '../../../../../utils/functions/getOrganizationImage'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import { Button, Chip } from '@mui/material'
import SkeletonTrendingIssue from '../../../../skeletons/skeleton-trending-issue'
import {
  Header,
  TabContainer,
  Tab,
  ResultContainer,
  AvatarContainer,
  ProfileInfoContainer,
  ProfileName,
  ProfileLocation,
  ActionContainer,
  RolesContainer,
  NoResults
} from './styled/center-comps'

const useStyles = makeStyles((theme) =>
  createStyles({
    unfollow: {
      minWidth: '2rem',
      '& span': {
        fontSize: '1.3rem !important'
      },
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.secondary.light,
      '&:hover': {
        backgroundColor: theme.palette.secondary.light
      },
      borderRadius: '.2rem',
      cursor: 'pointer',
      '& > *': {
        pointerEvents: 'none'
      }
    },
    follow: {
      minWidth: '2rem',
      '& span': {
        fontSize: '1.3rem !important'
      },
      borderRadius: '.2rem',
      color: theme.palette.text.primary,
      cursor: 'pointer',
      '&:hover': {},
      '& > *': {
        pointerEvents: 'none'
      }
    },
    chip: {
      marginRight: '.5rem',
      borderRadius: '.3rem'
    }
  })
)

function Center(props) {
  const classes = useStyles()
  const { isLoading, managingOrganizationIds } = props
  const [tab, setTab] = useState('managing')
  const assignedRoles = useSelector(({ assignedRoles }) => assignedRoles)
  const roles = useSelector(({ roles }) => roles)
  const currentUser = useSelector(({ currentUser }) => currentUser)
  const follows = useSelector(({ follows }) => follows)
  const organizations = useSelector(({ organizations }) => organizations)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleFollowOrganization = (e) => {
    const organization = _.find(organizations, {
      '_id': e.target.id
    })

    if (typeof organization === 'undefined' || organization === null) {
      console.log('[FAIL]: ', 'Failed to find organization for createOrganizationFollow.')
    } else {
      dispatch(
        createOrganizationFollow({
          organizationId: e.target.id,
          name: organization.publicName
        })
      ).then((data) => {
        if (!data.error) {
          console.log('[SUCCESS]: ', 'Successfully followed organization!')
        } else {
          console.log('[FAIL]: ', 'Failed to follow organization.')
        }
      })
    }
  }

  const handleUnfollowOrganization = (e) => {
    const organization = _.find(organizations, {
      '_id': e.target.id
    })

    if (typeof organization === 'undefined' || organization === null) {
      console.log('[FAIL]: ', 'Failed to find organization for deleteFollowByOrganizationID.')
    } else {
      dispatch(
        deleteFollowByOrganizationID({
          organizationId: e.target.id,
          currUserId: currentUser.id,
          name: organization.publicName
        })
      ).then((data) => {
        if (!data.error) {
          console.log('[SUCCESS]: ', 'Successfully unfollowed organization!')
        } else {
          console.log('[FAIL]: ', 'Failed to unfollow organization.')
        }
      })
    }
  }

  const renderManagingOrganizations = () => {
    const filteredOrganizations = _.filter(organizations, (organization) => {
      if (_.includes(managingOrganizationIds, organization._id)) return true

      return false
    })

    return filteredOrganizations.map((organization, i) => {
      const filteredRoles = _.filter(roles, { organizationId: organization._id })
      const filteredAssignedRoles = _.filter(assignedRoles, (assignedRole) => {
        return _.some(filteredRoles, { _id: assignedRole.roleId })
      })

      const assignedRoleChips = filteredAssignedRoles.map((assignedRole) => {
        const role = _.find(roles, { _id: assignedRole.roleId })

        if (!role) return null

        return <Chip key={role._id} label={role.name} color="secondary" className={classes.chip} />
      })

      const condStyles = {}
      const { _id, image, publicName, address } = organization
      const { cityName, state } = address
      const isFollowing = _.some(follows, (follow) => {
        return follow.follower === currentUser.id && follow.organizationId === _id
      })

      if (i === 0) {
        condStyles.borderTopLeftRadius = '.3rem'
        condStyles.borderTopRightRadius = '.3rem'
      }

      if (i === filteredOrganizations.length - 1) {
        condStyles.borderBottomLeftRadius = '.3rem'
        condStyles.borderBottomRightRadius = '.3rem'
      }

      return (
        <ResultContainer key={_id} style={condStyles}>
          <AvatarContainer>
            <UserAvatar
              src={getOrganizationImage(image)}
              width={'3.05rem'}
              height={'3.05rem'}
              borderWidth={'0.2rem'}
              link={`/organization/${_id}`}
              verified={1}
            />
          </AvatarContainer>

          <ActionContainer>
            {isFollowing ? (
              <Button
                id={_id}
                variant="contained"
                color="primary"
                className={classes.unfollow}
                onClick={handleUnfollowOrganization}>
                <span className="material-icons">person_remove</span>
              </Button>
            ) : (
              <Button
                id={_id}
                variant="contained"
                color="primary"
                className={classes.follow}
                onClick={handleFollowOrganization}>
                <span className="material-icons">person_add</span>
              </Button>
            )}
          </ActionContainer>

          <ProfileInfoContainer>
            <ProfileName id={_id} onClick={(e) => navigate(`/organization/${e.target.id}`)}>
              {publicName}
            </ProfileName>

            {cityName && state && <ProfileLocation>{cityName + ', ' + state}</ProfileLocation>}

            <RolesContainer>{assignedRoleChips}</RolesContainer>
          </ProfileInfoContainer>
        </ResultContainer>
      )
    })
  }

  const renderFollowingOrganizations = () => {
    return follows.map((follow, i) => {
      const organization = _.find(organizations, { _id: follow.organizationId })

      if (!organization) return null

      const condStyles = {}
      const { _id, image, publicName, address } = organization
      const { cityName, state } = address
      const isFollowing = _.some(follows, (follow) => {
        return follow.follower === currentUser.id && follow.organizationId === _id
      })

      if (i === 0) {
        condStyles.borderTopLeftRadius = '.3rem'
        condStyles.borderTopRightRadius = '.3rem'
      }

      if (i === follows.length - 1) {
        condStyles.borderBottomLeftRadius = '.3rem'
        condStyles.borderBottomRightRadius = '.3rem'
      }

      return (
        <ResultContainer key={_id} style={condStyles}>
          <AvatarContainer>
            <UserAvatar
              src={getOrganizationImage(image)}
              width={'3.05rem'}
              height={'3.05rem'}
              borderWidth={'0.2rem'}
              link={`/organization/${_id}`}
              verified={1}
            />
          </AvatarContainer>

          <ActionContainer>
            {isFollowing ? (
              <Button
                id={_id}
                variant="contained"
                color="primary"
                className={classes.unfollow}
                onClick={handleUnfollowOrganization}>
                <span className="material-icons">person_remove</span>
              </Button>
            ) : (
              <Button
                id={_id}
                variant="contained"
                color="primary"
                className={classes.follow}
                onClick={handleFollowOrganization}>
                <span className="material-icons">person_add</span>
              </Button>
            )}
          </ActionContainer>

          <ProfileInfoContainer>
            <ProfileName id={_id} onClick={(e) => navigate(`/organization/${e.target.id}`)}>
              {publicName}
            </ProfileName>

            {cityName && state && <ProfileLocation>{cityName + ', ' + state}</ProfileLocation>}
          </ProfileInfoContainer>
        </ResultContainer>
      )
    })
  }

  const managingOrganizations = renderManagingOrganizations()
  const followingOrganizations = renderFollowingOrganizations()

  return (
    <div>
      <Header>My Organizations</Header>

      <TabContainer>
        <Tab onClick={() => setTab('managing')} selected={tab === 'managing'}>
          Managing
        </Tab>

        <Tab onClick={() => setTab('following')} selected={tab === 'following'}>
          Following
        </Tab>
      </TabContainer>

      {!isLoading ? (
        <div>
          {tab === 'managing' && (
            <>
              {managingOrganizations.length > 0 ? (
                managingOrganizations
              ) : (
                <NoResults>{'You are not managing any organizations at this time...'}</NoResults>
              )}
            </>
          )}

          {tab === 'following' && (
            <>
              {followingOrganizations.length > 0 ? (
                followingOrganizations
              ) : (
                <NoResults>{'You are not following any organizations at this time...'}</NoResults>
              )}
            </>
          )}
        </div>
      ) : (
        <div>
          <SkeletonTrendingIssue count={3} />
        </div>
      )}
    </div>
  )
}

export default Center
