import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import config from '../../../config'
// import WarningBanner from '../../warning-banner/warning-banner'
import SideNavDesktop from './components/side-nav/side-nav-desktop'
import SideNavMobile from './components/side-nav/side-nav-mobile'
import AccountDetails from './components/account-details/account-details'
import ProfilePicture from './components/profile-picture/profile-picture'
import PersonalDetails from './components/personal/personal'
import LocationForm from './components/location/location-form'
import PrivacySettings from './components/privacy/privacy'
import EmailSettings from './components/email/email'
import Identification from './components/identification/identification'
import BillingInfo from './components/billing/billing'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import { IconButton } from '@mui/material'
import {
  MainContainer,
  SectionContainer,
  SectionHeader,
  SectionContentContainer
} from './styled/my-account-comps'

// Add these constants at the top of the file
const SECTIONS = [
  '',
  'account',
  'picture',
  'personal',
  'location',
  'identification',
  'privacy',
  'email'
]

const CONTENT_HEADERS = {
  account: 'Account Details',
  picture: 'Profile Picture',
  personal: 'Personal Details',
  location: 'Location Settings',
  identification: 'Identification Information',
  privacy: 'Privacy Settings',
  email: 'Email Settings'
}

const useStyles = makeStyles((theme) =>
  createStyles({
    iconButton: {
      backgroundColor: 'rgba(0,0,0,.1)',
      padding: '.5rem',
      color: theme.palette.text.secondary,
      borderRadius: '.5rem',
      position: 'absolute',
      top: '50%',
      left: '.5rem',
      transform: 'translateY(-50%)',
      '& span.material-icons': {
        fontSize: '1.8rem'
      },
      '&:hover': {
        backgroundColor: 'rgba(0,0,0,.2)'
      }
    }
  })
)

function MyAccount() {
  const { tab } = useParams()
  const classes = useStyles()
  const navigate = useNavigate()
  const [sideNavMobileOpen, setSideNavMobileOpen] = useState(false)
  const [backButton, setBackButton] = useState(false)

  // Memoize this calculation
  const fullscreen = useMemo(() => {
    return window.innerWidth <= parseInt(config.UI.drawer_breakpoint.max.split('px')[0])
  }, [])

  // Update window resize handler
  const handleWindowResize = useCallback(() => {
    const newFullscreen =
      window.innerWidth <= parseInt(config.UI.drawer_breakpoint.max.split('px')[0])
    setBackButton(newFullscreen)

    if (tab) {
      setSideNavMobileOpen(false)
    } else {
      if (newFullscreen) {
        setSideNavMobileOpen(true)
      } else {
        navigate('/my-account/account')
      }
    }
  }, [navigate, tab])

  // Memoize content header getter
  const getContentHeader = useCallback(() => {
    return CONTENT_HEADERS[tab] || 'Account Details'
  }, [tab])

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize)
    return () => window.removeEventListener('resize', handleWindowResize)
  }, [handleWindowResize])

  useEffect(() => {
    if (tab) {
      if (!SECTIONS.includes(tab)) {
        navigate('/page-not-found')
      }
    } else {
      if (fullscreen) {
        setSideNavMobileOpen(true)
      } else {
        navigate('/my-account/account')
      }
    }
  }, [fullscreen, navigate])

  // Memoize back button handler
  const handleBackButton = useCallback(() => {
    navigate('/my-account')
  }, [navigate])

  return (
    <MainContainer>
      <SideNavMobile open={sideNavMobileOpen} setOpen={setSideNavMobileOpen} />
      <SideNavDesktop />

      {tab && (
        <>
          <SectionContainer>
            <SectionHeader>
              {(backButton || fullscreen) && (
                <IconButton className={classes.iconButton} onClick={handleBackButton} size="large">
                  <span className="material-icons">chevron_left</span>
                </IconButton>
              )}

              {getContentHeader()}
            </SectionHeader>

            {/* {currentUser.stripe && currentUser.stripe.customerId === '' && (
              <WarningBanner
                type="info"
                htmlBody={
                  <React.Fragment>
                    <b>Attention Beta User:&nbsp;</b> On 4/30/2021 user accounts who have not
                    provided a payment method will be closed. Please add a payment method by
                    navigating to{' '}
                    <a href="/my-account/billing">My Account &gt; Billing Information</a>.
                  </React.Fragment>
                }
                style={{ margin: '.5rem 0' }}
              />
            )}

            {!currentProfile.documentVerified && (
              <WarningBanner
                type="info"
                htmlBody={
                  <React.Fragment>
                    <b>Attention Beta User:&nbsp;</b> On 4/30/2021 user accounts who have not
                    verified their identity will be closed. Please verify your identity by
                    navigating to{' '}
                    <a href="/my-account/identification">My Account &gt; Identification</a>.
                  </React.Fragment>
                }
                style={{ margin: '.5rem 0' }}
              />
            )}

            {(!currentUser.phone || currentUser.phone.phoneNumber === '') && (
              <WarningBanner
                type="info"
                htmlBody={
                  <React.Fragment>
                    {' '}
                    <b>Attention Beta User:&nbsp;</b> On 4/30/2021 user accounts who have not added
                    a phone number will be closed. Please add your phone number by navigating to{' '}
                    <a href="/my-account/account">My Account &gt; Account Information</a>.
                  </React.Fragment>
                }
                style={{ margin: '.5rem 0' }}
              />
            )} */}

            <SectionContentContainer>
              {tab === 'account' && <AccountDetails />}
              {tab === 'picture' && <ProfilePicture />}
              {tab === 'personal' && <PersonalDetails />}
              {tab === 'location' && <LocationForm />}
              {tab === 'identification' && <Identification />}
              {tab === 'privacy' && <PrivacySettings />}
              {tab === 'email' && <EmailSettings />}
              {/* {tab === 'billing' && <BillingInfo />} */}
            </SectionContentContainer>
          </SectionContainer>
        </>
      )}
    </MainContainer>
  )
}

export default MyAccount
