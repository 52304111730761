import React, { Component } from 'react'
import PropTypes from 'prop-types'
import withStyles from '@mui/styles/withStyles'
import { Skeleton } from '@mui/material'

const animation = 'pulse'

const wrapperStyles = {
  backgroundColor: 'rgba(0,0,0,0)',
  display: 'inline-block',
  padding: '0 1.5rem'
}

const styles = () => ({
  image: {
    backgroundColor: 'rgba(250, 250, 250, .2)',
    display: 'block',
    margin: '0 auto',
    borderRadius: '50%'
  },
  header: {
    marginTop: '.5rem'
  }
})

class SkeletonConnection extends Component {
  render() {
    const { classes, count, style, color } = this.props
    const styleOverrides = style || {}
    const backgroundColor = color || 'rgba(0, 0, 0, 0.04)'
    const numSkeletons = count || 1
    let skeletons = []

    for (var i = 0; i < numSkeletons; i++) {
      skeletons.push(
        <div key={i} style={{ ...wrapperStyles, ...styleOverrides }}>
          <Skeleton
            animation={animation}
            variant="circular"
            width={'4rem'}
            height={'4rem'}
            className={classes.image}
            style={{ backgroundColor }}
          />
          <Skeleton
            animation={animation}
            variant="text"
            width={'5.5rem'}
            height={'1.5rem'}
            className={classes.header}
            style={{ backgroundColor }}
          />
        </div>
      )
    }

    return (
      <div style={{ height: '7.5rem', overflow: 'hidden', textAlign: 'center' }}>{skeletons}</div>
    )
  }
}

SkeletonConnection.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(SkeletonConnection)
