import { useState } from 'react'
import config from '../../../../../../config'
import LoginButton from '../../../login-button/login-button'
import { Box, Popper, Grow, Paper, ClickAwayListener } from '@mui/material'
import {
  MuiMenuList,
  MuiMenuItem,
  MuiTypography,
  MuiIconButton,
  MuiBox
} from './styled/mui-components'

function Dropdown() {
  const [anchorEl, setAnchorEl] = useState(null)
  const [open, setOpen] = useState(false)

  const handleDropdownButton = (e, open) => {
    setAnchorEl(e.currentTarget)
    setOpen(open)
  }

  return (
    <>
      <MuiIconButton onClick={(e) => handleDropdownButton(e, !open)} size="large">
        <span className="material-icons">menu</span>
      </MuiIconButton>

      <Box>
        <Popper open={open} anchorEl={anchorEl} placement={'bottom-end'} transition disablePortal>
          {({ TransitionProps }) => (
            <Grow {...TransitionProps} timeout={350}>
              <Paper>
                <ClickAwayListener onClickAway={() => setOpen(false)}>
                  <MuiMenuList>
                    <MuiMenuItem
                      component="a"
                      href={`${config.publicSiteURL}`}
                      target="_blank"
                      rel="noreferrer">
                      <MuiTypography component={'h2'}>Home</MuiTypography>
                    </MuiMenuItem>

                    <MuiMenuItem
                      component="a"
                      href={`${config.publicSiteURL}/terms-of-use`}
                      target="_blank"
                      rel="noreferrer">
                      <MuiTypography component={'h2'}>Terms of Use</MuiTypography>
                    </MuiMenuItem>

                    <MuiMenuItem
                      component="a"
                      href={`${config.publicSiteURL}/privacy-policy`}
                      target="_blank"
                      rel="noreferrer">
                      <MuiTypography component={'h2'}>Privacy Policy</MuiTypography>
                    </MuiMenuItem>

                    <MuiMenuItem
                      component="a"
                      href={`${config.publicSiteURL}/copyright-policy`}
                      target="_blank"
                      rel="noreferrer">
                      <MuiTypography component={'h2'}>Copyright Policy</MuiTypography>
                    </MuiMenuItem>

                    <MuiBox>
                      <LoginButton styleProps={{ flex: 1 }} />
                    </MuiBox>
                  </MuiMenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Box>
    </>
  )
}

export default Dropdown
