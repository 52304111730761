import { useState } from 'react'
import config from '../../../../config'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme, createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles'
import { MainContainer, MobileContainer, Header, TabList, Tab } from './styled/tabs-comps'

const localTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: parseInt(config.UI.drawer_breakpoint.min, 10), // 901
      lg: 1280,
      xl: 1920
    }
  }
})

function Tabs({ handleSetTab }) {
  const [selected, setSelected] = useState('inbox')
  const handleSetSelected = (tab) => {
    setSelected(tab)
    handleSetTab(tab)
  }

  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('md'))
  const desktop = useMediaQuery(theme.breakpoints.up('lg'))

  return (
    <>
      {matches ? (
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={localTheme}>
            <MainContainer>
              <Header>Discussions</Header>
              {desktop ? (
                <TabList>
                  <Tab
                    width={selected === 'inbox' ? '12rem' : '10rem'}
                    fontSize={selected === 'inbox' ? '1.35rem' : '1.125rem'}
                    onClick={() => handleSetSelected('inbox')}>
                    Inbox
                  </Tab>
                  <Tab
                    width={selected === 'sent' ? '12rem' : '10rem'}
                    fontSize={selected === 'sent' ? '1.35rem' : '1.125rem'}
                    onClick={() => handleSetSelected('sent')}>
                    Sent
                  </Tab>
                </TabList>
              ) : (
                <TabList>
                  <Tab
                    width={selected === 'inbox' ? '10rem' : '8rem'}
                    fontSize={selected === 'inbox' ? '1.35rem' : '1.125rem'}
                    onClick={() => handleSetSelected('inbox')}>
                    Inbox
                  </Tab>
                  <Tab
                    width={selected === 'sent' ? '10rem' : '8rem'}
                    fontSize={selected === 'sent' ? '1.35rem' : '1.125rem'}
                    onClick={() => handleSetSelected('sent')}>
                    Sent
                  </Tab>
                </TabList>
              )}
            </MainContainer>
          </ThemeProvider>
        </StyledEngineProvider>
      ) : (
        <MobileContainer>
          <Header>Discussions</Header>
          <TabList>
            <Tab
              focused={selected === 'inbox'}
              fontSize={selected === 'inbox' ? '1.35rem' : '1.125rem'}
              onClick={() => handleSetSelected('inbox')}>
              {' '}
              Inbox
            </Tab>
            <Tab
              focused={selected === 'sent'}
              fontSize={selected === 'sent' ? '1.35rem' : '1.125rem'}
              onClick={() => handleSetSelected('sent')}>
              {' '}
              Sent
            </Tab>
          </TabList>
        </MobileContainer>
      )}
    </>
  )
}

export default Tabs
