import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { loginWithCode } from '../../../redux/thunks/users/loginWithCode'
import config from '../../../config'
import Footer from '../../footer/footer'
import { useMediaQuery } from '@mui/material'
import Button from '@mui/material/Button'
import {
  HeroContainer,
  InnerContainer,
  TitleContainer,
  Title,
  SubTitle
} from './styled/landing-comps'
import { Box, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import Image from 'mui-image'

const MuiTypographyTitle = styled(Typography)(({ theme }) => ({
  fontSize: '2.5rem',
  color: theme.palette.text.secondary,
  textAlign: 'center'
}))

export const MuiButton = styled(Button)(({ theme }) => ({
  fontSize: '1.35rem',
  minWidth: '15rem'
}))

// Custom Image wrapper to handle default props
const CustomImage = ({
  src,
  alt,
  duration = 0,
  easing = 'linear',
  fit = 'cover',
  position = 'static',
  width = '100%',
  height = '100%',
  shift,
  ...props
}) => (
  <Image
    src={src}
    alt={alt}
    duration={duration}
    easing={easing}
    fit={fit}
    position={position}
    width={width}
    height={height}
    shift={shift}
    {...props}
  />
)

function Landing() {
  const [authenticated, setAuthenticated] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const isMobile = useMediaQuery('(max-width:1050px)')
  const isAuthenticated = useSelector(({ isAuthenticated }) => isAuthenticated)
  const COGNITO_URL = import.meta.env.VITE_AWS_COGNITO_URL
  const CLIENT_ID = import.meta.env.VITE_AWS_COGNITO_APP_CLIENT_ID
  const REDIRECT_URI = config.cognito.COGNITO_REDIRECT_URI
  const loginURL = `${COGNITO_URL}/login?client_id=${CLIENT_ID}&response_type=code&scope=email+openid+phone+aws.cognito.signin.user.admin+profile&redirect_uri=${REDIRECT_URI}&state=STATE`
  console.log('*** loginURL ***', loginURL)
  useEffect(() => {
    if (window.location.href.includes('code')) {
      const code = window.location.href.split('code=')[1].split('&')[0]

      dispatch(loginWithCode({ code })).then((result) => {
        if (result.code === 200 || result.code === 201) {
          setAuthenticated(true)
        } else {
          // TODO add error handling...
          console.log(`[ERROR], LoginWithCode returned an unsuccessful status code`)
        }
      })
    }
  }, [dispatch])

  useEffect(() => {
    if (authenticated || isAuthenticated) {
      console.log(`RUNNING REDIRECT IN LANDING`, authenticated, loginURL)
      navigate('/home')
    }
  }, [authenticated, isAuthenticated, navigate])

  return (
    <>
      <HeroContainer>
        <CustomImage
          src="https://res.cloudinary.com/headliner/image/upload/b_rgb:abcab3,o_50/a_0/v1712706608/LandingPage/duy-pham-Cecb0_8Hx-o-unsplash_2.jpg"
          alt="people holding shoulders sitting on wall"
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
            position: 'absolute',
            top: 0
          }}>
          <Box
            sx={{
              marginBottom: '6rem',
              width: '30rem',
              '@media (max-width: 899px)': {
                width: '25rem'
              },
              '@media (max-width: 599px)': {
                width: '20rem'
              },
              '@media (max-width: 449px)': {
                width: '18rem'
              }
            }}>
            <CustomImage
              src="https://res.cloudinary.com/headliner/image/upload/v1712953382/logos/civiltalk_logo_green_bg_new.png"
              alt="CivilTalk: Your Civility Matters"
              shift="bottom"
              fit="contain"
            />
          </Box>
          {/* <MuiTypographyTitle>STRENGTHEN YOUR EQ AND EXERCISE YOUR CIVILITY</MuiTypographyTitle> */}
          {isMobile && (
            <MuiButton variant="contained" href={loginURL}>
              Sign up/Login
            </MuiButton>
          )}
        </Box>
      </HeroContainer>

      <Footer />
    </>
  )
}

export default Landing
