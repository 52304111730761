// import 'react-app-polyfill/ie11'
// import 'react-app-polyfill/stable'
import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { Provider } from 'react-redux'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
// import { MuiThemeProvider } from '@mui/material/styles'
import { ThemeProvider as StyledThemeProvider } from 'styled-components'
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles'
import { firebase, firebaseConfig } from './firebase/firebase.js'
import { createFirestoreInstance } from 'redux-firestore'
import { ReactReduxFirebaseProvider } from 'react-redux-firebase'
import theme from './res/mui-theme.js'
import styledTheme from './res/theme'
import './index.css'
import './config.js'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import 'react-phone-input-2/lib/bootstrap.css'
import * as serviceWorker from './serviceWorker'
import config from './config.js'
import { store } from './redux/store.js'
import ScrollToTop from './components/scroll-to-top/scroll-to-top'
import App from './components/App'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

// ****************************************** //
// *** Initializing Firebase App  *********** //
// ****************************************** //
// firebase.initializeApp(firebaseConfig)

const rrfProps = {
  firebase,
  config: firebaseConfig,
  dispatch: store.dispatch,
  createFirestoreInstance
}

Sentry.init({
  dsn: config.sentry,
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.5
})

// React-Query Provider
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      refetchOnWindowFocus: false
    }
  }
})

const appContainer = document.getElementById('root')
const root = createRoot(appContainer)

root.render(
  <Provider store={store}>
    <ReactReduxFirebaseProvider {...rrfProps}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Elements stripe={loadStripe(import.meta.env.VITE_STRIPE_KEY)}>
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={theme}>
                <StyledThemeProvider theme={styledTheme}>
                  <ScrollToTop>
                    <App />
                    <div id="modal-root" />
                  </ScrollToTop>
                </StyledThemeProvider>
              </ThemeProvider>
            </StyledEngineProvider>
          </Elements>
        </BrowserRouter>
      </QueryClientProvider>
    </ReactReduxFirebaseProvider>
  </Provider>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
