import { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import config from '../../../config.js'
import TermsHeader from './components/terms-header'
import TermsDocumentsDisplay from './components/terms-documents-display'
import TermsOfUseForm from './components/terms-of-use-form'
import Loader from '../../loader/loader'
import Dialog from '@mui/material/Dialog'

function TermsOfUse() {
  const [open, setOpen] = useState(true)

  const {
    data: termsVersions,
    isLoading,
    error
  } = useQuery({
    queryKey: ['termsVersion'],
    queryFn: getCurrentTermsVersion
    // staleTime: 1000 * 60 * 60 // Cache for 1 hour
  })

  if (isLoading) return <Loader />
  if (error) return <div>Error loading terms: {error.message}</div>

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      disablePortal
      fullScreen
      sx={{
        '& .MuiPaper-root': {
          background: '#eaeaea'
        }
      }}>
      {termsVersions && <TermsHeader termsVersion={termsVersions.termsVersion} />}
      {termsVersions && <TermsDocumentsDisplay termsVersion={termsVersions.termsVersion} />}
      {termsVersions && <TermsOfUseForm version={termsVersions.termsVersion.version} />}
    </Dialog>
  )
}

export default TermsOfUse

// ************************* //
// *** REACT QUERY FETCH *** //
// ************************* //
const getCurrentTermsVersion = async () => {
  const response = await fetch(`${config.baseURL}/api/terms-versions`)

  return response.json()
}
