import LoginButton from '../../../login-button/login-button'
import config from '../../../../../../config'
import { LinksContainer } from './styled/links-comps'
import { Button } from '@mui/material'
import { styled } from '@mui/material/styles'

const StyledButton = styled(Button)(({ theme }) => ({
  height: '2.6rem',
  color: theme.palette.text.primary,
  fontSize: '1rem',
  fontWeight: 500,
  '&: hover': {
    background: theme.palette.secondary.light,
    opacity: 0.75
  }
}))

function Links() {
  const { pathname } = window.location

  return (
    <LinksContainer aria-labelledby="primary-navigation">
      <StyledButton
        id="primary-navigation"
        variant="text"
        component={'a'}
        href={`${config.publicSiteURL}/`}
        target="_blank">
        Home
      </StyledButton>

      <LoginButton />
    </LinksContainer>
  )
}

export default Links
