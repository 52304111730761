import Box from '@mui/material/Box'
import LogoCondensedImage from '../../../../media/logo/condensed-green.png'
import { LogoCondensed } from '../styled/terms-of-use-comps'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'

function TermsHeader({ termsVersion }) {
  return (
    <MuiBox
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
      <MuiBox
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          maxWidth: '80rem',
          ml: 1,
          mr: 1
        }}>
        <MuiBox>
          <LogoCondensed src={LogoCondensedImage} alt="CivilTalk Logo Condensed (CT)" />
        </MuiBox>

        <MuiTypography
          variant="h1"
          id="alert-dialog-title"
          sx={{
            textAlign: 'center',
            fontSize: '3rem',
            fontWeight: 600,
            margin: '1rem',
            '@media (max-width: 700px)': {
              fontSize: '2.5rem'
            }
          }}>
          Welcome to CivilTalk
        </MuiTypography>

        <MuiTypography
          variant="h2"
          paragraph
          id="alert-dialog-description"
          sx={{
            textAlign: 'center',
            fontSize: '1.75rem',
            fontWeight: 400,
            margin: '1rem',
            '@media (max-width: 700px)': {
              fontSize: '1.25rem'
            }
          }}>
          At CivilTalk, we prioritize your privacy and are committed to safeguarding your
          information.
        </MuiTypography>
        <MuiTypography
          variant="h2"
          paragraph
          id="alert-dialog-description"
          sx={{
            textAlign: 'center',
            fontSize: '1.75rem',
            fontWeight: 400,
            marginBottom: '1rem',
            '@media (max-width: 700px)': {
              fontSize: '1.25rem'
            }
          }}>
          We want to assure you that we will never sell your data to third parties.
        </MuiTypography>
        <MuiTypography
          paragraph
          id="alert-dialog-description"
          sx={{
            textAlign: 'center',
            fontSize: '1.75rem',
            fontWeight: 400,
            marginBottom: '1rem',
            '@media (max-width: 700px)': {
              fontSize: '1.25rem'
            }
          }}>
          As a private company dedicated to serving our community, we uphold the highest standards
          of confidentiality, ensuring that your trust in us is well-placed and respected.
        </MuiTypography>
      </MuiBox>
    </MuiBox>
  )
}

export default TermsHeader

// ***************************** //
// *** MUI Styled Components *** //
// ***************************** //

const MuiBox = styled(Box)(({ theme }) => ({
  // You can add default styling for all MuiBox instances here
}))

const MuiTypography = styled(Typography)(({ theme }) => ({
  // You can add default styling for all MuiTypography instances here
}))
