import { useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import config from '../../../../config.js'
import Image from 'mui-image'
import { Box } from '@mui/material'

type Props = {
  isAuthenticated: boolean
}

function Logo(props: Props) {
  const navigate = useNavigate()
  const [logo] = useState(
    `https://res.cloudinary.com/headliner/image/upload/v1712948051/logos/civiltalk_logo_transparent.png`
  )

  const handleGoHome = () => {
    const { isAuthenticated } = props

    if (isAuthenticated) {
      navigate('/home')
    } else {
      window.location.href = `${config.publicSiteURL}/`
    }
  }

  if (!props.isAuthenticated) return null

  return (
    <Box
      component={'button'}
      onClick={handleGoHome}
      sx={{
        border: 'none',
        backgroundColor: 'unset',
        display: 'flex',
        maxWidth: '14rem',
        justifyContent: 'center',
        alignItems: 'center',
        height: '3.5rem',
        marginLeft: '0.5rem',
        '@media (max-width: 890px)': {
          height: '3.25rem'
        },
        '@media (max-width: 599px)': {
          height: '2.75rem'
        },
        '@media (max-width: 399px)': {
          height: '2.5rem'
        },
        '@media (max-width: 339px)': {
          height: '2.25rem'
        }
      }}>
      <Image
        src={logo}
        alt="CivilTalk Logo (CivilTalk)"
        style={{ objectFit: 'contain' }}
        duration={0}
        easing="linear"
        fit="contain"
        position="static"
        width="100%"
        height="100%"
      />
    </Box>
  )
}

const mapStateToProps = (state: any) => ({
  isAuthenticated: state.isAuthenticated
})

export default connect(mapStateToProps, null)(Logo)
