import React from 'react'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import Button from '@mui/material/Button'
import { MainContainer, ContentContainer, Message } from './styled/page-not-found-comps'

const useStyles = makeStyles((theme) =>
  createStyles({
    button: {
      display: 'block',
      width: '12rem',
      height: '3.5rem',
      margin: '0 auto',
      textTransform: 'capitalize'
    }
  })
)

function PageNotFound() {
  const classes = useStyles()
  const navigate = useNavigate()

  const handleClick = () => {
    navigate('/')
  }

  return (
    <MainContainer>
      <ContentContainer>
        <Message>Page not found</Message>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={handleClick}>
          CivilTalk Home
        </Button>
      </ContentContainer>
    </MainContainer>
  )
}

export default PageNotFound
