import LegalNameForm from './components/legal-name-form/legal-name-form'
import PublicNameForm from './components/public-name-form/public-name-form'
import EmailForm from './components/email-form/email-form'
import PhoneForm from './components/phone-form/phone-form'
import WebsiteForm from './components/website-form/website-form'
import DonationLinkForm from './components/donation-link-form/donation-link-form'

function AccountDetails() {
  return (
    <>
      <LegalNameForm />
      <PublicNameForm />
      <EmailForm />
      <PhoneForm />
      <WebsiteForm />
      <DonationLinkForm />
    </>
  )
}

export default AccountDetails
