import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import debounce from 'lodash/debounce'
import find from 'lodash/find'
import moment from 'moment'
import { createPrompt } from '../../../redux/thunks/prompts/createPrompt'
import { updatePromptById } from '../../../redux/thunks/prompts/updatePromptById'
// import { postDraftActions } from '../../../redux/action-creators/post-draft'
import CreatePromptValidation from './validations/create-prompt-validation'
import isVerifiedUser from '../../../utils/functions/isVerifiedUser'
import PublishDatePicker from './components/publish-date-picker'
import UserAvatar from '../user-avatar/user-avatar'
import FormError from '../../alerts/form-error'
import makeStyles from '@mui/styles/makeStyles'
import styled from '@mui/material/styles/styled'
import createStyles from '@mui/styles/createStyles'
import { InputLabel, TextField, Button, CircularProgress } from '@mui/material'
import MessageIcon from '@mui/icons-material/Message'
import {
  MainContainer,
  Prompt,
  FormContainer,
  FormInputContainer,
  AvatarContainer,
  TakeHeaderInnerContainer,
  Name,
  InputContainer,
  TextCount,
  ButtonsContainer
} from './styled/prompt-form-comps'
import { getResponsiveStyles } from './responsive-styles/prompt-form'
import theme from '../../../res/theme'

const styles = {
  visibilityButton: {
    minHeight: '2.2rem',
    color: theme.palette.text.primary,
    backgroundColor: '#ddd',
    position: 'relative',
    '&:hover': {
      backgroundColor: '#eee'
    },
    '& .material-icons': {
      fontSize: '1.2rem'
    }
  }
}

const useStyles = makeStyles((theme) =>
  createStyles({
    textInput: {
      background: '#eff5f1',
      '&.MuiInputLabel-root': {
        color: '#000'
      }
    },
    cancel: {
      margin: '0 0.5rem',
      fontSize: '1rem',
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.cancelButton.light,
      '&:hover': {
        backgroundColor: theme.palette.cancelButton.dark
      }
    },
    submitButton: {
      margin: '0 0.5rem',
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.light
      }
    },
    submitText: {
      fontWeight: 700
    },
    circularProgress: {
      display: 'block',
      width: '17px !important',
      height: '17px !important',
      margin: '.3rem auto 0 auto',
      '& svg': {
        color: 'white'
      }
    }
  })
)

function PromptForm(props) {
  const { setOpen, courseId, prompts, setPrompts, promptToEdit, isEditing, handleClose } = props
  const classes = useStyles()
  const mainRef = useRef(null)
  const [isLoading, setIsLoading] = useState(true)
  const [didRecognizeRef, setDidRecognizeRef] = useState(false)
  const [componentWidth, setComponentWidth] = useState(null)
  const [responsiveStyles, setResponsiveStyles] = useState(getResponsiveStyles(componentWidth))
  const [title, setTitle] = useState('')
  const [prompt, setPrompt] = useState('')
  const [publishDate, setPublishDate] = useState(moment())
  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState('')
  const currentProfile = useSelector(({ currentProfile }) => currentProfile)
  // const postDraft = useSelector(({ postDraft }) => postDraft)
  const { fname, lname, avatar, documentVerified } = currentProfile
  const picture = avatar
  const verified = documentVerified ? 1 : 0
  const dispatch = useDispatch()

  useEffect(() => {
    const textareaEl = document.querySelector('#textarea-prompt')

    window.addEventListener('resize', handleSetComponentWidth)
    textareaEl.addEventListener('input', autoResize, false)

    return () => {
      window.removeEventListener('resize', handleSetComponentWidth)
      textareaEl.removeEventListener('input', autoResize, false)
    }
  }, [])

  useEffect(() => {
    if (!didRecognizeRef) {
      if (mainRef.current !== null && typeof mainRef.current !== 'undefined') {
        setComponentWidth(mainRef.current.clientWidth)
        setDidRecognizeRef(true)
      }
    }
  }, [mainRef, didRecognizeRef])

  useEffect(() => {
    setResponsiveStyles(getResponsiveStyles(componentWidth))
  }, [componentWidth])

  useEffect(() => {
    if (isEditing) {
      setTitle(promptToEdit.title)
      setPrompt(promptToEdit.prompt)
      setPublishDate(moment(promptToEdit.publishDate))
    }
  }, [])

  const handleSetComponentWidth = () => {
    const width = mainRef.current ? mainRef.current.clientWidth : window.innerWidth

    setComponentWidth(width)
  }

  const autoResize = () => {
    const textareaEl = document.querySelector('#textarea-post')

    textareaEl.style.height = 'auto'
    textareaEl.style.height = textareaEl.scrollHeight + 'px'
  }

  const handleTitleInputChange = (e) => {
    const { value } = e.target

    if (value.length <= 300) {
      if (error) setError('')

      setTitle(value)
    }

    // handleUpdatePostDraft(value)
  }

  const handlePromptInputChange = (e) => {
    const { value } = e.target

    if (value.length <= 3000) {
      if (error) setError('')

      setPrompt(value)
    }

    // handleUpdatePostDraft(value)
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  // const handleUpdatePostDraft = useCallback(
  //   debounce((value) => {
  //     dispatch(postDraftActions.set(value))
  //   }, 1000),
  //   []
  // )

  const handleSubmit = (e) => {
    e.preventDefault()

    setProcessing(true)

    const { error } = CreatePromptValidation({
      title,
      prompt,
      courseId,
      publishDate: publishDate.valueOf()
    })

    if (error) {
      console.log('[VALIDATION ERROR]: ', error)

      setError(error)
      setProcessing(false)
    } else {
      dispatch(
        createPrompt({
          title,
          prompt,
          courseId,
          publishDate: publishDate.valueOf()
        })
      )
        .then((data) => {
          if (!data.error) {
            console.log('[SUCCESS]: ', 'Successfully created prompt!', data)

            const { prompt } = data

            setPrompts([prompt, ...prompts])

            // dispatch(postDraftActions.clear())
            setOpen(false)
          } else {
            console.log('[FAIL]: ', 'Failed to create prompt.', data)

            setError('Something went wrong. Please try again later.')
          }

          setProcessing(false)
        })
        .catch((e) => {
          setProcessing(false)
        })
    }
  }

  const handleEdit = (e) => {
    e.preventDefault()

    setProcessing(true)

    const { error } = CreatePromptValidation({
      title,
      prompt,
      courseId,
      publishDate: publishDate.valueOf()
    })

    if (error) {
      console.log('[VALIDATION ERROR]: ', error)

      setError(error)
      setProcessing(false)
    } else {
      const fieldsToUpdate = { title, prompt, publishDate: publishDate.valueOf() }

      dispatch(updatePromptById({ promptId: promptToEdit._id, fieldsToUpdate }))
        .then((data) => {
          if (!data || data.error) {
            console.log('[FAIL]: ', 'Failed to edit prompt.', data)

            setError('Something went wrong. Please try again later.', JSON.stringify(data))
            setProcessing(false)
          } else {
            console.log('[SUCCESS]: ', 'Successfully updated prompt!')

            const updatedPrompt = data.data.prompt

            const index = prompts.findIndex((prompt) => prompt._id === data.data.prompt._id)

            setPrompts([...prompts.slice(0, index), updatedPrompt, ...prompts.slice(index + 1)])
          }

          handleClose()
        })
        .catch((e) => {
          setProcessing(false)
        })
    }
  }

  return (
    <MainContainer ref={mainRef}>
      <Prompt>
        <MessageIcon sx={{ color: 'common.white', marginRight: '0.5rem' }} />
        {isEditing ? <span>Edit Prompt</span> : <span>Create Prompt</span>}
      </Prompt>

      <FormContainer>
        <FormError message={error} />

        <AvatarContainer>
          <UserAvatar
            src={picture}
            width={'2.25rem'}
            height={'2.25rem'}
            borderWidth={'0.2rem'}
            verified={verified} // this will come from the profile
          />
          <TakeHeaderInnerContainer>
            <Name>{fname + ' ' + lname}</Name>
          </TakeHeaderInnerContainer>
        </AvatarContainer>
        <FormInputContainer>
          <InputContainer>
            <InputLabel
              id="title"
              color={'primary'}
              sx={{
                color: 'text.primary',
                fontSize: '1rem',
                fontWeight: 600,
                marginBottom: '0.5rem'
              }}>
              Title:
            </InputLabel>
            <MuiTextField
              id="title"
              // label="Title your Prompt"
              helperText="Make sure your prompt ties to the learning objectives of the course"
              variant="filled"
              name="title"
              value={title}
              onChange={handleTitleInputChange}
              color="secondary"
              className={classes.textInput}
              error={false}
            />
            <TextCount>{`${title.length} / 300`}</TextCount>
            <InputLabel
              id="textarea-prompt"
              color={'primary'}
              sx={{
                color: 'text.primary',
                fontSize: '1rem',
                fontWeight: 600,
                marginBottom: '0.5rem'
              }}>
              Create your Prompt:
            </InputLabel>
            <MuiTextField
              id="textarea-prompt"
              // label="Multiline"
              helperText="Remember to include link to reference materials and remind the students to use AI"
              multiline
              maxRows={20}
              variant="filled"
              name="prompt"
              value={prompt}
              onChange={handlePromptInputChange}
              color="secondary"
              className={classes.textInput}
              error={false}
            />
            <TextCount>{`${prompt.length} / 3000`}</TextCount>
            <PublishDatePicker publishDate={publishDate} setPublishDate={setPublishDate} />
          </InputContainer>
        </FormInputContainer>

        <ButtonsContainer>
          <Button
            variant="contained"
            color="secondary"
            className={classes.cancel}
            style={{ width: responsiveStyles.submitButton.width }}
            onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <>
            {isEditing ? (
              <Button
                variant="contained"
                color="secondary"
                className={classes.submitButton}
                style={{ width: responsiveStyles.submitButton.width }}
                disabled={processing || !isVerifiedUser(currentProfile)}
                onClick={handleEdit}>
                {!isVerifiedUser(currentProfile) && <span className="material-icons">lock</span>}
                {processing ? (
                  <CircularProgress color="secondary" className={classes.circularProgress} />
                ) : (
                  <span className={classes.submitText}>Submit Edit</span>
                )}
              </Button>
            ) : (
              <Button
                variant="contained"
                color="secondary"
                className={classes.submitButton}
                style={{ width: responsiveStyles.submitButton.width }}
                disabled={processing || !isVerifiedUser(currentProfile)}
                onClick={handleSubmit}>
                {!isVerifiedUser(currentProfile) && <span className="material-icons">lock</span>}
                {processing ? (
                  <CircularProgress color="secondary" className={classes.circularProgress} />
                ) : (
                  <span className={classes.submitText}>Create</span>
                )}
              </Button>
            )}
          </>
        </ButtonsContainer>
      </FormContainer>
    </MainContainer>
  )
}

export default PromptForm

// ***************************** //
// *** MUI Styled Components *** //
// ***************************** //
const MuiTextField = styled(TextField)(({ theme }) => ({
  background: theme.palette.background.gray,
  color: theme.palette.text.primary,
  minWidth: '18rem',
  // minWidth:
  '& label': {
    color: theme.palette.text.primary
  },
  '& label.Mui-focused': {
    color: theme.palette.secondary.main
  },
  '& .MuiInput-underline:after': {
    // borderBottomColor: '#B2BAC2'
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      // borderColor: '#E0E3E7'
    },
    '&:hover fieldset': {
      // borderColor: '#B2BAC2'
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.secondary.main
    }
  },
  '& .MuiFormHelperText-root': {
    color: theme.palette.text.primary,
    padding: '0.15rem'
  }
}))
