import React, { useState, useEffect } from 'react'
import { connect, useSelector } from 'react-redux'
import { updateProfileByUserID } from '../../../../../redux/thunks/profiles/updateProfileByUserID'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import { Switch } from '@mui/material'
import { InputContainer, InputLabel, InputSublabel, SwitchContainer } from './styled/privacy-comps'

const useStyles = makeStyles(() =>
  createStyles({
    switch: {
      marginLeft: '-.8rem'
    }
  })
)

const PrivacySettings = (props) => {
  const classes = useStyles()
  const { updateProfileByUserID } = props
  const currentProfile = useSelector((state) => state.currentProfile)
  const [processing, setProcessing] = useState({ showLocation: false })
  const [privacySettings, setPrivacySettings] = useState({
    showLocation: false
  })

  useEffect(() => {
    const tmpPrivacySettings = currentProfile.privacySettings || {}

    setPrivacySettings({ ...tmpPrivacySettings })
  }, [currentProfile])

  const handleUpdateProfileByUserID = (e) => {
    const { name, checked } = e.target

    setProcessing({ ...processing, [name]: true })

    updateProfileByUserID({
      fieldsToUpdate: {
        [`privacySettings.${name}`]: checked
      }
    })
      .then((data) => {
        if (!data.error) {
          console.log('[SUCCESS]: ', 'Successfully updated profile by user ID!')
        } else {
          console.log('[FAIL]: ', 'Failed to update profile by user ID.')
        }

        setProcessing({ ...processing, [name]: false })
      })
      .catch((e) => {
        setProcessing({ ...processing, [name]: false })
      })
  }

  return (
    <>
      <InputContainer style={{ marginTop: '0' }}>
        <InputLabel>Make Location Visible:</InputLabel>

        <InputSublabel>
          If enabled, your <b>city</b> and <b>state</b> will be publicly viewable on your profile
          page.
        </InputSublabel>

        <SwitchContainer>
          <Switch
            color="primary"
            checked={privacySettings.showLocation}
            onChange={handleUpdateProfileByUserID}
            name="showLocation"
            inputProps={{ 'aria-label': 'showLocation-checkbox' }}
            disabled={processing.showLocation}
            className={classes.switch}
          />
        </SwitchContainer>
      </InputContainer>
    </>
  )
}

const mapDispatchToProps = (dispatch) => ({
  updateProfileByUserID: (params) => {
    return dispatch(updateProfileByUserID(params))
  }
})

export default connect(null, mapDispatchToProps)(PrivacySettings)
