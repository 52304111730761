import React, { Component } from 'react'
import PropTypes from 'prop-types'
import withStyles from '@mui/styles/withStyles'
import { Skeleton } from '@mui/material'
import {
  MobileInterestContainer,
  ArticleContainer,
  ArticleHeader,
  RedArticleHeader
} from '../styled/org-details-comps'

const animation = 'pulse'

const styles = (theme) => ({
  profilePic: {
    borderTopLeftRadius: '.2rem',
    borderTopRightRadius: '.2rem'
  },
  label: {
    background: theme.palette.secondary.light,
    padding: '1rem',
    [theme.breakpoints.down('lg')]: {
      padding: '0.5rem'
    },
    borderRadius: '0.3rem',
    marginBottom: '1rem'
  },
  text: {
    margin: '.2rem 0'
  },
  hr: {
    margin: '1.3rem 0 1.5rem 0'
  }
})

class OrgDetailsSkeleton extends Component {
  render() {
    const { classes } = this.props

    return (
      <>
        <MobileInterestContainer>
          <ArticleHeader>Interests:</ArticleHeader>
          <Skeleton
            animation={animation}
            variant="rectangular"
            height={'3rem'}
            className={classes.label}
          />
        </MobileInterestContainer>
        <ArticleContainer></ArticleContainer>
        <ArticleHeader>Civility Rating:</ArticleHeader>
        <Skeleton
          animation={animation}
          variant="rectangular"
          height={'3rem'}
          className={classes.label}
        />

        <ArticleContainer>
          <ArticleHeader>About Me:</ArticleHeader>
          <RedArticleHeader>Core Beliefs</RedArticleHeader>
          <Skeleton
            animation={animation}
            variant="rectangular"
            height={'3rem'}
            className={classes.label}
          />

          <RedArticleHeader>Professional Experience</RedArticleHeader>
          <Skeleton
            animation={animation}
            variant="rectangular"
            height={'3rem'}
            className={classes.label}
          />

          <RedArticleHeader>Educational Experience</RedArticleHeader>
          <Skeleton
            animation={animation}
            variant="rectangular"
            height={'3rem'}
            className={classes.label}
          />
        </ArticleContainer>

        <ArticleContainer>
          <ArticleHeader>Connections:</ArticleHeader>
          <Skeleton
            animation={animation}
            variant="rectangular"
            height={'3rem'}
            className={classes.label}
          />
        </ArticleContainer>
      </>
    )
  }
}

OrgDetailsSkeleton.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(OrgDetailsSkeleton)
