import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { getProfileByUserID } from '../../../../../../redux/thunks/profiles/getProfileByUserID'
import moment from 'moment'
import getName from '../../../../../../utils/functions/getName'
import PostForm from '../../../../../partials/post-form/post-form'
import UserAvatar from '../../../../../partials/user-avatar/user-avatar'
import CollapsedContent from '../../../../../partials/collapsed-content/collapsed-content'
import useMediaQuery from '@mui/material/useMediaQuery'
import makeStyles from '@mui/styles/makeStyles'
import Button from '@mui/material/Button'
import Skeleton from '@mui/material/Skeleton'
import Dialog from '@mui/material/Dialog'
import CloseIcon from '@mui/icons-material/Close'
import ScheduleIcon from '@mui/icons-material/Schedule'
import DropdownMenu from '../components/dropdown-menu'

import {
  MainContainer,
  PaddedContainer,
  TakeHeaderContainer,
  AvatarContainer,
  TakeHeaderInnerContainer,
  Name,
  DateString,
  UserTake,
  HR,
  ActionsContainer,
  PostFormContainer
} from '../styled/prompts-comps'

const useStyles = makeStyles((theme) => ({
  closeIcon: {
    position: 'absolute',
    top: '0.35rem',
    right: '0.5rem'
  }
}))

function Prompt({
  prompt,
  prompts,
  setPrompts,
  responseFormView,
  contentRows,
  postDisplay,
  isInstructor
}) {
  const [profile, setProfile] = useState({})
  const [open, setOpen] = useState(false)
  const dispatch = useDispatch()
  const classes = useStyles()
  const navigate = useNavigate()
  const isMobile = useMediaQuery('(max-width: 799px)')
  const userName = getName(profile)
  const isPublished = prompt && prompt.publishDate < Date.now()
  const notPublishedBGColor = '#aaabab'

  useEffect(() => {
    if (prompt && prompt.userId) {
      dispatch(getProfileByUserID({ userId: prompt.userId })).then((data) => {
        if (data.error) {
          console.log(`[ERROR]: ${data.message}`)
        } else {
          console.log(`Successfully retrieved profile avatar`)
          setProfile(data.profile)
        }
      })
    }
  }, [])

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleVisitProfile = () => {
    navigate(`/profile/${profile.userId}`)
  }

  if (!isInstructor && !isPublished) {
    return null
  }

  return (
    <>
      <MainContainer
        style={postDisplay && { marginBottom: '0' }}
        bgColor={!isPublished && notPublishedBGColor}>
        <PaddedContainer>
          {isInstructor && (
            <DropdownMenu prompt={prompt} prompts={prompts} setPrompts={setPrompts} />
          )}

          <TakeHeaderContainer>
            <AvatarContainer onClick={() => handleVisitProfile()}>
              <UserAvatar
                src={profile.avatar}
                width={'2.3rem'}
                height={'2.3rem'}
                borderWidth={'0.2rem'}
                verified={profile.documentVerified ? 1 : 0}
              />
            </AvatarContainer>

            <TakeHeaderInnerContainer>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {profile && profile.fname ? (
                  <Name onClick={() => handleVisitProfile()}>{userName}</Name>
                ) : (
                  <Skeleton
                    variant="rectangular"
                    width={140}
                    height={19}
                    sx={{ bgcolor: 'secondary.light' }}
                  />
                )}
              </div>
              {isPublished ? (
                <DateString>
                  <ScheduleIcon style={{ fontSize: '1rem', marginRight: '0.25rem' }} />
                  Published: {moment(prompt.publishDate).fromNow()}
                </DateString>
              ) : (
                <DateString>
                  <ScheduleIcon style={{ fontSize: '1rem', marginRight: '0.25rem' }} />
                  This prompt will be published {moment(prompt.publishDate).fromNow()}
                </DateString>
              )}
            </TakeHeaderInnerContainer>
          </TakeHeaderContainer>
          <UserTake>
            <CollapsedContent
              color={'#333'}
              fontWeight={600}
              fontSize={'1rem'}
              lineHeight={'1.5rem'}
              linkify={false}
              content={prompt.title}
              rows={1}
              // viewsParams={!isHostedByUser && { takeId: take._id, userId: currentUser.id }}
            />
          </UserTake>
          <UserTake>
            <CollapsedContent
              color={'#333'}
              fontWeight={400}
              fontSize={'1rem'}
              lineHeight={'1.5rem'}
              linkify={true}
              content={prompt.prompt}
              rows={contentRows || null}
              // viewsParams={!isHostedByUser && { takeId: take._id, userId: currentUser.id }}
            />
          </UserTake>
          {!responseFormView && (
            <>
              <HR />

              <ActionsContainer>
                <Button
                  onClick={handleOpen}
                  variant="contained"
                  sx={{
                    backgroundColor: 'primary.main',
                    '&:hover': {
                      backgroundColor: 'primary.light'
                    }
                  }}
                  disabled={!isPublished}>
                  Respond
                </Button>
              </ActionsContainer>
            </>
          )}
        </PaddedContainer>
      </MainContainer>

      <Dialog open={open} onClose={handleClose} maxWidth={'xl'} fullScreen={isMobile}>
        <PostFormContainer>
          {isMobile && <CloseIcon onClick={handleClose} className={classes.closeIcon} />}
          <PostForm
            type="coursePrompt"
            setOpen={setOpen}
            prompt={prompt}
            promptCourseId={prompt.courseId}
          />
        </PostFormContainer>
      </Dialog>
    </>
  )
}

export default Prompt
