import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
// import { analytics } from "../../../firebase/firebase"
import { beginVerificationAttempt } from '../../../../redux/thunks/verification-attempts/beginVerificationAttempt'
import { verificationAttemptsActions } from '../../../../redux/action-creators/verification-attempts'
import copyVerificationAttempt from '../functions/copy-verification-attempt'
import ButtonContainer from './button-container/button-container'
import WarningBanner from '../../../warning-banner/warning-banner'
import StripeIcon from '../../../../media/icons/stripe/png/powered_by_stripe_blurple.png'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import { Button, CircularProgress } from '@mui/material'
import { Subheader, StripeImage, ButtonsContainer } from '../styled/verify-comps'

const useStyles = makeStyles((theme) =>
  createStyles({
    secondaryButton: {
      display: 'inline-block',
      width: '15rem',
      height: '3.5rem',
      marginRight: '2rem',
      borderRadius: '.3rem',
      fontSize: '0.9375rem', // 15px
      textTransform: 'capitalize',
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.cancelButton.light,
      '&:hover': {
        backgroundColor: theme.palette.cancelButton.dark
      },
      '@media (max-width: 650px)': {
        display: 'block',
        width: '16rem',
        height: '3rem',
        margin: '0 auto',
        fontSize: '0.875rem' // 14px
      },
      '@media (max-width: 400px)': {
        width: '100%'
      }
    },
    primaryButton: {
      display: 'inline-block',
      width: '15rem',
      height: '3.5rem',
      borderRadius: '.3rem',
      fontSize: '0.9375rem', // 15px
      textTransform: 'capitalize',
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.light
      },
      '@media (max-width: 650px)': {
        display: 'block',
        width: '16rem',
        height: '3rem',
        margin: '1rem auto 0 auto',
        fontSize: '0.875rem' // 14px
      },
      '@media (max-width: 400px)': {
        width: '100%'
      }
    },
    circularProgress: {
      display: 'block',
      margin: '.2rem auto 0 auto',
      width: '18px !important',
      height: '18px !important',
      '& svg': {
        color: theme.palette.text.primary
      }
    }
  })
)

function Start(props) {
  const {
    setStep,
    setVerificationAttempt,
    setVerificationAttemptId,
    lastError,
    setLastError,
    success,
    stripe
  } = props
  const [processing, setProcessing] = useState(false)
  const { documentVerified } = useSelector((state) => state.currentProfile)
  const classes = useStyles()
  const dispatch = useDispatch()

  const beginVerification = () => {
    console.log('Commencing Stripe Verification Process.')

    setProcessing(true)

    dispatch(beginVerificationAttempt()).then((data1) => {
      if (data1.error) {
        console.log('[FAIL]: Failed to begin verification attempt.')

        setProcessing(false)
      } else {
        console.log('[SUCCESS]: Successfully began verification attempt.')

        dispatch(verificationAttemptsActions.add([data1.verificationAttempt]))
        setVerificationAttemptId(data1.verificationAttempt._id)
        setVerificationAttempt({ ...copyVerificationAttempt(data1.verificationAttempt) })

        stripe.verifyIdentity(data1.stripeClientSecret).then(({ error }) => {
          if (error) {
            console.log('[ERROR]', error)

            if (error.code === 'consent_declined') {
              setLastError('The user declined to grant consent to collect and verify their data.')
            }

            setProcessing(false)
          } else {
            console.log('[SUCCESS]', 'Verification submitted!')
          }
        })
      }
    })
  }

  return (
    <>
      {lastError && (
        <WarningBanner
          type="alert"
          htmlBody={
            <p>
              <b>Verification Unsuccessful:</b> Your last verification failed for the following
              reason: <i>{lastError}</i> Please retry verifying at your earliest convenience. <br />
              Note: you will be charged an additional 2 tokens for each verification attempt.
            </p>
          }
          style={{ width: '85%', margin: '1.5rem auto 0 auto' }}
        />
      )}

      {success && (
        <WarningBanner
          type="confirmation"
          htmlBody={
            <p>
              <b>Verification Successful:</b> Congratulations, your identity been successfully
              verified. <u>Please Note:</u> you can set a nickname at any time to change your
              display name.
            </p>
          }
          style={{ width: '85%', margin: '1.5rem auto 0 auto' }}
        />
      )}

      {documentVerified ? (
        <Subheader>
          If you would like to update your <b>full name</b> on CivilTalk, we require you to validate
          your identity through our secure partnering service,{' '}
          <a href="https://stripe.com/identity" target="_blank" rel="noopener noreferrer">
            Stripe
          </a>
          .
        </Subheader>
      ) : (
        <Subheader>
          In order to ensure the integrity and protection of our community, we require all{' '}
          <b>members</b> to have validated their identity through our secure partnering service,{' '}
          <a href="https://stripe.com/identity" target="_blank" rel="noopener noreferrer">
            Stripe
          </a>
          .
        </Subheader>
      )}

      <Subheader>
        <b>Important:</b> Each verification attempt cost 2 tokens. If the verification attempt
        fails, you will still be charged. Each additional attempt also costs 2 tokens.
      </Subheader>

      <Subheader>
        Please have either your <span>passport</span>, <span>driver's license</span>, or{' '}
        <span>government-issued ID</span> handy.
      </Subheader>

      <div style={{ textAlign: 'center' }}>
        <a
          href={'https://www.stripe.com/identity'}
          target="_blank"
          rel="noreferrer"
          style={{ display: 'inline-block' }}>
          <StripeImage src={StripeIcon} alt="Powered by Stripe Icon" />
        </a>
      </div>

      <ButtonsContainer>
        {setStep && (
          <Button
            variant="contained"
            color="secondary"
            className={classes.secondaryButton}
            onClick={() => setStep && setStep(0)}>
            Back
          </Button>
        )}

        <ButtonContainer
          processing={processing}
          beginVerification={beginVerification}
          documentVerified={documentVerified}
        />
        {/** TODO:  add conditional here 
        <Button
          variant="contained"
          color="primary"
          className={classes.primaryButton}
          onClick={beginVerification}
          disabled={processing}>
          {processing ? (
            <CircularProgress color="primary" className={classes.circularProgress} />
          ) : documentVerified ? (
            'Update Verification'
          ) : (
            'Begin Verification'
          )}
        </Button>
        */}
      </ButtonsContainer>
    </>
  )
}

export default Start
