import config from '../../../../config.js'
import _ from 'lodash'
import Chain from '../../../../utils/classes/chain.js'

const loadGeneral = (
  isLoading,
  afterDate,
  isQuestion,
  excludeCurrentUser,
  tags,
  sortKey,
  filterKey,
  currentProfile,
  state,
  setState,
  actions
) => {
  const { loadLimits } = config

  const _getFollowing = () => {
    return actions.getFollowing()
  }

  // const _getFollowingOrNone = () => {
  //   if (filterKey === 'following') {
  //     return _getFollowing()
  //   }

  //   return new Promise((resolve) => resolve({}))
  // }

  const _getTakes = (params) => {
    return actions.getTakes(params)
  }

  const _getDiscussions = (takeIds) => {
    return actions.getDiscussions({
      takeIds,
      limit: loadLimits.discussions.perPost,
      isLoadingPage: isLoading
    })
  }

  const _getRebuttals = (debateIds) => {
    return actions.getRebuttals({
      debateIds,
      limit: loadLimits.rebuttals,
      isLoadingPage: isLoading
    })
  }

  const _getProfilesByUserIDs = (userIds) => {
    return actions.getProfilesByUserIDs({ userIds, isLoadingPage: isLoading })
  }

  const chain = new Chain(state)
  const profileAddress = currentProfile.personalAddress

  return _getFollowing()
    .then((data1) => {
      if (data1.error) {
        chain.break('Failed to get following.')

        return Promise.resolve()
      }

      // Grab the userIds from the following profiles
      if (filterKey === 'following') {
        const userIds = data1.follows.map((follow) => follow.userId)

        return _getTakes({
          afterDate,
          userIds,
          limit: loadLimits.posts,
          sortBy: sortKey,
          profileAddress,
          isQuestion,
          excludeCurrentUser,
          tags,
          isLoadingPage: isLoading
        })
      }

      // *** Main feed query ***
      return _getTakes({
        afterDate,
        limit: loadLimits.posts,
        sortBy: sortKey,
        filterKey,
        profileAddress,
        isQuestion,
        excludeCurrentUser,
        tags,
        isLoadingPage: isLoading
      })
    })
    .then((data2) => {
      if (chain.broken) return Promise.resolve()

      if (data2.error) {
        chain.break('Failed to retrieve takes.')

        return Promise.resolve()
      }

      if (data2.takes.length === 0) {
        chain.setState({
          ...chain.state,
          status: {
            ...chain.state.status,
            moreToLoad: false
          }
        })

        chain.break('No takes found.')

        return Promise.resolve()
      }

      chain.setState({
        ...chain.state,
        status: {
          ...chain.state.status,
          moreToLoad: data2.takes.length === loadLimits.posts
        }
      })

      const takeIds = []
      const userIds = []

      for (var i = 0; i < data2.takes.length; i++) {
        const { _id, userId } = data2.takes[i]

        // Grab take IDs from takes
        if (!_.includes(takeIds, _id)) {
          takeIds.push(_id)
        }

        // Grab user IDs from takes that are not current user
        if (!_.includes(userIds, userId) && userId !== currentProfile.userId) {
          userIds.push(userId)
        }
      }

      chain.setData('userIds', userIds)

      return _getDiscussions(takeIds)
    })
    .then((data3) => {
      if (chain.broken) return Promise.resolve()

      if (data3.error) {
        chain.break('Failed to retrieve discussions.')

        return Promise.resolve()
      }

      const userIds = [...chain.data.userIds]
      const discussionIds = []

      for (var j = 0; j < data3.discussions.length; j++) {
        const { _id, hostUserId, guestUserId } = data3.discussions[j]

        discussionIds.push(_id)

        // Grab take IDs from takes that are not current user
        if (guestUserId !== currentProfile.userId) {
          if (!_.includes(userIds, guestUserId)) {
            userIds.push(guestUserId)
          }
        }

        if (hostUserId !== currentProfile.userId) {
          if (!_.includes(userIds, hostUserId)) {
            userIds.push(hostUserId)
          }
        }
      }

      chain.setData('userIds', userIds)

      return _getRebuttals(discussionIds)
    })
    .then((data4) => {
      if (chain.broken) return Promise.resolve()

      if (data4.error) {
        chain.break('Failed to retrieve rebuttals.')

        return Promise.resolve()
      }

      return _getProfilesByUserIDs(chain.data.userIds)
    })
    .then((data5) => {
      if (chain.broken) return Promise.resolve()

      if (data5.error) {
        chain.break('Failed to retrieve profiles by user IDs.')

        return Promise.resolve()
      }

      chain.break('Successfully loaded feed.')

      return Promise.resolve()
    })
    .then((last_data) => {
      let { broken, log } = chain

      if (broken) {
        console.log('[DEBUG]: ', log)

        setState({
          ...chain.state,
          status: {
            ...chain.state.status,
            isLoading: false,
            isLoadingMore: false,
            sortBy: sortKey,
            filter: filterKey
          },
          tags
        })

        return Promise.resolve()
      }

      throw new Error('Unhandled promise chain logic.')
    })
    .catch((error) => {
      console.log('[ERROR]: ', error)
    })
}

export default loadGeneral
