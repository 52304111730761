import React, { Component } from 'react'
import { connect } from 'react-redux'
import { createUserFollow } from '../../../../redux/thunks/follows/createUserFollow'
import { deleteFollowByUserID } from '../../../../redux/thunks/follows/deleteFollowByUserID'
import getName from '../../../../utils/functions/getName'
import UserAvatar from '../../../partials/user-avatar/user-avatar'
import withStyles from '@mui/styles/withStyles'
import PropTypes from 'prop-types'
import { Button } from '@mui/material'
import SkeletonTrendingIssue from '../../../skeletons/skeleton-trending-issue'
import {
  Header,
  TabContainer,
  Tab,
  ResultContainer,
  AvatarContainer,
  ProfileInfoContainer,
  ProfileName,
  ProfileLocation,
  MutualConnection,
  ActionContainer,
  NoResults
} from './styled/center-comps'

const getFilteredFollows = (follows, organizationId) => {
  return follows.filter((follow) => follow.organizationId === organizationId)
}

const styles = (theme) => ({
  unfollow: {
    minWidth: '2rem',
    '& span': {
      fontSize: '1.3rem !important'
    },
    color: '#ffffff',
    backgroundColor: theme.palette.secondary.light,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main
    },
    borderRadius: '.2rem',
    cursor: 'pointer',
    '& > *': {
      pointerEvents: 'none'
    }
  },
  follow: {
    minWidth: '2rem',
    '& span': {
      fontSize: '1.3rem !important'
    },
    borderRadius: '.2rem',
    color: theme.palette.text.secondary,
    cursor: 'pointer',
    '&:hover': {},
    '& > *': {
      pointerEvents: 'none'
    }
  }
})

class InnerCenter extends Component {
  constructor(props) {
    super(props)

    this.state = {}

    this.handleTab = this.handleTab.bind(this)
    this.handleGoToProfile = this.handleGoToProfile.bind(this)
    this.handleGoToOrganization = this.handleGoToOrganization.bind(this)
    this.handleFollowUser = this.handleFollowUser.bind(this)
    this.handleUnfollowUser = this.handleUnfollowUser.bind(this)
  }

  handleTab(filter) {
    this.props.handleFilterSelect(filter)
  }

  handleGoToProfile(event) {
    this.props.navigate('/profile/' + event.target.id)
  }

  handleGoToOrganization(e) {
    this.props.navigate(`/organization/${e.target.id}`)
  }

  handleFollowUser(e) {
    const profile = this.props.loadedProfiles[e.target.id]

    if (typeof profile === 'undefined' || profile === null) {
      console.log('[FAIL]: ', 'Failed to find profile for createFollow.')
    } else {
      this.props
        .createUserFollow({
          userId: e.target.id,
          name: getName(profile)
        })
        .then((data) => {
          if (!data.error) {
            console.log('[SUCCESS]: ', 'Successfully followed user!')
          } else {
            console.log('[FAIL]: ', 'Failed to follow user.')
          }
        })
    }
  }

  handleUnfollowUser(e) {
    const profile = this.props.loadedProfiles[e.target.id]

    if (typeof profile === 'undefined' || profile === null) {
      console.log('[FAIL]: ', 'Failed to find profile for createFollow.')
    } else {
      this.props
        .deleteFollowByUserID({
          userId: e.target.id,
          currUserId: this.props.currentUser.id,
          name: getName(profile)
        })
        .then((data) => {
          if (!data.error) {
            console.log('[SUCCESS]: ', 'Successfully unfollowed user!')
          } else {
            console.log('[FAIL]: ', 'Failed to unfollow user.')
          }
        })
    }
  }

  render() {
    const { classes, currentUser, follows, loadedProfiles, organizations, state } = this.props
    const { isLoading } = state.status
    const { organizationId } = this.props.params
    const organization = organizations.find((org) => org._id === organizationId)
    const filteredFollows = getFilteredFollows(follows, organizationId)

    if (!organization) return null

    const renderedResults = filteredFollows.map((follow, i) => {
      const profile = loadedProfiles[follow.follower]

      if (!profile) return null

      const condStyles = {}
      const { avatar, userId, personalAddress, privacySettings, documentVerified } = profile
      const isCurrentUser = userId === currentUser.id
      const showLocation =
        personalAddress.city && personalAddress.state && privacySettings.showLocation
      const verified = documentVerified ? 1 : 0
      const isFollowing = _.some(follows, (follow) => {
        return follow.follower === currentUser.id && follow.userId === userId
      })

      if (i === 0) {
        condStyles.borderTopLeftRadius = '.3rem'
        condStyles.borderTopRightRadius = '.3rem'
      }

      if (i === filteredFollows.length - 1) {
        condStyles.borderBottomLeftRadius = '.3rem'
        condStyles.borderBottomRightRadius = '.3rem'
      }

      return (
        <ResultContainer key={userId} style={condStyles}>
          <AvatarContainer>
            <UserAvatar
              src={avatar}
              width={'3.05rem'}
              height={'3.05rem'}
              borderWidth={'0.2rem'}
              verified={verified}
            />
          </AvatarContainer>

          {!isCurrentUser && (
            <ActionContainer>
              {isFollowing ? (
                <Button
                  id={userId}
                  variant="contained"
                  color="primary"
                  className={classes.unfollow}
                  onClick={this.handleUnfollowUser}>
                  <span className="material-icons">person_remove</span>
                </Button>
              ) : (
                <Button
                  id={userId}
                  variant="contained"
                  color="primary"
                  className={classes.follow}
                  onClick={this.handleFollowUser}>
                  <span className="material-icons">person_add</span>
                </Button>
              )}
            </ActionContainer>
          )}

          <ProfileInfoContainer>
            <ProfileName id={userId} onClick={this.handleGoToProfile}>
              {getName(profile)}
            </ProfileName>

            {showLocation && (
              <ProfileLocation>
                {personalAddress.city + ', ' + personalAddress.state}
              </ProfileLocation>
            )}

            {isFollowing && currentUser.id !== this.props.params.userId && (
              <MutualConnection>Mutual Connection</MutualConnection>
            )}
          </ProfileInfoContainer>
        </ResultContainer>
      )
    })

    return (
      <div>
        <Header>Connections - {organization.publicName}</Header>

        <TabContainer>
          <Tab>Followers</Tab>
        </TabContainer>

        {!isLoading ? (
          <div>
            {renderedResults.length > 0 ? (
              renderedResults
            ) : (
              <NoResults>{'No connections to display...'}</NoResults>
            )}
          </div>
        ) : (
          <div>
            <SkeletonTrendingIssue count={3} />
          </div>
        )}
      </div>
    )
  }
}

// ******************** //
// Wrapping Class component in a function component to get access to useNavigate
// ******************** //
import { useNavigate } from 'react-router-dom'

function Center(props) {
  const navigate = useNavigate()

  return <InnerCenter {...props} navigate={navigate} />
}

InnerCenter.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
  currentUser: state.currentUser,
  follows: state.follows,
  loadedProfiles: state.loadedProfiles,
  organizations: state.organizations
})

const mapDispatchToProps = (dispatch) => ({
  createUserFollow: (params) => {
    return dispatch(createUserFollow(params))
  },
  deleteFollowByUserID: (params) => {
    return dispatch(deleteFollowByUserID(params))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Center))
