import React, { useState, useEffect, createRef, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { firebase } from '../../../../../firebase/firebase'
import { getProfilesByUserIDs } from '../../../../../redux/thunks/profiles/getProfilesByUserIDs'
import { getOrganizationsByIDs } from '../../../../../redux/thunks/organizations/getOrganizationsByIDs'
import loadNotificationsByType from '../functions/loadNotificationsByType'
import LoadMoreByType from '../functions/loadMoreNotificationsByType'
import Notification from './notification'
import { MenuList, MenuItem, CircularProgress } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import { NoNotificationsCopy } from '../styled/notifications-list-comps'

const useStyles = makeStyles((theme) =>
  createStyles({
    menuList: {
      paddingTop: 0
    },
    menuItem: {
      height: '5.5rem',
      padding: '1rem 0',
      borderBottom: '1px solid #eaeaea',
      backgroundColor: 'white',
      boxShadow: '-3px -1px 6px 3px rgba(100, 100, 100, 0.1)',
      cursor: 'default',
      '&:hover': {
        backgroundColor: 'white'
      }
    },
    centeredMenuItem: {
      justifyContent: 'center',
      height: '5.5rem',
      padding: '1rem 0',
      borderBottom: '1px solid #eaeaea',
      backgroundColor: 'white',
      boxShadow: '-3px -1px 6px 3px rgba(100, 100, 100, 0.1)',
      cursor: 'default',
      '&:hover': {
        backgroundColor: theme.palette.common.white
      }
    },
    progress: {
      textAlign: 'center',
      justifyContent: 'center'
    }
  })
)

function NotificationsList(props) {
  const { filter, handleCloseFromInnerComponent } = props
  const classes = useStyles()
  const dispatch = useDispatch()
  const [loadProfiles, setLoadProfiles] = useState(false)
  const [notifications, setNotifications] = useState([])
  const [startAfter, setStartAfter] = useState(Object)
  const [limit] = useState(10)
  const [loading, setLoading] = useState(false)

  const authRef = useSelector((state) => state.firebase.auth)
  const ref = firebase
    .firestore()
    .collection('userMeta')
    .doc(authRef.uid)
    .collection('notifications')

  useEffect(() => {
    setLoading(true)
    loadNotificationsByType(
      filter,
      ref,
      notifications,
      setNotifications,
      startAfter,
      setStartAfter,
      limit,
      setLoading
    )
  }, [filter])

  // Get the profiles from the notifications
  useEffect(() => {
    if (notifications.length > 0) {
      let userIds = []
      let organizationIds = []

      notifications.forEach((notification) => {
        if (notification.payload && notification.payload.userId) {
          userIds[notification.payload.userId] = notification.payload.userId
        }

        if (notification.payload && notification.payload.organizationId) {
          organizationIds[notification.payload.organizationId] = notification.payload.organizationId
        }
      })

      userIds = Object.keys(userIds)
      organizationIds = Object.keys(organizationIds)

      if (userIds.length > 0) {
        dispatch(
          getProfilesByUserIDs({
            userIds,
            isLoadingPage: false,
            isLoadingNotificationProfiles: true
          })
        )
      }

      if (organizationIds.length > 0) {
        dispatch(
          getOrganizationsByIDs({
            type: 'notifications',
            organizationIds,
            isLoadingPage: true,
            isLoadingNotificationOrganizations: true
          })
        )
      }

      setLoadProfiles(false)
    }
  }, [notifications, dispatch, loadProfiles])

  // *** Infinite Scroll Logic *** //
  const observer = createRef()
  const lastNotificationElementRef = useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          // console.log('LOADING MORE')
          setLoading(true)
          return LoadMoreByType(
            filter,
            ref,
            notifications,
            setNotifications,
            startAfter,
            setStartAfter,
            limit,
            setLoading
          )
        }
      })
      if (node) observer.current.observe(node)
    },
    [observer, filter, ref, notifications, startAfter, limit]
  )

  return (
    <>
      <MenuList id="notifications-dropdown" className={classes.menuList}>
        {typeof notifications !== 'undefined' && notifications.length > 0 ? (
          notifications.map((notification, index) => {
            if (index + 1 === notifications.length && (index + 1) % 10 === 0) {
              return (
                <Notification
                  key={notification.id}
                  notification={notification}
                  handleCloseFromInnerComponent={handleCloseFromInnerComponent}
                  ref={lastNotificationElementRef}
                />
              )
            } else {
              return (
                <Notification
                  key={notification.id}
                  notification={notification}
                  handleCloseFromInnerComponent={handleCloseFromInnerComponent}
                />
              )
            }
          })
        ) : (
          <MenuItem className={classes.centeredMenuItem}>
            {loading ? (
              <CircularProgress className={classes.progress} />
            ) : (
              <NoNotificationsCopy>No notifications</NoNotificationsCopy>
            )}
          </MenuItem>
        )}
      </MenuList>
    </>
  )
}

export default NotificationsList
