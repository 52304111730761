import { useNavigate } from 'react-router-dom'
import {
  ArticleContainer,
  RedArticleHeader,
  WhiteBackgroundContainer,
  TransparentInlineContainer,
  FollowContent,
  Label
} from '../styled/org-details-comps'

function Connections(props) {
  const { orgId, followers } = props
  const navigate = useNavigate()

  return (
    <ArticleContainer>
      <RedArticleHeader>Connections</RedArticleHeader>
      <WhiteBackgroundContainer>
        <TransparentInlineContainer>
          <FollowContent onClick={() => navigate(`/connections/organization/${orgId}`)}>
            {followers}
          </FollowContent>

          <Label style={{ margin: '0 auto', float: 'none' }}>Followers</Label>
        </TransparentInlineContainer>
      </WhiteBackgroundContainer>
    </ArticleContainer>
  )
}

export default Connections
