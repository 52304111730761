import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import PopularTags from '../../../../partials/popular-tags/popular-tags'
import RelatedTags from '../../../../partials/related-tags/related-tags'
import EmotionalIntelligenceEssentials from '../../../../partials/emotional-intelligence-essentials/emotional-intelligence-essentials'
import SuggestedConnections from '../../../../partials/suggested-connections/suggested-connections'
import SuggestedOrganizations from '../../../../partials/suggested-organizations/suggested-organizations'

import { MainContainer, HeadingPrimary } from './styled/discover-comps'

import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import { styled } from '@mui/material/styles'

const StyledTabList = styled((props) => <TabList {...props} />)(({ theme }) => ({}))

const StyledTab = styled((props) => <Tab {...props} />)(({ theme }) => ({
  background: theme.palette.secondary.light,
  borderRadius: '0.3rem',
  color: '#333333',
  fontWeight: 700,
  marginRight: '0.5rem',
  textTransform: 'none',
  '&:hover': {
    opacity: 0.5
  },
  '&.Mui-selected': {
    background: theme.palette.secondary.main,
    color: '#ffffff'
  },
  '@media (max-width: 400px)': {
    padding: '10px 14px'
  }
}))

const StyledTabPanel = styled((props) => <TabPanel {...props} />)(({ theme }) => ({
  borderRadius: '0.3rem',
  boxShadow: '0px 5px 5px -2px rgba(0, 0, 0, 0.2)',
  minHeight: '50vh',
  background: '#ffffff'
}))

function Discover() {
  const [value, setValue] = React.useState('1')
  const [isLoading, setIsLoading] = useState(true)
  const [randomInterest, setRandomInterest] = useState(null)
  const currentProfile = useSelector(({ currentProfile }) => currentProfile)
  const fullscreen = window.innerWidth <= 892

  useEffect(() => {
    const { tagsOfInterest } = currentProfile

    if (isLoading && tagsOfInterest) {
      setRandomInterest(tagsOfInterest[Math.floor(Math.random() * tagsOfInterest.length)] || null)
      setIsLoading(false)
    }
  }, [currentProfile, currentProfile.tagsOfInterest, isLoading])

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <MainContainer style={fullscreen ? { borderRadius: '0' } : {}}>
      {/* <TabContext value={value}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: '#ffffff',
            padding: '0.75rem 0 0 0 ',
            background: '#eaeaea'
          }}>
          <StyledTabList
            onChange={handleChange}
            aria-label="CivilTalk discover tab list"
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile>
            <StyledTab label="EI Essentials" value="1" />
            <StyledTab label="Popular Topics" value="2" />
            <StyledTab label="Related Interests" value="3" />
            <StyledTab label="Suggested Members" value="4" />
            <StyledTab label="Suggested Orgs" value="5" />
          </StyledTabList>
        </Box>
        <StyledTabPanel
          value="1"
          // sx={{
          //   borderRadius: '0.3rem',
          //   boxShadow: '0px 5px 5px -2px rgba(0, 0, 0, 0.2)',
          //   minHeight: '100vh',
          //   background: '#ffffff'
          // }}
        > */}
      <HeadingPrimary>
        Emotional Intelligence <span>essentials</span>:
      </HeadingPrimary>
      {!isLoading && (
        <>
          <EmotionalIntelligenceEssentials />

          <HeadingPrimary>
            <span>Trending</span> topics:
          </HeadingPrimary>
          <PopularTags days={30} limit={10} activateLinks={true} />

          <RelatedTags
            tagIds={randomInterest ? [randomInterest] : []}
            limit={5}
            hideReferenceTag={true}
            hideOnNoResults={true}
            customHeading={
              <HeadingPrimary>
                Discover topics related to your interest in{' '}
                <span style={{ whiteSpace: 'nowrap' }}>{randomInterest}</span>:
              </HeadingPrimary>
            }
            style={{
              background: 'none',
              padding: '0',
              boxShadow: 'none',
              border: 'none'
            }}
          />
        </>
      )}
      {/* </StyledTabPanel>
        <StyledTabPanel value="2">
          <HeadingPrimary>
            This month's <span>popular topics</span>:
          </HeadingPrimary>
          <PopularTags days={30} limit={10} activateLinks={true} style={{ marginTop: '.5rem' }} />
        </StyledTabPanel>
        <StyledTabPanel value="3">
          {!isLoading && (
            <RelatedTags
              tagIds={randomInterest ? [randomInterest] : []}
              limit={5}
              hideReferenceTag={true}
              hideOnNoResults={true}
              customHeading={
                <HeadingPrimary>
                  Discover topics related to your interest in{' '}
                  <span style={{ whiteSpace: 'nowrap' }}>{randomInterest}</span>:
                </HeadingPrimary>
              }
              style={{
                background: 'none',
                padding: '0',
                boxShadow: 'none',
                border: 'none'
              }}
            />
          )}
        </StyledTabPanel>
        <StyledTabPanel value="4">
          <SuggestedConnections allowRandomSuggestions={true} hideOnNoResults={true} />
        </StyledTabPanel>
        <StyledTabPanel value="5">
          <SuggestedOrganizations
            disableFromInsights={false}
            disableRandomSuggestions={false}
            disableFromInterests={false}
            hideOnNoResults={true}
          />
        </StyledTabPanel>
      </TabContext> */}
    </MainContainer>
  )
}

export default Discover
