import { useState, useEffect } from 'react' // TODO: useRef
import { useSelector, useDispatch } from 'react-redux'
import { analytics } from '../../../../../firebase/firebase'
import { checkFollowingUser } from '../../../../../redux/thunks/follows/checkFollowingUser'
import { createUserFollow } from '../../../../../redux/thunks/follows/createUserFollow'
import { deleteFollowByUserID } from '../../../../../redux/thunks/follows/deleteFollowByUserID'
import SkeletonProfileHeader from '../../../../skeletons/skeleton-profile-header'
import ProfileAvatar from './profile-avatar'
import ImageUpload from '../../../../partials/image-upload/image-upload'
import useMediaQuery from '@mui/material/useMediaQuery'
import ButtonGroup from '@mui/material/ButtonGroup'
import EditButton from './edit-button'
import FollowButton from './follow-button'
import ReportButton from './report-button'
import Tags from '../../../../partials/tags/tags'
import UserAccolades from '../../../../partials/user-accolades/user-accolades'
import getName from '../../../../../utils/functions/getName'

import {
  MainContainer,
  DetailsContainer,
  Name,
  Location,
  Knowledge,
  Interests,
  DropDownButtonContainer
} from './styled/header-comps'

function Header(props) {
  const { profile, isCurrentUser } = props
  const [open, setOpen] = useState(false)

  const [isLoading, setIsLoading] = useState(true)
  const currentUser = useSelector(({ currentUser }) => currentUser)
  // const currentProfile = useSelector(({ currentProfile }) => currentProfile)
  const follows = useSelector(({ follows }) => follows)
  const dispatch = useDispatch()
  const {
    userId,
    avatar,
    tagsOfKnowledge,
    tagsOfInterest,
    personalAddress,
    privacySettings,
    documentVerified
  } = profile
  const hasLocation = personalAddress.city && personalAddress.state
  const isFollowing = follows.some(
    (follow) => follow.follower === currentUser.id && follow.userId === userId
  )
  const matches = useMediaQuery('(min-width:500px)')

  const handleFollow = () => {
    dispatch(
      createUserFollow({
        userId: profile.userId,
        name: getName(profile)
      })
    ).then((data) => {
      if (!data.error) {
        console.log('[SUCCESS]: ', 'Successfully followed user!')
      } else {
        console.log('[FAIL]: ', 'Failed to follow user.')
      }
    })

    analytics.logEvent('toggle_follow')
  }

  const handleUnfollow = () => {
    dispatch(
      deleteFollowByUserID({
        userId: profile.userId,
        currUserId: currentUser.id,
        name: getName(profile)
      })
    ).then((data) => {
      if (!data.error) {
        console.log('[SUCCESS]: ', 'Successfully unfollowed user!')
      } else {
        console.log('[FAIL]: ', 'Failed to unfollow user.')
      }
    })
  }

  const handleOpenImageUpload = () => {
    setOpen(true)
  }

  const handleCloseImageUpload = () => {
    setOpen(false)
  }

  useEffect(() => {
    if (isLoading) {
      if (!isCurrentUser) {
        dispatch(checkFollowingUser({ userId }))
          .then((data) => {
            if (!data.error) {
              console.log('[SUCCESS]: ', 'Successfully checked if following user!')
            } else {
              console.log('[FAIL]: ', 'Failed to check if following user.')
            }

            setIsLoading(false)
          })
          .catch((e) => {
            setIsLoading(false)
          })
      } else {
        setIsLoading(false)
      }
    }
  }, [dispatch, isCurrentUser, isLoading, userId])

  if (!profile || Object.keys(profile).length === 0 || isLoading) {
    return (
      <MainContainer>
        <SkeletonProfileHeader />
      </MainContainer>
    )
  }

  return (
    <MainContainer>
      <ProfileAvatar
        avatar={avatar}
        isCurrentUser={isCurrentUser}
        isFollowing={isFollowing}
        handleFollow={handleFollow}
        handleUnfollow={handleUnfollow}
        handleOpen={handleOpenImageUpload}
        documentVerified={documentVerified}
      />

      <DetailsContainer>
        {isCurrentUser ? <Name>{getName(currentUser)}</Name> : <Name>{getName(profile)}</Name>}

        <Location>
          {hasLocation && privacySettings.showLocation && (
            <span>{`${personalAddress.city}, ${personalAddress.state}`}</span>
          )}
        </Location>

        <Knowledge>
          <b>Topics of Knowledge:</b>{' '}
          {tagsOfKnowledge.length > 0 ? (
            <Tags tagIds={tagsOfKnowledge} tagStyle={{ marginTop: '0', marginBottom: '.7rem' }} />
          ) : (
            <p>None Selected</p>
          )}
        </Knowledge>

        <Interests>
          <b>Topics of Interest:</b>{' '}
          {tagsOfInterest.length > 0 ? (
            <Tags tagIds={tagsOfInterest} tagStyle={{ marginTop: '0', marginBottom: '.7rem' }} />
          ) : (
            <p>None Selected</p>
          )}
        </Interests>

        {/* <UserAccolades userId={userId} style={{ marginTop: '.5rem' }} profile={profile} /> */}
      </DetailsContainer>

      <DropDownButtonContainer>
        {isCurrentUser ? (
          <EditButton />
        ) : (
          <>
            <ButtonGroup
              orientation={matches ? 'horizontal' : 'vertical'}
              sx={{ position: 'absolute', top: '1rem', right: '1rem' }}>
              <FollowButton userId={userId} profile={profile} />
              <ReportButton userId={userId} />
            </ButtonGroup>
          </>
        )}
      </DropDownButtonContainer>

      {isCurrentUser && (
        <ImageUpload
          open={open}
          handleOpen={handleOpenImageUpload}
          handleClose={handleCloseImageUpload}
        />
      )}
    </MainContainer>
  )
}

export default Header
