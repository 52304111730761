import { termsVersionsTypes } from '../action-creators/terms-versions'
const { SET_TERMS, CLEAR_TERMS } = termsVersionsTypes

const termsVersionsReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_TERMS:
      return {
        ...state,
        ...action.termsVersion
      }

    case CLEAR_TERMS:
      return initialState

    default:
      return state
  }
}

export default termsVersionsReducer
