import { currentUserAcceptedTermsTypes } from '../action-creators/current-user-accepted-terms'

const { SET_TERMS, UPDATE_TERMS, CLEAR_TERMS } = currentUserAcceptedTermsTypes

// const initialState = {
//   _id: 0,
//   userId: '',
//   acceptedVersion: 'v1',
//   acceptedAt: 0,
//   history: [],
//   whenCreated: 0,
//   whenModified: 0
// }

const currentUserAcceptedTermsReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_TERMS:
      if (!action.currentUserTerms) {
        return state
      }

      return {
        ...state,
        ...action.currentUserTerms
      }

    case UPDATE_TERMS:
      return {
        ...state,
        ...action.currentUserTerms
      }

    case CLEAR_TERMS:
      return initialState

    default:
      return state
  }
}

export default currentUserAcceptedTermsReducer
