import React, { Component } from 'react'
import { connect } from 'react-redux'
import ReactCodeInput from 'react-code-input'
import { sendEmailUpdateEmail } from '../../../../../../../redux/thunks/emails/sendEmailUpdateEmail'
import { updateEmail } from '../../../../../../../redux/thunks/users/updateEmail'
import sendEmailUpdateEmailValidation from './validations/send-email-update-email'
import updateEmailValidation from './validations/update-email'
import FormError from '../../../../../../../components/alerts/form-error'
import PropTypes from 'prop-types'
import withStyles from '@mui/styles/withStyles'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import theme from '../../../../../../../res/theme'
import './css/phone-input.css'
import {
  ItemContainer,
  InputLabel,
  CodeLabel,
  InputsContainer,
  Input
} from './styled/email-form-comps'
import config from '../../../../../../../config'

const numFields = 6

const styles = (theme) => ({
  actionButton: {
    textTransform: 'capitalize',
    width: '15rem',
    margin: '1rem 0 0 35%',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.light
    },
    [`@media (max-width: ${config.UI.drawer_breakpoint.max})`]: {
      width: '65%'
    },
    '@media (max-width: 600px)': {
      width: '100%',
      margin: '1rem 0 0 0'
    }
  },
  smallerActionButton: {
    width: '11rem',
    margin: '1rem 0 0 0',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.light
    },
    [`@media (max-width: 1100px)`]: {
      margin: '1rem 0 0 35%',
      width: '65%'
    },
    '@media (max-width: 600px)': {
      width: '100%',
      margin: '1rem 0 0 0'
    }
  },
  resendButton: {
    width: '11rem',
    margin: '1rem 1rem 0 35%',
    [`@media (max-width: 1100px)`]: {
      margin: '1rem 0 0 35%',
      width: '65%'
    },
    [`@media (max-width: ${config.UI.drawer_breakpoint.max})`]: {
      width: '65%'
    },
    '@media (max-width: 600px)': {
      width: '100%',
      margin: '1rem 0 0 0'
    }
  }
})

class EmailForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      fields: {
        email: '',
        code: ''
      },
      status: {
        isUpdatingEmail: false,
        didSendCode: false,
        didResendCode: false
      },
      open: false,
      error: ''
    }

    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleCodeInput = this.handleCodeInput.bind(this)
    this.handleBeginUpdateEmail = this.handleBeginUpdateEmail.bind(this)
    this.handleSendEmailUpdateEmail = this.handleSendEmailUpdateEmail.bind(this)
    this.handleUpdateEmail = this.handleUpdateEmail.bind(this)
    this.handleDialog = this.handleDialog.bind(this)
    this.handleCloseDialog = this.handleCloseDialog.bind(this)
  }

  componentDidMount() {
    const { currentUser } = this.props

    this.setState({
      ...this.state,
      fields: {
        ...this.state.fields,
        email: currentUser.email
      }
    })
  }

  handleInputChange(event) {
    const target = event.target
    const name = target.name

    this.setState({
      ...this.state,
      fields: {
        ...this.state.fields,
        [name]: target.value
      }
    })
  }

  handleCodeInput(value) {
    this.setState({
      ...this.state,
      fields: {
        ...this.state.fields,
        code: value
      },
      error: ''
    })
  }

  handleBeginUpdateEmail(event) {
    event.preventDefault()

    this.setState({
      ...this.state,
      fields: {
        ...this.state.fields,
        email: ''
      },
      status: {
        ...this.state.status,
        isUpdatingEmail: true
      }
    })
  }

  handleSendEmailUpdateEmail(resend) {
    const { currentUser } = this.props
    const { email } = this.state.fields
    const { error } = sendEmailUpdateEmailValidation({ email }, currentUser)

    if (error !== '') {
      console.log('[VALIDATION ERROR]: ', error)

      this.setState({
        ...this.state,
        error
      })
    } else {
      this.props.sendEmailUpdateEmail({ email }).then((data) => {
        if (!data.error) {
          console.log('[SUCCESS]: ', 'Successfully sent email update email!')

          this.setState({
            ...this.state,
            status: {
              ...this.state.status,
              didSendCode: true,
              didResendCode: resend
            },
            error: ''
          })
        } else {
          console.log('[FAIL]: ', 'Failed to send email update email.')

          this.setState({
            ...this.state,
            error: data.message
          })
        }
      })
    }
  }

  handleUpdateEmail(event) {
    event.preventDefault()

    const { email, code } = this.state.fields
    const { error } = updateEmailValidation({ code }, numFields)

    if (error !== '') {
      console.log('[VALIDATION ERROR]: ', error)
      this.setState({
        ...this.state,
        error
      })
    } else {
      this.props.updateEmail({ email, code }).then((data) => {
        if (!data.error) {
          console.log('[SUCCESS]: ', 'Successfully updated email!')
          this.setState({
            ...this.state,
            status: {
              ...this.state.status,
              isUpdatingEmail: false,
              didSendCode: false,
              didResendCode: false
            },
            error: ''
          })
        } else {
          console.log('[FAIL]: ', 'Failed to update email.')
          this.setState({
            ...this.state,
            error: data.message
          })
        }
      })
    }
  }

  handleDialog() {
    this.setState({
      ...this.state,
      open: true
    })
  }

  handleCloseDialog() {
    this.setState({
      ...this.state,
      open: false
    })
  }

  render() {
    const { classes } = this.props
    const { fields, status, error } = this.state

    return (
      <ItemContainer>
        <FormError message={error} />

        <InputLabel>Email:</InputLabel>

        {!status.isUpdatingEmail && (
          <>
            <InputsContainer>
              <Input
                type="email"
                name="email"
                placeholder="Email"
                value={fields.email}
                width="15rem"
                disabled={true}
              />
            </InputsContainer>

            <Button
              variant="contained"
              color="primary"
              className={classes.actionButton}
              // onClick={this.handleBeginUpdateEmail}
              onClick={this.handleDialog}>
              Update Email
            </Button>
          </>
        )}

        {status.isUpdatingEmail && !status.didSendCode && (
          <>
            <InputsContainer>
              <Input
                type="email"
                name="email"
                placeholder="Enter new email"
                value={fields.email}
                width="15rem"
                onChange={this.handleInputChange}
              />
            </InputsContainer>

            <Button
              variant="contained"
              color="primary"
              className={classes.actionButton}
              onClick={() => this.handleSendEmailUpdateEmail(false)}>
              Submit
            </Button>
          </>
        )}

        {status.isUpdatingEmail && status.didSendCode && (
          <>
            <InputsContainer>
              {status.didResendCode ? (
                <CodeLabel>
                  We resent a code to your email address. Please enter it below.
                </CodeLabel>
              ) : (
                <CodeLabel>Please enter the code we sent to your email address.</CodeLabel>
              )}

              <div style={{ marginTop: '1.3rem' }}>
                <ReactCodeInput
                  fields={numFields}
                  type="text"
                  inputStyle={{
                    'backgroundColor': 'rgba(0,0,0,0)',
                    'MozAppearance': 'textfield',
                    'borderRadius': '.3rem',
                    'border': '1px solid #d4d4d4',
                    'boxShadow': '0px 9px 7px -2px rgba(120, 120, 120, 0.1)',
                    'margin': '0 .3rem',
                    'paddingLeft': '.6rem',
                    'paddingBottom': '.1rem',
                    'width': '2.1rem',
                    'height': '2.1rem',
                    'fontFamily': theme.typography.fontFamily,
                    'fontSize': '1.4rem',
                    'fontWeight': theme.typography.fontWeight.regular,
                    'boxSizing': 'border-box',
                    'color': theme.typography.fontColor.bodyCopy
                  }}
                  onChange={this.handleCodeInput}
                />
              </div>
            </InputsContainer>

            <Button
              variant="contained"
              color="secondary"
              className={classes.resendButton}
              onClick={() => this.handleSendEmailUpdateEmail(true)}>
              Resend
            </Button>

            <Button
              variant="contained"
              color="primary"
              className={classes.smallerActionButton}
              onClick={this.handleUpdateEmail}>
              Submit
            </Button>
          </>
        )}

        <Dialog open={this.state.open} onClose={this.handleCloseDialog}>
          <DialogTitle>Please contact support@civiltalk.com</DialogTitle>
        </Dialog>
      </ItemContainer>
    )
  }
}

EmailForm.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
  currentUser: state.currentUser
})

const mapDispatchToProps = (dispatch) => ({
  sendEmailUpdateEmail: (params) => {
    return dispatch(sendEmailUpdateEmail(params))
  },
  updateEmail: (params) => {
    return dispatch(updateEmail(params))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(EmailForm))
