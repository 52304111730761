import config from '../../../config'
import { currentProfileActions } from '../../action-creators/current-profile.js'
import { actionResponseActions } from '../../action-creators/action-response.js'
import { loaderActions } from '../../action-creators/loader.js'
import { authenticatedFetch } from '../../../utils/functions/authenticatedFetch'

/*
 * METHOD TO UPDATE PROFILE TERMS BY USER ID
 */
export const updateProfileTermsByUserID = (params) => {
  let responseCode = ''
  const method = 'PUT'
  const url = `${config.baseURL}/api/profile/terms`
  const headers = {
    'Content-Type': 'application/json'
  }
  const requestBody = JSON.stringify(params.fieldsToUpdate)

  // Thunk middleware knows how to handle functions.
  // It passes the dispatch method as an argument to the function,
  // thus making it able to dispatch actions itself.
  return (dispatch) => {
    dispatch(loaderActions.startLoader())
    console.log('Sending update profile terms by ID request to CivilTalk')

    // Return the fetch so react components calling 'store.dispatch()' can use 'then()'
    return authenticatedFetch(url, {
      method,
      body: requestBody,
      headers,
      credentials: 'include'
    })
      .then((response) => {
        dispatch(loaderActions.stopLoader())
        responseCode = response.status

        return response.json()
      })
      .then((data) => {
        if (responseCode === 200) {
          dispatch(currentProfileActions.updateProfile(data.updatedFields))
          dispatch(
            actionResponseActions.createActionResponse('Successfully updated profile terms!')
          )

          return {
            error: false,
            code: responseCode,
            message: data.message
          }
        } else {
          console.log(data.message)

          return {
            error: true,
            code: responseCode,
            message: data.message
          }
        }
      })
      .catch((error) => {
        console.log(error)

        dispatch(loaderActions.stopLoader())

        return {
          error: true,
          code: '',
          message: error.message
        }
      })
  }
}
