import React, { Component } from 'react'
import { connect } from 'react-redux'
import PhoneInput from 'react-phone-input-2'
const ReactPhoneInput = PhoneInput.default ? PhoneInput.default : PhoneInput
import ReactCodeInput from 'react-code-input'
import { sendUpdateMobileVerificationCode } from '../../../../../../../redux/thunks/mobile/sendUpdateMobileVerificationCode'
import { updatePhone } from '../../../../../../../redux/thunks/users/updatePhone'
import sendUpdateMobileVerificationCodeValidation from './validations/send-update-mobile-verification-code'
import updatePhoneValidation from './validations/update-phone'
import FormError from '../../../../../../../components/alerts/form-error'
import PropTypes from 'prop-types'
import withStyles from '@mui/styles/withStyles'
import { FormControlLabel, Checkbox } from '@mui/material'
import Button from '@mui/material/Button'
import './css/phone-input.css'
import {
  ItemContainer,
  InputLabel,
  CodeLabel,
  InputsContainer,
  Input
} from './styled/phone-form-comps'
import { withTheme } from 'styled-components'
import config from '../../../../../../../config'

const numFields = 6

const styles = (theme) => ({
  actionButton: {
    textTransform: 'capitalize',
    width: '15rem',
    margin: '1rem 0 0 35%',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.light
    },
    [`@media (max-width: ${config.UI.drawer_breakpoint.max})`]: {
      width: '65%'
    },
    '@media (max-width: 600px)': {
      width: '100%',
      margin: '1rem 0 0 0'
    }
  },
  smallerActionButton: {
    width: '11rem',
    margin: '1rem 0 0 0',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.light
    },
    [`@media (max-width: 1100px)`]: {
      margin: '1rem 0 0 35%',
      width: '65%'
    },
    '@media (max-width: 600px)': {
      width: '100%',
      margin: '1rem 0 0 0'
    }
  },
  resendButton: {
    width: '11rem',
    margin: '1rem 1rem 0 35%',
    [`@media (max-width: 1100px)`]: {
      margin: '1rem 0 0 35%',
      width: '65%'
    },
    [`@media (max-width: ${config.UI.drawer_breakpoint.max})`]: {
      width: '65%'
    },
    '@media (max-width: 600px)': {
      width: '100%',
      margin: '1rem 0 0 0'
    }
  },
  formControl: {
    display: 'block',
    width: '35rem',
    marginTop: '.7rem',
    marginBottom: '-.7rem',
    '& > span': {
      marginTop: '.1rem',
      verticalAlign: 'middle',
      fontSize: '1rem',
      fontWeight: 300,
      color: theme.palette.text.primary
    }
  }
})

class PhoneForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      fields: {
        countryCode: 'US',
        phone: '+1',
        call: false
      },
      status: {
        isUpdatingPhone: false,
        didSendCode: false,
        didResendCode: false
      },
      error: ''
    }

    this.handleInputChange = this.handleInputChange.bind(this)
    this.handlePhoneInputChange = this.handlePhoneInputChange.bind(this)
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this)
    this.handleCodeInput = this.handleCodeInput.bind(this)
    this.handleBeginUpdatePhone = this.handleBeginUpdatePhone.bind(this)
    this.handleSendMobileVerificationCode = this.handleSendMobileVerificationCode.bind(this)
    this.handleUpdatePhone = this.handleUpdatePhone.bind(this)
  }

  componentDidMount() {
    const { currentUser } = this.props

    this.setState({
      ...this.state,
      fields: {
        ...this.state.fields,
        phone: currentUser.phone.phoneNumber
      }
    })
  }

  handleInputChange(event) {
    const target = event.target
    const name = target.name

    this.setState({
      ...this.state,
      fields: {
        ...this.state.fields,
        [name]: target.value
      }
    })
  }

  handlePhoneInputChange(phone, country) {
    this.setState({
      ...this.state,
      fields: {
        ...this.state.fields,
        countryCode: country.countryCode.toUpperCase(),
        phone
      }
    })
  }

  handleCheckboxChange() {
    this.setState({
      ...this.state,
      fields: {
        ...this.state.fields,
        call: !this.state.fields.call
      },
      error: ''
    })
  }

  handleCodeInput(value) {
    this.setState({
      ...this.state,
      fields: {
        ...this.state.fields,
        code: value
      },
      error: ''
    })
  }

  handleBeginUpdatePhone(event) {
    event.preventDefault()

    this.setState({
      ...this.state,
      fields: {
        ...this.state.fields,
        phone: '+1'
      },
      status: {
        ...this.state.status,
        isUpdatingPhone: true
      }
    })
  }

  handleSendMobileVerificationCode(resend) {
    const { currentUser } = this.props
    const { countryCode, phone, call } = this.state.fields

    const { error } = sendUpdateMobileVerificationCodeValidation({ phone }, currentUser)

    if (error !== '') {
      console.log('[VALIDATION ERROR]: ', error)

      this.setState({
        ...this.state,
        error
      })
    } else {
      this.props
        .sendUpdateMobileVerificationCode({
          countryCode,
          phone,
          channelType: 'sms'
          //channelType: call ? 'call' : 'sms'
        })
        .then((data) => {
          if (!data.error) {
            console.log('[SUCCESS]: ', 'Successfully sent update phone mobile verification code!')

            this.setState({
              ...this.state,
              status: {
                ...this.state.status,
                didSendCode: true,
                didResendCode: resend
              },
              error: ''
            })
          } else {
            console.log('[FAIL]: ', 'Failed to send phone update mobile verification code.')

            this.setState({
              ...this.state,
              error: data.message
            })
          }
        })
    }
  }

  handleUpdatePhone(event) {
    event.preventDefault()

    const { countryCode, phone, code } = this.state.fields
    const { error } = updatePhoneValidation({ code }, numFields)

    if (error !== '') {
      console.log('[VALIDATION ERROR]: ', error)
      this.setState({
        ...this.state,
        error
      })
    } else {
      this.props.updatePhone({ countryCode, phone, code }).then((data) => {
        if (!data.error) {
          console.log('[SUCCESS]: ', 'Successfully updated phone number!')

          this.setState({
            ...this.state,
            status: {
              ...this.state.status,
              isUpdatingPhone: false,
              didSendCode: false,
              didResendCode: false
            },
            error: ''
          })
        } else {
          console.log('[FAIL]: ', 'Failed to update phone number.')
          this.setState({
            ...this.state,
            error: data.message
          })
        }
      })
    }
  }

  render() {
    const { classes, theme } = this.props
    const { fields, status, error } = this.state

    const phoneStyles = {
      container: {
        color: theme.palette.text.primary,
        width: '16.2rem'
      },
      input: {
        color: theme.palette.text.primary,
        width: '100%',
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.fontSizes.regular,
        fontWeight: theme.typography.fontWeight.regular
      }
    }

    return (
      <ItemContainer>
        <FormError message={error} />

        <InputLabel>Phone:</InputLabel>

        {!status.isUpdatingPhone && (
          <>
            <InputsContainer>
              <Input
                type="tel"
                name="phone"
                placeholder="Phone"
                value={fields.phone}
                width="15rem"
                disabled={true}
              />
            </InputsContainer>

            <Button
              variant="contained"
              color="primary"
              className={classes.actionButton}
              onClick={this.handleBeginUpdatePhone}>
              Update Phone
            </Button>
          </>
        )}

        {status.isUpdatingPhone && !status.didSendCode && (
          <>
            <InputsContainer
              style={{
                display: 'inline-block',
                overflow: 'visible',
                width: '16.2rem'
              }}>
              <ReactPhoneInput
                containerStyle={phoneStyles.container}
                inputStyle={phoneStyles.input}
                country={fields.countryCode.toLowerCase()}
                value={fields.phone}
                onChange={(phone, country) => this.handlePhoneInputChange(phone, country)}
              />
            </InputsContainer>

            <Button
              variant="contained"
              color="primary"
              className={classes.actionButton}
              onClick={() => this.handleSendMobileVerificationCode(false)}>
              Submit
            </Button>
          </>
        )}

        {status.isUpdatingPhone && status.didSendCode && (
          <>
            <InputsContainer>
              {status.didResendCode ? (
                <CodeLabel>
                  We resent a code to your mobile device. Please enter it below.
                </CodeLabel>
              ) : (
                <CodeLabel>Please enter the code we sent to your mobile device.</CodeLabel>
              )}

              <div style={{ marginTop: '1.3rem' }}>
                <ReactCodeInput
                  fields={numFields}
                  type="text"
                  inputStyle={{
                    'backgroundColor': 'rgba(0,0,0,0)',
                    'MozAppearance': 'textfield',
                    'borderRadius': '.3rem',
                    'border': '1px solid #d4d4d4',
                    'boxShadow': '0px 9px 7px -2px rgba(120, 120, 120, 0.1)',
                    'margin': '0 .3rem',
                    'paddingLeft': '.6rem',
                    'paddingBottom': '.1rem',
                    'width': '2.1rem',
                    'height': '2.1rem',
                    'fontFamily': theme.typography.fontFamily,
                    'fontSize': '1.4rem',
                    'fontWeight': theme.typography.fontWeight.regular,
                    'boxSizing': 'border-box',
                    'color': theme.palette.text.primary
                  }}
                  onChange={this.handleCodeInput}
                />
              </div>
            </InputsContainer>

            <Button
              variant="contained"
              color="secondary"
              className={classes.resendButton}
              onClick={() => this.handleSendMobileVerificationCode(true)}>
              Resend
            </Button>

            <Button
              variant="contained"
              color="primary"
              className={classes.smallerActionButton}
              onClick={this.handleUpdatePhone}>
              Submit
            </Button>
          </>
        )}
      </ItemContainer>
    )
  }
}

PhoneForm.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
  currentUser: state.currentUser
})

const mapDispatchToProps = (dispatch) => ({
  sendUpdateMobileVerificationCode: (params) => {
    return dispatch(sendUpdateMobileVerificationCode(params))
  },
  updatePhone: (params) => {
    return dispatch(updatePhone(params))
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withTheme(PhoneForm)))

// <FormControlLabel
// className={classes.formControl}
//   control={
//     <Checkbox
//     checked={fields.call}
//       onClick={this.handleCheckboxChange}
//       name="call"
//       color="primary"
//       />
//     }
//     label="Call me with my verification code instead."
// />
