import React from 'react'
import { useSelector } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import Button from '@mui/material/Button'
import StripeIcon from '../../../../media/icons/stripe/png/powered_by_stripe_blurple.png'
import { SectionSubheader, StripeImageStart } from '../styled/add-payment-method-comps'

const useStyles = makeStyles((theme) =>
  createStyles({
    addPaymentMethodButton: {
      margin: '1rem 1rem 0 0',
      width: '15rem',
      fontSize: '1rem', // 16px
      textTransform: 'capitalize',
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.light
      }
    }
  })
)

function Start(props) {
  const classes = useStyles()
  const { setStep } = props
  const currentUser = useSelector(({ currentUser }) => currentUser)

  const handleBeginAddPaymentMethod = () => {
    console.log('Beginning add payment method...')

    setStep('card')
  }

  return (
    <div>
      {!currentUser.stripe.customerId && (
        <SectionSubheader>
          <b>IMPORTANT:</b>&nbsp;&nbsp; In order to gain access to the full CivilTalk experience and
          help support the growth of our community, we require all members to subscribe to the
          service through a payment plan of your choosing.
        </SectionSubheader>
      )}

      <Button
        variant="contained"
        color="primary"
        className={classes.addPaymentMethodButton}
        onClick={handleBeginAddPaymentMethod}>
        {currentUser.stripe.customerId ? 'Add Payment Method' : 'Pay and Subscribe'}
      </Button>

      <a href={'https://www.stripe.com'} target="_blank" rel="noopener noreferrer">
        <StripeImageStart
          src={StripeIcon}
          alt="Powered by Stripe Icon"
          style={{
            position: 'static',
            display: 'inline-block',
            height: '1.7rem',
            verticalAlign: 'middle',
            margin: '1rem 0 0 0'
          }}
        />
      </a>
    </div>
  )
}

export default Start
