import React, { Component } from 'react'
import { connect } from 'react-redux'
import getFilteredFollows from '../functions/get-filtered-follows'
import { createUserFollow } from '../../../../redux/thunks/follows/createUserFollow'
import { deleteFollowByUserID } from '../../../../redux/thunks/follows/deleteFollowByUserID'
import { createOrganizationFollow } from '../../../../redux/thunks/follows/createOrganizationFollow'
import { deleteFollowByOrganizationID } from '../../../../redux/thunks/follows/deleteFollowByOrganizationID'
// import getAvatar from '../../../../utils/functions/getAvatar'
import getName from '../../../../utils/functions/getName'
import UserAvatar from '../../../partials/user-avatar/user-avatar'
import getOrganizationImage from '../../../../utils/functions/getOrganizationImage'
import withStyles from '@mui/styles/withStyles'
import PropTypes from 'prop-types'
import { Button } from '@mui/material'
// import MenuIcon from '@mui/icons-material/MoreHoriz'
import SkeletonTrendingIssue from '../../../skeletons/skeleton-trending-issue'
import {
  Header,
  TabContainer,
  Tab,
  ResultContainer,
  // ProfilePicture,
  AvatarContainer,
  ProfileInfoContainer,
  ProfileName,
  ProfileLocation,
  MutualConnection,
  ActionContainer,
  NoResults
} from './styled/center-comps'

const styles = (theme) => ({
  unfollow: {
    minWidth: '2rem',
    '& span': {
      fontSize: '1.3rem !important'
    },
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.secondary.light,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main
    },
    borderRadius: '.2rem',
    cursor: 'pointer',
    '& > *': {
      pointerEvents: 'none'
    }
  },
  follow: {
    minWidth: '2rem',
    '& span': {
      fontSize: '1.3rem !important'
    },
    borderRadius: '.2rem',
    color: theme.palette.text.primary,
    cursor: 'pointer',
    '&:hover': {},
    '& > *': {
      pointerEvents: 'none'
    }
  }
})

class InnerCenter extends Component {
  constructor(props) {
    super(props)

    this.state = {}

    this.handleTab = this.handleTab.bind(this)
    this.handleGoToProfile = this.handleGoToProfile.bind(this)
    this.handleGoToOrganization = this.handleGoToOrganization.bind(this)
    this.handleFollowUser = this.handleFollowUser.bind(this)
    this.handleUnfollowUser = this.handleUnfollowUser.bind(this)
    this.handleFollowOrganization = this.handleFollowOrganization.bind(this)
    this.handleUnfollowOrganization = this.handleUnfollowOrganization.bind(this)
  }

  handleTab(filter) {
    this.props.handleFilterSelect(filter)
  }

  handleGoToProfile(userId) {
    this.props.navigate(`/profile/${userId}`)
  }

  handleGoToOrganization(e) {
    this.props.navigate(`/organization/${e.target.id}`)
  }

  handleFollowUser(e) {
    const profile = this.props.loadedProfiles[e.target.id]

    if (typeof profile === 'undefined' || profile === null) {
      console.log('[FAIL]: ', 'Failed to find profile for createFollow.')
    } else {
      this.props
        .createUserFollow({
          userId: e.target.id,
          name: getName(profile)
        })
        .then((data) => {
          if (!data.error) {
            console.log('[SUCCESS]: ', 'Successfully followed user!')
          } else {
            console.log('[FAIL]: ', 'Failed to follow user.')
          }
        })
    }
  }

  handleUnfollowUser(e) {
    const profile = this.props.loadedProfiles[e.target.id]

    if (typeof profile === 'undefined' || profile === null) {
      console.log('[FAIL]: ', 'Failed to find profile for createFollow.')
    } else {
      this.props
        .deleteFollowByUserID({
          userId: e.target.id,
          currUserId: this.props.currentUser.id,
          name: getName(profile)
        })
        .then((data) => {
          if (!data.error) {
            console.log('[SUCCESS]: ', 'Successfully unfollowed user!')
          } else {
            console.log('[FAIL]: ', 'Failed to unfollow user.')
          }
        })
    }
  }

  handleFollowOrganization(e) {
    const organization = this.props.organizations.find((org) => org._id === e.target.id)

    if (typeof organization === 'undefined' || organization === null) {
      console.log('[FAIL]: ', 'Failed to find organization for createOrganizationFollow.')
    } else {
      this.props
        .createOrganizationFollow({
          organizationId: e.target.id,
          name: organization.publicName
        })
        .then((data) => {
          if (!data.error) {
            console.log('[SUCCESS]: ', 'Successfully followed organization!')
          } else {
            console.log('[FAIL]: ', 'Failed to follow organization.')
          }
        })
    }
  }

  handleUnfollowOrganization(e) {
    const organization = this.props.organizations.find((org) => org._id === e.target.id)

    if (typeof organization === 'undefined' || organization === null) {
      console.log('[FAIL]: ', 'Failed to find organization for deleteFollowByOrganizationID.')
    } else {
      this.props
        .deleteFollowByOrganizationID({
          organizationId: e.target.id,
          currUserId: this.props.currentUser.id,
          name: organization.publicName
        })
        .then((data) => {
          if (!data.error) {
            console.log('[SUCCESS]: ', 'Successfully unfollowed organization!')
          } else {
            console.log('[FAIL]: ', 'Failed to unfollow organization.')
          }
        })
    }
  }

  render() {
    const { classes, currentUser, follows, loadedProfiles, organizations, state, params } =
      this.props
    const { isLoading, filter } = state.status
    console.log('params', params)
    const { userId } = params
    // const loadedProfile = loadedProfiles[userId]
    const filteredFollows = getFilteredFollows(follows, filter, userId)

    // if (typeof loadedProfile === 'undefined') {
    //   return null
    // }

    const renderedResults = filteredFollows.map((follow, i) => {
      if (follow.userId) {
        const profile = ((filter) => {
          switch (filter) {
            case 'followers':
              return loadedProfiles[follow.follower]
            case 'following':
              return loadedProfiles[follow.userId]
            default:
              return {}
          }
        })(filter)

        if (!profile) return null

        const condStyles = {}
        const { avatar, userId, personalAddress, privacySettings, documentVerified } = profile
        const isCurrentUser = userId === currentUser.id
        const showLocation =
          personalAddress.city && personalAddress.state && privacySettings.showLocation
        const verified = documentVerified ? 1 : 0
        const isFollowing = follows.some(
          (follow) => follow.follower === currentUser.id && follow.userId === userId
        )

        if (i === 0) {
          condStyles.borderTopLeftRadius = '.3rem'
          condStyles.borderTopRightRadius = '.3rem'
        }

        if (i === filteredFollows.length - 1) {
          condStyles.borderBottomLeftRadius = '.3rem'
          condStyles.borderBottomRightRadius = '.3rem'
        }

        return (
          <ResultContainer key={userId} style={condStyles}>
            <AvatarContainer onClick={() => this.handleGoToProfile(userId)}>
              <UserAvatar
                src={avatar}
                width={'3.05rem'}
                height={'3.05rem'}
                borderWidth={'0.2rem'}
                verified={verified}
              />
            </AvatarContainer>

            {!isCurrentUser && (
              <ActionContainer>
                {isFollowing ? (
                  <Button
                    id={userId}
                    variant="contained"
                    color="primary"
                    className={classes.unfollow}
                    onClick={this.handleUnfollowUser}>
                    <span className="material-icons">person_remove</span>
                  </Button>
                ) : (
                  <Button
                    id={userId}
                    variant="contained"
                    color="primary"
                    className={classes.follow}
                    onClick={this.handleFollowUser}>
                    <span className="material-icons">person_add</span>
                  </Button>
                )}
              </ActionContainer>
            )}

            <ProfileInfoContainer>
              <ProfileName onClick={() => this.handleGoToProfile(userId)}>
                {getName(profile)}
              </ProfileName>

              {showLocation && (
                <ProfileLocation>
                  {personalAddress.city + ', ' + personalAddress.state}
                </ProfileLocation>
              )}

              {isFollowing && currentUser.id !== params.userId && (
                <MutualConnection>Mutual Connection</MutualConnection>
              )}
            </ProfileInfoContainer>
          </ResultContainer>
        )
      } else if (follow.organizationId) {
        const organization = organizations.find((org) => org._id === follow.organizationId)

        if (!organization) return null

        const condStyles = {}
        const { _id, image, publicName, address } = organization
        const { cityName, state } = address
        const isFollowing = follows.some(
          (follow) => follow.follower === currentUser.id && follow.organizationId === _id
        )

        if (i === 0) {
          condStyles.borderTopLeftRadius = '.3rem'
          condStyles.borderTopRightRadius = '.3rem'
        }

        if (i === filteredFollows.length - 1) {
          condStyles.borderBottomLeftRadius = '.3rem'
          condStyles.borderBottomRightRadius = '.3rem'
        }

        return (
          <ResultContainer key={_id} style={condStyles}>
            <AvatarContainer>
              <UserAvatar
                src={getOrganizationImage(image)}
                width={'3.05rem'}
                height={'3.05rem'}
                borderWidth={'0.2rem'}
              />
            </AvatarContainer>

            <ActionContainer>
              {isFollowing ? (
                <Button
                  id={_id}
                  variant="contained"
                  color="primary"
                  className={classes.unfollow}
                  onClick={this.handleUnfollowOrganization}>
                  <span className="material-icons">person_remove</span>
                </Button>
              ) : (
                <Button
                  id={_id}
                  variant="contained"
                  color="primary"
                  className={classes.follow}
                  onClick={this.handleFollowOrganization}>
                  <span className="material-icons">person_add</span>
                </Button>
              )}
            </ActionContainer>

            <ProfileInfoContainer>
              <ProfileName id={_id} onClick={this.handleGoToOrganization}>
                {publicName}
              </ProfileName>

              {cityName && state && <ProfileLocation>{cityName + ', ' + state}</ProfileLocation>}

              {isFollowing && currentUser.id !== params.userId && (
                <MutualConnection>Mutual Connection</MutualConnection>
              )}
            </ProfileInfoContainer>
          </ResultContainer>
        )
      } else {
        return null
      }
    })

    return (
      <div>
        <Header>Connections</Header>

        <TabContainer>
          <Tab onClick={() => this.handleTab('followers')} selected={filter === 'followers'}>
            Followers
          </Tab>

          <Tab onClick={() => this.handleTab('following')} selected={filter === 'following'}>
            Following
          </Tab>
        </TabContainer>

        {!isLoading ? (
          <div>
            {renderedResults.length > 0 ? (
              renderedResults
            ) : (
              <NoResults>{'No connections to display...'}</NoResults>
            )}
          </div>
        ) : (
          <div>
            <SkeletonTrendingIssue count={3} />
          </div>
        )}
      </div>
    )
  }
}

// ******************** //
// Wrapping Class component in a function component to get access to useNavigate
// ******************** //
import { useNavigate } from 'react-router-dom'

function Center(props) {
  const navigate = useNavigate()

  return <InnerCenter {...props} navigate={navigate} />
}

InnerCenter.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
  currentUser: state.currentUser,
  follows: state.follows,
  loadedProfiles: state.loadedProfiles,
  organizations: state.organizations
})

const mapDispatchToProps = (dispatch) => ({
  createUserFollow: (params) => {
    return dispatch(createUserFollow(params))
  },
  deleteFollowByUserID: (params) => {
    return dispatch(deleteFollowByUserID(params))
  },
  createOrganizationFollow: (params) => {
    return dispatch(createOrganizationFollow(params))
  },
  deleteFollowByOrganizationID: (params) => {
    return dispatch(deleteFollowByOrganizationID(params))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Center))
