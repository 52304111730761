import { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import PhoneInput from 'react-phone-input-2'
const ReactPhoneInput = PhoneInput.default ? PhoneInput.default : PhoneInput
import { updatePhone } from '../../../../../../../redux/thunks/organizations/updatePhone'
import updatePhoneValidation from './validations/update-phone'
import FormError from '../../../../../../../components/alerts/form-error'
import PropTypes from 'prop-types'
import withStyles from '@mui/styles/withStyles'
import Button from '@mui/material/Button'
import theme from '../../../../../../../res/theme'
import './css/phone-input.css'
import { ItemContainer, InputLabel, InputsContainer } from './styled/phone-form-comps'
import { ThemeContext } from 'styled-components'
import config from '../../../../../../../config'

const styles = (theme) => ({
  actionButton: {
    textTransform: 'capitalize',
    width: '15rem',
    margin: '1rem 0 0 35%',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.light
    },
    [`@media (max-width: ${config.UI.drawer_breakpoint.max})`]: {
      width: '65%'
    },
    '@media (max-width: 600px)': {
      width: '100%',
      margin: '1rem 0 0 0'
    }
  }
})

const PhoneForm = (props) => {
  const { classes } = props
  const themeContext = useContext(ThemeContext)
  const { orgId } = useParams()
  const [processing, setProcessing] = useState(false)
  const [countryCode, setCountryCode] = useState('US')
  const [phone, setPhone] = useState('+1')
  const [error, setError] = useState('')
  const organizations = useSelector(({ organizations }) => organizations)
  const dispatch = useDispatch()
  const organization = _.find(organizations, { _id: orgId })

  useEffect(() => {
    if (organization) {
      setPhone(organization.phone.phoneNumber)
    }
  }, [organization])

  const handlePhoneInputChange = (phone, country) => {
    setCountryCode(country.countryCode.toUpperCase())
    setPhone(phone)
  }

  const handleUpdatePhone = (event) => {
    event.preventDefault()

    setProcessing(true)

    const { error } = updatePhoneValidation(phone, organization)

    if (error !== '') {
      console.log('[VALIDATION ERROR]: ', error)

      setError(error)
      setProcessing(false)
    } else {
      dispatch(updatePhone({ phone, organizationId: orgId })).then((data) => {
        if (!data.error) {
          console.log('[SUCCESS]: ', 'Successfully updated phone number!')

          setError('')
        } else {
          console.log('[FAIL]: ', 'Failed to update phone number.')

          setError(data.message)
        }

        setProcessing(false)
      })
    }
  }

  const phoneStyles = {
    container: {
      color: themeContext.palette.text.primary,
      width: '15rem'
    },
    input: {
      color: themeContext.palette.text.primary,
      width: '100%',
      fontFamily: themeContext.typography.fontFamily,
      fontSize: themeContext.typography.fontSizes.regular,
      fontWeight: themeContext.typography.fontWeight.regular
    }
  }

  if (!organization) return null

  return (
    <ItemContainer>
      <FormError message={error} />

      <InputLabel>Phone:</InputLabel>
      <>
        <InputsContainer>
          <ReactPhoneInput
            containerStyle={phoneStyles.container}
            inputStyle={phoneStyles.input}
            country={countryCode.toLowerCase()}
            value={phone}
            onChange={(phone, country) => handlePhoneInputChange(phone, country)}
          />
        </InputsContainer>

        <Button
          variant="contained"
          color="primary"
          className={classes.actionButton}
          onClick={handleUpdatePhone}
          disabled={phone.trim() === organization?.phone?.phoneNumber || processing}>
          Submit
        </Button>
      </>
    </ItemContainer>
  )
}

PhoneForm.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(PhoneForm)
