import React from 'react'
import { useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import getAvatar from '../../../utils/functions/getAvatar'
import LazyLoad from 'react-lazyload'
import { UserAvatarContainer, Image, Placeholder } from './styled/user-avatar-comps'

function UserAvatar(props) {
  const { src, link, width, height, borderWidth, verified } = props
  const navigate = useNavigate()
  const refPlaceholder = useRef()

  const removePlaceholder = () => {
    refPlaceholder.current.remove()
  }

  const handleVisitProfile = () => {
    if (link) {
      navigate(link)
    }
  }

  return (
    <UserAvatarContainer>
      <Placeholder ref={refPlaceholder} />
      <LazyLoad>
        <Image
          onLoad={removePlaceholder}
          onError={removePlaceholder}
          src={getAvatar(src)}
          alt={'profile image'}
          width={width}
          height={height}
          borderWidth={borderWidth}
          verified={verified}
          onClick={handleVisitProfile}
        />
      </LazyLoad>
    </UserAvatarContainer>
  )
}

export default UserAvatar
