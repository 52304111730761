import { useState, useEffect, useRef, useContext, useMemo, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import config from '../../../config'
import checkOrgPermissions from '../../../utils/functions/checkPermissions'
import LoadOrganizationAssignedRoles from '../../data-loading/load-organization-assigned-roles/load-organization-assigned-roles'
import LoadOrganizationStripeAccount from '../../data-loading/load-organization-stripe-account/load-organization-stripe-account'
import LoadOrganizations from '../../data-loading/load-organizations/load-organizations'
import SideNavDesktop from './components/side-nav/side-nav-desktop'
import SideNavMobile from './components/side-nav/side-nav-mobile'
import AccountDetails from './components/account-details/account-details'
import ProfilePicture from './components/profile-picture/profile-picture'
import OrganizationDetails from './components/organization-details/organization-details'
import LocationForm from './components/location/location-form'
import RoleManagement from './components/role-management/role-management'
import Billing from './components/billing/billing'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import { IconButton } from '@mui/material'
import {
  MainContainer,
  SectionContainer,
  SectionHeader,
  SectionContentContainer
} from './styled/my-account-comps'
import LoadOrganizationRolesContext from '../../data-loading/load-organization-assigned-roles/context/context'

// Add constants for sections and content headers
const SECTIONS = ['account', 'picture', 'details', 'location', 'roles', 'billing']

const CONTENT_HEADERS = {
  account: 'Account Details',
  picture: 'Profile Picture',
  details: 'Organization Details',
  location: 'Location Settings',
  roles: 'Role Management',
  billing: 'Billing & Donations'
}

const useStyles = makeStyles(() =>
  createStyles({
    iconButton: {
      backgroundColor: 'rgba(0,0,0,.1)',
      padding: '.5rem',
      color: 'white',
      borderRadius: '.5rem',
      position: 'absolute',
      top: '50%',
      left: '.5rem',
      transform: 'translateY(-50%)',
      '& span.material-icons': {
        fontSize: '1.8rem'
      },
      '&:hover': {
        backgroundColor: 'rgba(0,0,0,.2)'
      }
    }
  })
)

function OrganizationAccountContent() {
  const { orgId, tab } = useParams()
  const navigate = useNavigate()
  const { roles, assignedRoles } = useContext(LoadOrganizationRolesContext)
  const classes = useStyles()
  const [sideNavMobileOpen, setSideNavMobileOpen] = useState(false)
  const [backButton, setBackButton] = useState(false)
  const currentUser = useSelector(({ currentUser }) => currentUser)

  console.log('Current tab:', tab)
  console.log('Current orgId:', orgId)

  const userHasPermission = useCallback(
    (requiredPermissions = []) => {
      const hasPermission = checkOrgPermissions({
        assignedRoles,
        roles,
        currUserId: currentUser.id,
        permissions: requiredPermissions
      })

      console.log('Permission Check:', {
        tab,
        requiredPermissions,
        hasPermission,
        currentUser: currentUser.id,
        assignedRoles,
        roles
      })

      return hasPermission
    },
    [assignedRoles, roles, currentUser.id, tab]
  )

  // Memoize fullscreen calculation
  const fullscreen = useMemo(() => {
    return window.innerWidth <= parseInt(config.UI.drawer_breakpoint.max.split('px')[0])
  }, [])

  // Update window resize handler
  const handleWindowResize = useCallback(() => {
    const newFullscreen =
      window.innerWidth <= parseInt(config.UI.drawer_breakpoint.max.split('px')[0])
    setBackButton(newFullscreen)

    if (tab) {
      setSideNavMobileOpen(false)
    } else {
      if (newFullscreen) {
        setSideNavMobileOpen(true)
      } else {
        navigate(`/organization/${orgId}/settings/account`)
      }
    }
  }, [navigate, orgId, tab])

  // Memoize content header getter
  const getContentHeader = useCallback(() => {
    return CONTENT_HEADERS[tab] || 'Account Details'
  }, [tab])

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize)
    return () => window.removeEventListener('resize', handleWindowResize)
  }, [handleWindowResize])

  useEffect(() => {
    if (tab) {
      if (!SECTIONS.includes(tab)) {
        navigate('/page-not-found')
      }
    } else {
      if (fullscreen) {
        setSideNavMobileOpen(true)
      } else {
        navigate(`/organization/${orgId}/settings/account`)
      }
    }
  }, [fullscreen, navigate, orgId, tab])

  const hasProperPermissions = useCallback(() => {
    switch (tab) {
      case 'account':
        return userHasPermission([]) // Admin only
      case 'picture':
      case 'details':
      case 'location':
        return userHasPermission(['manageOrganizationDetails'])
      case 'roles':
        return userHasPermission([]) // Admin Only
      case 'billing':
        return userHasPermission(['manageBilling'])
      default:
        return userHasPermission([]) // Admin Only
    }
  }, [tab, userHasPermission])

  useEffect(() => {
    if (!hasProperPermissions()) {
      console.log('No proper permissions, redirecting to page not found')
      navigate('/page-not-found')
    }
  }, [hasProperPermissions, navigate])

  if (!hasProperPermissions()) {
    console.log('No proper permissions, returning null')
    return null
  }

  const renderContent = () => {
    console.log('Rendering content for tab:', tab)
    let content = null

    switch (tab) {
      case 'account':
        content = userHasPermission([]) ? <AccountDetails /> : null
        break
      case 'picture':
        content = userHasPermission(['manageOrganizationDetails']) ? <ProfilePicture /> : null
        break
      case 'details':
        console.log('About to render OrganizationDetails')
        if (userHasPermission(['manageOrganizationDetails'])) {
          console.log('Creating OrganizationDetails component')
          content = <OrganizationDetails />
          console.log('OrganizationDetails component created:', content)
        } else {
          console.log('No permission for OrganizationDetails')
        }
        break
      case 'location':
        content = userHasPermission(['manageOrganizationDetails']) ? <LocationForm /> : null
        break
      case 'roles':
        content = userHasPermission([]) ? <RoleManagement /> : null
        break
      case 'billing':
        content = userHasPermission(['manageBilling']) ? (
          <LoadOrganizationStripeAccount organizationId={orgId}>
            <Billing />
          </LoadOrganizationStripeAccount>
        ) : null
        break
      default:
        content = userHasPermission([]) ? <AccountDetails /> : null
    }

    console.log('Final content to render:', content)
    return content
  }

  return (
    <MainContainer>
      <SideNavMobile open={sideNavMobileOpen} setOpen={setSideNavMobileOpen} />
      <SideNavDesktop />

      <SectionContainer>
        <SectionHeader>
          {(backButton || fullscreen) && (
            <IconButton
              className={classes.iconButton}
              onClick={() => setSideNavMobileOpen((prev) => !prev)}
              size="large">
              <span className="material-icons">chevron_left</span>
            </IconButton>
          )}

          {getContentHeader()}
        </SectionHeader>

        <SectionContentContainer>
          {(() => {
            console.log('SectionContentContainer rendering')
            const content = renderContent()
            console.log('Content before render:', content)
            if (!content) {
              console.log('No content to render')
            }
            return content
          })()}
        </SectionContentContainer>
      </SectionContainer>
    </MainContainer>
  )
}

function OrganizationAccount() {
  const { orgId } = useParams()

  return (
    <LoadOrganizationAssignedRoles organizationId={orgId} disableRedux={true}>
      <LoadOrganizations organizationIds={[orgId]} enableAsync={true}>
        <OrganizationAccountContent />
      </LoadOrganizations>
    </LoadOrganizationAssignedRoles>
  )
}

export default OrganizationAccount
