import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import find from 'lodash/find'
import { updateOrganizationByID } from '../../../../../../../redux/thunks/organizations/updateOrganizationByID'
import UpdateWebsiteValidation from './validations/update-website'
import FormError from '../../../../../../alerts/form-error'
import makeStyles from '@mui/styles/makeStyles'
import Button from '@mui/material/Button'
import { ItemContainer, InputLabel, InputsContainer, Input } from './styled/website-form-comps'
import config from '../../../../../../../config'

const useStyles = makeStyles((theme) => ({
  actionButton: {
    textTransform: 'capitalize',
    width: '15rem',
    margin: '1rem 0 0 35%',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.light
    },
    [`@media (max-width: ${config.UI.drawer_breakpoint.max})`]: {
      width: '65%'
    },
    '@media (max-width: 600px)': {
      width: '100%',
      margin: '1rem 0 0 0'
    }
  }
}))

function WebsiteForm() {
  const classes = useStyles()
  const { orgId } = useParams()
  const organizations = useSelector(({ organizations }) => organizations)
  const organization = find(organizations, { _id: orgId })
  const dispatch = useDispatch()
  const [website, setWebsite] = useState('')
  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState('')

  useEffect(() => {
    if (organization) {
      setWebsite(organization.website)
    }
  }, [organization])

  const handleInputChange = (e) => {
    setWebsite(e.target.value)
  }

  const handleUpdateWebsite = () => {
    setProcessing(true)

    const { error } = UpdateWebsiteValidation(website)

    if (error) {
      setError(error)
      setProcessing(false)
    } else {
      dispatch(
        updateOrganizationByID({
          fieldsToUpdate: {
            website
          },
          organizationId: orgId
        })
      ).then((data) => {
        if (!data.error) {
          console.log('[SUCCESS]: ', 'Successfully updated website!')

          setProcessing(false)
        } else {
          console.log('[FAIL]: ', 'Failed to update website.')

          setProcessing(false)
        }
      })
    }
  }

  if (!organization) return null

  return (
    <div>
      <FormError message={error} />

      <ItemContainer>
        <InputLabel>Website:</InputLabel>

        <InputsContainer>
          <Input
            type="text"
            name="website"
            placeholder="Enter a website"
            value={website}
            width="15rem"
            onChange={handleInputChange}
          />
        </InputsContainer>

        <Button
          variant="contained"
          color="primary"
          className={classes.actionButton}
          onClick={handleUpdateWebsite}
          disabled={website.trim() === organization?.website || processing}>
          Submit
        </Button>
      </ItemContainer>
    </div>
  )
}

export default WebsiteForm
