import React from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import isVerifiedUser from '../../../../utils/functions/isVerifiedUser'
import { IconButton, Tooltip, useMediaQuery } from '@mui/material'
import MuiProfileIcon from '@mui/icons-material/AccountCircle'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  icon: {
    fontSize: '1.5rem',
    color: theme.palette.text.primary
  },
  iconButton: {},
  iconButtonActive: {
    backgroundColor: theme.palette.common.white
  },
  displayNone: {
    display: 'none'
  }
}))

function ProfileIcon() {
  const classes = useStyles()
  const isMobile = useMediaQuery('(max-width: 499px)')
  const currentProfile = useSelector(({ currentProfile }) => currentProfile)
  const currentUser = useSelector(({ currentUser }) => currentUser)
  const location = useLocation()
  const navigate = useNavigate()
  const { hasPerformedSetup } = currentProfile
  const profilePath = location.pathname === `/profile/${currentUser.id}`

  const handleGoToURL = (url) => {
    navigate(url)
  }

  // Hide if user is subscribed and has not performed setup
  // if (isVerifiedUser(currentProfile) && !hasPerformedSetup) {
  //   return null
  // }

  return isMobile ? null : (
    <Tooltip title="Profile">
      <IconButton
        className={profilePath ? classes.iconButtonActive : classes.iconButton}
        onClick={() => handleGoToURL('/profile/' + currentUser.id)}
        size="large">
        <MuiProfileIcon className={classes.icon} role="img" />
      </IconButton>
    </Tooltip>
  )
}

export default ProfileIcon
