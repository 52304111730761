import React, { useState } from 'react'
import { connect } from 'react-redux'
import { deleteAccount } from '../../../../../../../redux/thunks/users/deleteAccount'
import ConfirmationDialog from '../../../../../../dialogs/dialog-confirmation'
import FormError from '../../../../../../../components/alerts/form-error'
import PropTypes from 'prop-types'
import withStyles from '@mui/styles/withStyles'
import { ExpandMore, ExpandLess } from '@mui/icons-material'
import {
  MoreContainer,
  MoreText,
  CaretIconContainer,
  ActionsContainer,
  Action
} from './styled/account-actions-comps'

const styles = () => ({
  caretIcon: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translateX(-50%) translateY(-50%)'
  }
})

const AccountActions = (props) => {
  const { classes, deleteAccount } = props
  const [moreOpen, setMoreOpen] = useState(false)
  const [deleteDialog, setDeleteDialog] = useState(false)
  const [error, setError] = useState('')

  const handleDeleteAccount = () => {
    deleteAccount().then((data) => {
      if (!data.error) {
        console.log('[SUCCESS]: ', 'Successfully deleted account!')

        setDeleteDialog(false)
        setError('')
      } else {
        console.log('[FAIL]: ', 'Failed to delete account.')

        setError(data.message)
      }
    })
  }

  return (
    <div style={{ marginTop: '1rem' }}>
      <ConfirmationDialog
        open={deleteDialog}
        primaryAction={handleDeleteAccount}
        secondaryAction={() => setDeleteDialog(false)}
        primaryText={'Confirm'}
        secondaryText={'Cancel'}
        title="Are you sure?"
        body={
          <div>
            <p>
              Upon deleting your account, your subscription will be canceled and all personal
              information will be wiped from our community.
            </p>

            <p style={{ marginTop: '1rem' }}>
              Are you sure you want to delete your account? This action cannot be undone.
            </p>
          </div>
        }
      />

      <FormError message={error} />

      <MoreContainer onClick={() => setMoreOpen(!moreOpen)}>
        <MoreText>
          More{' '}
          <CaretIconContainer>
            {moreOpen ? (
              <ExpandLess className={classes.caretIcon} />
            ) : (
              <ExpandMore className={classes.caretIcon} />
            )}
          </CaretIconContainer>
        </MoreText>
      </MoreContainer>

      {moreOpen && (
        <ActionsContainer>
          <Action onClick={() => setDeleteDialog(true)}>Delete Account</Action>
        </ActionsContainer>
      )}
    </div>
  )
}

AccountActions.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapDispatchToProps = (dispatch) => ({
  deleteAccount: (params) => {
    return dispatch(deleteAccount(params))
  }
})

export default connect(null, mapDispatchToProps)(withStyles(styles)(AccountActions))
