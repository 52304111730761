import React from 'react'
import { useSelector } from 'react-redux'
import { Routes, Route } from 'react-router-dom'
import './main.css'
import Landing from './landing/landing'
import HomePrivate from './home-private-new/home-private'
import VerificationSteps from './verification-steps/verification-steps'
import Profile from './profile/profile'
import Course from './course/course'
import ConnectionsOrganization from './connections-organization/connections-organization'
import Connections from './connections/connections'
import Post from './post/post'
import Tag from './tag/tag'
import DiscussionRoom from './discussion-room-new/discussion-room'
import MyAccount from './my-account/my-account'
import SearchResults from './search-results/search-results'
import Support from './support/support'
import PageNotFound from '../page-not-found/page-not-found'
import Organization from './organization/organization'
import Organizations from './organizations/organizations'
import OrganizationAccount from './organization-account/organization-account'
import MyCourses from './my-courses/my-courses'
import TermsOfUse from './terms-of-use/terms-of-use'
import Report from './reports/report'
import CourseSetup from './course-setup/course-setup'
import OrganizationAdmin from './organization-admin/organization-admin'

// const CustomRoute = ({ component: Component, hasAcceptedTerms, isAuthenticated, ...rest }) => (
//   <Route
//     {...rest}
//     render={(props) => {
//       if (!isAuthenticated) {
//         return <Redirect to="/" />
//       } else if (isAuthenticated && !hasAcceptedTerms && rest.path !== '/terms-of-use') {
//         return <Redirect to="/terms-of-use" />
//       } else {
//         return <Component {...props} />
//       }
//     }}
//   />
// )

function Main() {
  const isAuthenticated = useSelector((state) => state.isAuthenticated)
  const currentProfile = useSelector((state) => state.currentProfile)

  const mainClass = isAuthenticated ? 'mainLoggedIn' : 'mainNotLoggedIn'
  const { hasAcceptedTerms } = currentProfile

  // console.log('hasAcceptedTerms', hasAcceptedTerms)
  console.log('*** isAuthenticated ***', isAuthenticated)

  return (
    <main id="main-content" className={mainClass}>
      {!isAuthenticated ? (
        <>
          {console.log('Rendering unauthenticated routes')}
          <Routes>
            <Route path="/" element={<Landing />} />
          </Routes>
        </>
      ) : (
        <>
          {console.log('Rendering authenticated routes')}
          <Routes>
            <Route path="/" element={<Landing />} />
            <Route path="/terms-of-use" element={<TermsOfUse />} />
            <Route path="/home">
              <Route index element={<HomePrivate />} />
              <Route path=":action" element={<HomePrivate />} />
              <Route path=":action/:tagId" element={<HomePrivate />} />
              <Route path="topic/:tagId" element={<HomePrivate />} />
            </Route>
            <Route path="/verify" element={<VerificationSteps />} />
            <Route path="/profile/:userId" element={<Profile />} />
            <Route path="/course/:courseId" element={<Course />} />
            <Route path="/course-setup/:courseId" element={<CourseSetup />} />
            <Route
              path="/connections/organization/:organizationId"
              element={<ConnectionsOrganization />}
            />
            <Route path="/connections/:userId/:filter" element={<Connections />} />
            <Route path="/post/:takeId/:discussionId" element={<Post />} />
            <Route path="/topic/:tagId" element={<Tag />} />
            <Route path="/post/:takeId" element={<Post />} />
            <Route path="/post/:takeId/:discussionId" element={<Post />} />
            <Route path="/discussion-room" element={<DiscussionRoom />} />
            <Route path="/my-account/:tab" element={<MyAccount />} />
            <Route path="/my-account" element={<MyAccount />} />
            <Route path="/search/:query" element={<SearchResults />} />
            <Route path="/support" element={<Support />} />
            <Route path="/organization/:orgId/settings/:tab" element={<OrganizationAccount />} />
            <Route path="/organization/:orgId" element={<Organization />} />
            <Route path="/organizations" element={<Organizations />} />
            <Route path="/organization-admin/:tab" element={<OrganizationAdmin />} />
            <Route path="/my-courses" element={<MyCourses />} />
            <Route path="/report/:courseId" element={<Report />} />
            <Route path="/page-not-found" element={<PageNotFound />} />
          </Routes>
        </>
      )}
    </main>
  )
}

export default Main

{
  /* <CustomRoute
          exact
          path="/verify"
          component={VerificationSteps}
          isAuthenticated={isAuthenticated}
          hasAcceptedTerms={hasAcceptedTerms}
        />
        <CustomRoute
          exact
          path="/profile/:userId"
          component={Profile}
          isAuthenticated={isAuthenticated}
          hasAcceptedTerms={hasAcceptedTerms}
        />
        <CustomRoute
          exact
          path="/course/:courseId"
          component={Course}
          isAuthenticated={isAuthenticated}
          hasAcceptedTerms={hasAcceptedTerms}
        />
        <CustomRoute
          exact
          path="/course-setup/:courseId?"
          component={CourseSetup}
          isAuthenticated={isAuthenticated}
          hasAcceptedTerms={hasAcceptedTerms}
        />
        <CustomRoute
          exact
          path="/connections/organization/:organizationId"
          component={ConnectionsOrganization}
          isAuthenticated={isAuthenticated}
          hasAcceptedTerms={hasAcceptedTerms}
        />
        <CustomRoute
          exact
          path="/connections/:userId/:filter?"
          component={Connections}
          isAuthenticated={isAuthenticated}
          hasAcceptedTerms={hasAcceptedTerms}
        />
        <CustomRoute
          exact
          path="/post/:takeId/:discussionId?"
          component={Post}
          isAuthenticated={isAuthenticated}
          hasAcceptedTerms={hasAcceptedTerms}
        />
        <CustomRoute
          exact
          path="/topic/:tagId?"
          component={Tag}
          isAuthenticated={isAuthenticated}
          hasAcceptedTerms={hasAcceptedTerms}
        />
        <CustomRoute
          path="/discussion-room"
          component={DiscussionRoom}
          isAuthenticated={isAuthenticated}
          hasAcceptedTerms={hasAcceptedTerms}
        />
        <CustomRoute
          exact
          path="/my-account/:tab?/:step?"
          component={MyAccount}
          isAuthenticated={isAuthenticated}
          hasAcceptedTerms={hasAcceptedTerms}
        />
        <CustomRoute
          exact
          path="/search/:query?"
          component={SearchResults}
          isAuthenticated={isAuthenticated}
          hasAcceptedTerms={hasAcceptedTerms}
        />
        <CustomRoute
          exact
          path="/support"
          component={Support}
          isAuthenticated={isAuthenticated}
          hasAcceptedTerms={hasAcceptedTerms}
        />
        <CustomRoute
          exact
          path="/organization/:orgId/settings/:tab/:refreshType?"
          component={OrganizationAccount}
          isAuthenticated={isAuthenticated}
          hasAcceptedTerms={hasAcceptedTerms}
        />
        <CustomRoute
          exact
          path="/organization/:orgId"
          component={Organization}
          isAuthenticated={isAuthenticated}
          hasAcceptedTerms={hasAcceptedTerms}
        />
        <CustomRoute
          exact
          path="/organizations"
          component={Organizations}
          isAuthenticated={isAuthenticated}
          hasAcceptedTerms={hasAcceptedTerms}
        />
        <CustomRoute
          path="/organization-admin"
          component={OrganizationAdmin}
          isAuthenticated={isAuthenticated}
          hasAcceptedTerms={hasAcceptedTerms}
        />
        <CustomRoute
          exact
          path="/my-courses"
          component={MyCourses}
          isAuthenticated={isAuthenticated}
          hasAcceptedTerms={hasAcceptedTerms}
        />
        <CustomRoute
          exact
          path="/report/:courseId"
          component={Report}
          isAuthenticated={isAuthenticated}
          hasAcceptedTerms={hasAcceptedTerms}
        />
        <CustomRoute
          exact
          path="/page-not-found"
          component={PageNotFound}
          isAuthenticated={isAuthenticated}
          hasAcceptedTerms={hasAcceptedTerms}
        /> */
}
