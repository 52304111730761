import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'

function TermsDocumentsDisplay({ termsVersion }) {
  const [termsContent, setTermsContent] = useState('')
  const [privacyContent, setPrivacyContent] = useState('')
  const [copyrightContent, setCopyrightContent] = useState('')
  const { termsOfServiceFileUrl, privacyPolicyFileUrl, copyrightPolicyFileUrl } = termsVersion

  useEffect(() => {
    const fetchTermsContent = async () => {
      try {
        const response = await fetch(termsOfServiceFileUrl)
        const html = await response.text()
        setTermsContent(html)
      } catch (error) {
        console.error('Error fetching HTML content:', error)
      }
    }

    const fetchPrivacyContent = async () => {
      try {
        const response = await fetch(privacyPolicyFileUrl)
        const html = await response.text()
        setPrivacyContent(html)
      } catch (error) {
        console.error('Error fetching HTML content:', error)
      }
    }

    const fetchCopyrightContent = async () => {
      try {
        const response = await fetch(copyrightPolicyFileUrl)
        const html = await response.text()
        setCopyrightContent(html)
      } catch (error) {
        console.error('Error fetching HTML content:', error)
      }
    }

    fetchTermsContent()
    fetchPrivacyContent()
    fetchCopyrightContent()
  }, [termsOfServiceFileUrl, privacyPolicyFileUrl, copyrightPolicyFileUrl])

  return (
    <MuiBoxOuter>
      <MuiBoxInner>
        <StyledContent dangerouslySetInnerHTML={{ __html: termsContent }} />
      </MuiBoxInner>
      <MuiBoxInner>
        <StyledContent dangerouslySetInnerHTML={{ __html: privacyContent }} />
      </MuiBoxInner>
      <MuiBoxInner>
        <StyledContent dangerouslySetInnerHTML={{ __html: copyrightContent }} />
      </MuiBoxInner>
    </MuiBoxOuter>
  )
}

export default TermsDocumentsDisplay

// ***************************** //
// *** MUI Styled Components *** //
// ***************************** //

const MuiBoxOuter = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '1rem auto 12rem auto',
  maxWidth: '80rem',
  '@media (max-width: 700px)': {
    width: '100%'
  }
}))

const MuiBoxInner = styled(Box)(({ theme }) => ({
  background: theme.palette.common.white,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '0 2rem',
  '@media (max-width: 700px)': {
    margin: '0 auto'
  }
}))

const StyledContent = styled('div')(({ theme }) => ({
  color: theme.palette.text.primary,
  padding: '2rem',
  fontSize: '1rem',
  fontWeight: 400,
  '@media (max-width: 700px)': {
    padding: '1rem'
  },
  h1: {
    fontSize: '2rem',
    fontWeight: 600,
    marginBottom: '1rem',
    '@media (max-width: 700px)': {
      fontSize: '1.75rem',
      textAlign: 'center'
    }
  },
  h2: {
    fontSize: '1.5rem', // text-2xl
    fontWeight: 600, // font-bold
    marginBottom: '0.75rem' // mb-3
  },
  h3: {
    fontSize: '1.25rem', // text-xl
    fontWeight: 600,
    marginBottom: '0.5rem' // mb-2
  },
  p: {
    fontSize: '1.15rem',
    fontWeight: 400,
    marginBottom: '1rem'
  },
  blockquote: {
    paddingLeft: '1rem', // pl-4
    borderLeft: `4px solid ${theme.palette.divider}`, // border-l-4 border-gray-300
    fontStyle: 'italic', // italic
    margin: '1rem 0' // my-4
  },
  ul: {
    listStyleType: 'disc', // list-disc
    listStylePosition: 'inside', // list-inside
    marginLeft: '2rem',
    marginBottom: '1rem' // mb-4
  },
  ol: {
    listStyleType: 'decimal', // list-decimal
    listStylePosition: 'inside', // list-inside
    marginLeft: '2rem',
    marginBottom: '1rem' // mb-4
  },
  li: {
    marginBottom: '0.25rem', // mb-1
    fontSize: '1.15rem',
    fontWeight: 400
  },
  strong: {
    fontWeight: 600 // font-bold
  },
  em: {
    fontStyle: 'italic' // italic
  },
  u: {
    textDecoration: 'underline' // underline
  }
}))
