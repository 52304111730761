import React, { useState, useEffect } from 'react'
import { connect, useSelector } from 'react-redux'
import { updateProfileByUserID } from '../../../../../redux/thunks/profiles/updateProfileByUserID'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import { Switch } from '@mui/material'
import { InputContainer, InputLabel, InputSublabel, SwitchContainer } from './styled/email-comps'

const useStyles = makeStyles(() =>
  createStyles({
    switch: {
      marginLeft: '-.8rem'
    }
  })
)

const EmailSettings = (props) => {
  const classes = useStyles()
  const { updateProfileByUserID } = props
  const currentProfile = useSelector((state) => state.currentProfile)
  const [processing, setProcessing] = useState({ weeklyRecap: false, dailyRecap: false })
  const [emailSettings, setEmailSettings] = useState({
    weeklyRecap: false,
    dailyRecap: false
  })

  useEffect(() => {
    const tmpEmailSettings = currentProfile.emailSettings || {}

    setEmailSettings({ ...tmpEmailSettings })
  }, [currentProfile])

  const handleUpdateProfileByUserID = (e) => {
    const { name, checked } = e.target

    setProcessing({ ...processing, [name]: true })

    updateProfileByUserID({
      fieldsToUpdate: {
        [`emailSettings.${name}`]: checked
      }
    })
      .then((data) => {
        if (!data.error) {
          console.log('[SUCCESS]: ', 'Successfully updated profile by user ID!')
        } else {
          console.log('[FAIL]: ', 'Failed to update profile by user ID.')
        }

        setProcessing({ ...processing, [name]: false })
      })
      .catch((e) => {
        setProcessing({ ...processing, [name]: false })
      })
  }

  return (
    <>
      <InputContainer style={{ marginTop: '0' }}>
        <InputLabel>Receive Weekly Community Emails:</InputLabel>

        <InputSublabel>
          If enabled, you will receive weekly emails regarding <b>community updates</b> and{' '}
          <b>what's trending</b> on CivilTalk.
        </InputSublabel>

        <SwitchContainer>
          <Switch
            color="primary"
            checked={emailSettings.weeklyRecap}
            onChange={handleUpdateProfileByUserID}
            name="weeklyRecap"
            inputProps={{ 'aria-label': 'weeklyRecap-checkbox' }}
            disabled={processing.weeklyRecap}
            className={classes.switch}
          />
        </SwitchContainer>
      </InputContainer>

      <InputContainer>
        <InputLabel>Receive Daily Notification Emails:</InputLabel>

        <InputSublabel>
          If enabled, you will receive daily emails regarding <b>personal account notifications</b>.
        </InputSublabel>

        <SwitchContainer>
          <Switch
            color="primary"
            checked={emailSettings.dailyRecap}
            onChange={handleUpdateProfileByUserID}
            name="dailyRecap"
            inputProps={{ 'aria-label': 'dailyRecap-checkbox' }}
            disabled={processing.dailyRecap}
            className={classes.switch}
          />
        </SwitchContainer>
      </InputContainer>
    </>
  )
}

const mapDispatchToProps = (dispatch) => ({
  updateProfileByUserID: (params) => {
    return dispatch(updateProfileByUserID(params))
  }
})

export default connect(null, mapDispatchToProps)(EmailSettings)
