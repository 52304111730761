// import { Routes, Route, useParams, useLocation } from 'react-router-dom'
import { useNavigate, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import ManageMemberships from './components/manage-memberships'
import useMembershipRoles from '../../../hooks/useMembershipRoles'
import { OuterContainer, Header } from './styled/organization-admin-comps'
import Box from '@mui/material/Box'
import ButtonBase from '@mui/material/ButtonBase'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings'
import ProfileIcon from '@mui/icons-material/AccountCircle'
import SchoolIcon from '@mui/icons-material/School'
import { styled } from '@mui/material/styles'

function OrganizationAdmin() {
  const currentProfile = useSelector((state) => state.currentProfile)
  const navigate = useNavigate()
  const { tab } = useParams()
  // set orgId for different development environments before release to production
  const organizationId =
    import.meta.env.VITE_NODE_ENV === 'production'
      ? '65a587332e5f8e0035efe4a4'
      : '658081a4ce604400356103ba'

  const { isAdmin, isInstructor } = useMembershipRoles(organizationId, currentProfile)

  console.log('isAdmin:', isAdmin)
  console.log('isInstructor:', isInstructor)

  if (!isAdmin && !isInstructor) {
    navigate('/home')
  }

  return (
    <OuterContainer>
      <Header>UConn Dashboard</Header>

      {tab === 'dashboard' && (
        <MuiPaperOuter>
          <Typography
            variant="h4"
            sx={{
              backgroundColor: 'hsl(131, 25%, 75%)',
              padding: '1rem 2rem',
              borderRadius: '0.25rem 0.25rem 0 0',
              fontSize: '1.5rem',
              fontWeight: '500'
            }}>
            Choose from the options below:
          </Typography>
          <MuiPaperInner elevation={0}>
            {isAdmin && (
              <MuiButtonBase onClick={() => navigate('manage-memberships')} disabled={!isAdmin}>
                <ProfileIcon sx={{ fontSize: '4rem', fill: '#333' }} />
                <Typography variant="h5">Manage Memberships</Typography>
              </MuiButtonBase>
            )}
            <MuiButtonBase onClick={() => navigate(`/my-courses?mode=report`)}>
              <SchoolIcon sx={{ fontSize: '4rem' }} />
              <Typography variant="h5">Manage Courses</Typography>
            </MuiButtonBase>
          </MuiPaperInner>
        </MuiPaperOuter>
      )}
      {tab === 'manage-memberships' && <ManageMemberships />}
    </OuterContainer>
  )
}

export default OrganizationAdmin

/** ********************** */
/** Material-UI Components */
/** ********************** */
const MuiPaperOuter = styled(Paper)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  // margin: '1rem 0',
  // padding: '2rem',
  borderRadius: '0.25rem',
  background: '#fff',
  minHeight: '40rem'
}))

const MuiPaperInner = styled(Paper)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '2rem',
  padding: '2rem',
  borderRadius: '0.25rem'
}))

const MuiButtonBase = styled(ButtonBase)(({ theme }) => ({
  display: 'flex',
  gap: '0.5rem',
  backgroundColor: 'hsl(131, 25%, 75%)',
  color: theme.palette.text.primary,
  padding: '4rem',
  borderRadius: '0.25rem',
  height: '10rem',
  width: '18rem',
  minWidth: '18rem'
}))
