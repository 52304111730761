import React, { useRef, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import getName from '../../../../../utils/functions/getName'
import {
  MainContainer,
  Heading,
  Subheading,
  MenuContainer,
  MenuItemContainer,
  MenuItemPositionContainer,
  MenuItemText
} from './styled/header-comps'
import { getResponsiveStyles } from './responsive-styles/header'

const Header = (props) => {
  const { action, setAction } = props
  const mainRef = useRef(null)
  const [didRecognizeRef, setDidRecognizeRef] = useState(false)
  const [componentWidth, setComponentWidth] = useState(0)
  const [responsiveStyles, setResponsiveStyles] = useState(getResponsiveStyles(componentWidth))
  const currentUser = useSelector(({ currentUser }) => currentUser)
  const fullscreen = window.innerWidth <= 892

  const handleSetComponentWidth = () => {
    const width = mainRef.current ? mainRef.current.clientWidth : window.innerWidth

    setComponentWidth(width)
  }

  useEffect(() => {
    window.addEventListener('resize', handleSetComponentWidth)

    return () => {
      window.removeEventListener('resize', handleSetComponentWidth)
    }
  }, [])

  useEffect(() => {
    if (!didRecognizeRef) {
      if (mainRef.current !== null && typeof mainRef.current !== 'undefined') {
        setComponentWidth(mainRef.current.clientWidth)
        setDidRecognizeRef(true)
      }
    }
  }, [mainRef, didRecognizeRef])

  useEffect(() => {
    setResponsiveStyles(getResponsiveStyles(componentWidth))
  }, [componentWidth])

  return (
    <MainContainer
      ref={mainRef}
      padding={responsiveStyles.mainContainer.padding}
      style={fullscreen ? { borderRadius: '0' } : {}}>
      <Heading>
        Hello,{' '}
        {getName(currentUser, 'first', { preventDefaultName: true })
          ? `${getName(currentUser, 'first')}.`
          : ''}
      </Heading>
      <Subheading>What would you like to do today?</Subheading>

      {/** *********************************************************** */}
      {/** ****************** Desktop Display Tabs  ****************** */}
      {/** *********************************************************** */}
      <div style={{ display: responsiveStyles.singleRow.display }}>
        <MenuContainer
          height={responsiveStyles.menuContainer.height}
          marginLeft={responsiveStyles.menuContainer.marginLeft}>
          {/** BROWSE */}
          <MenuItemContainer
            width={responsiveStyles.menuItemContainer.singleRow.width}
            height={
              action === 'activity'
                ? responsiveStyles.menuItemContainer.height.active
                : responsiveStyles.menuItemContainer.height.inactive
            }
            bgColor={
              action === 'activity'
                ? responsiveStyles.menuItemContainer.backgroundColor.active
                : responsiveStyles.menuItemContainer.backgroundColor.inactive
            }
            onClick={() => setAction('activity')}>
            <MenuItemPositionContainer>
              <MenuItemText>Browse</MenuItemText>
            </MenuItemPositionContainer>
          </MenuItemContainer>

          {/** DISCOVER */}
          <MenuItemContainer
            width={responsiveStyles.menuItemContainer.singleRow.width}
            height={
              action === 'discover'
                ? responsiveStyles.menuItemContainer.height.active
                : responsiveStyles.menuItemContainer.height.inactive
            }
            bgColor={
              action === 'discover'
                ? responsiveStyles.menuItemContainer.backgroundColor.active
                : responsiveStyles.menuItemContainer.backgroundColor.inactive
            }
            onClick={() => setAction('discover')}>
            <MenuItemPositionContainer>
              <MenuItemText>Discover</MenuItemText>
            </MenuItemPositionContainer>
          </MenuItemContainer>

          {/** CONNECT */}
          <MenuItemContainer
            width={responsiveStyles.menuItemContainer.singleRow.width}
            height={
              action === 'connect'
                ? responsiveStyles.menuItemContainer.height.active
                : responsiveStyles.menuItemContainer.height.inactive
            }
            bgColor={
              action === 'connect'
                ? responsiveStyles.menuItemContainer.backgroundColor.active
                : responsiveStyles.menuItemContainer.backgroundColor.inactive
            }
            onClick={() => setAction('connect')}>
            <MenuItemPositionContainer>
              <MenuItemText>Connect</MenuItemText>
            </MenuItemPositionContainer>
          </MenuItemContainer>

          {/** MY COURSES */}
          <MenuItemContainer
            width={responsiveStyles.menuItemContainer.singleRow.width}
            height={
              action === 'my-courses'
                ? responsiveStyles.menuItemContainer.height.active
                : responsiveStyles.menuItemContainer.height.inactive
            }
            bgColor={
              action === 'my-courses'
                ? responsiveStyles.menuItemContainer.backgroundColor.active
                : responsiveStyles.menuItemContainer.backgroundColor.inactive
            }
            onClick={() => setAction('my-courses')}>
            <MenuItemPositionContainer>
              <MenuItemText>My Courses</MenuItemText>
            </MenuItemPositionContainer>
          </MenuItemContainer>
        </MenuContainer>
      </div>

      {/** ********************************************************** */}
      {/** ****************** Mobile Display Tabs  ****************** */}
      {/** ********************************************************** */}
      <div style={{ display: responsiveStyles.dualRow.display }}>
        <MenuContainer
          height={responsiveStyles.menuContainer.height}
          marginLeft={responsiveStyles.menuContainer.marginLeft}>
          {/** BROWSE */}
          <MenuItemContainer
            width={responsiveStyles.menuItemContainer.dualRow.width}
            height={
              action === 'activity'
                ? responsiveStyles.menuItemContainer.height.active
                : responsiveStyles.menuItemContainer.height.inactive
            }
            bgColor={
              action === 'activity'
                ? responsiveStyles.menuItemContainer.backgroundColor.active
                : responsiveStyles.menuItemContainer.backgroundColor.inactive
            }
            onClick={() => setAction('activity')}>
            <MenuItemPositionContainer>
              <MenuItemText>Browse</MenuItemText>
            </MenuItemPositionContainer>
          </MenuItemContainer>

          {/** DISCOVER */}
          <MenuItemContainer
            width={responsiveStyles.menuItemContainer.dualRow.width}
            height={
              action === 'discover'
                ? responsiveStyles.menuItemContainer.height.active
                : responsiveStyles.menuItemContainer.height.inactive
            }
            bgColor={
              action === 'discover'
                ? responsiveStyles.menuItemContainer.backgroundColor.active
                : responsiveStyles.menuItemContainer.backgroundColor.inactive
            }
            onClick={() => setAction('discover')}>
            <MenuItemPositionContainer>
              <MenuItemText>Discover</MenuItemText>
            </MenuItemPositionContainer>
          </MenuItemContainer>

          {/** CONNECT */}
          <MenuItemContainer
            width={responsiveStyles.menuItemContainer.dualRow.width}
            height={
              action === 'connect'
                ? responsiveStyles.menuItemContainer.height.active
                : responsiveStyles.menuItemContainer.height.inactive
            }
            bgColor={
              action === 'connect'
                ? responsiveStyles.menuItemContainer.backgroundColor.active
                : responsiveStyles.menuItemContainer.backgroundColor.inactive
            }
            onClick={() => setAction('connect')}>
            <MenuItemPositionContainer>
              <MenuItemText>Connect</MenuItemText>
            </MenuItemPositionContainer>
          </MenuItemContainer>

          {/** MY COURSES */}
          <MenuItemContainer
            width={responsiveStyles.menuItemContainer.dualRow.width}
            height={
              action === 'my-courses'
                ? responsiveStyles.menuItemContainer.height.active
                : responsiveStyles.menuItemContainer.height.inactive
            }
            bgColor={
              action === 'my-courses'
                ? responsiveStyles.menuItemContainer.backgroundColor.active
                : responsiveStyles.menuItemContainer.backgroundColor.inactive
            }
            onClick={() => setAction('my-courses')}>
            <MenuItemPositionContainer>
              <MenuItemText>My Courses</MenuItemText>
            </MenuItemPositionContainer>
          </MenuItemContainer>
        </MenuContainer>
      </div>
    </MainContainer>
  )
}

export default Header
