import { useState, useEffect } from 'react' // TODO: useRef
import { useSelector, useDispatch } from 'react-redux'
import _ from 'lodash'
import CourseAvatar from '../header/components/course-avatar'
import useMediaQuery from '@mui/material/useMediaQuery'

import {
  MainContainer,
  DetailsContainer,
  Name,
  Location,
  DropDownButtonContainer
} from '../header/styled/header-comps'

function Header(props) {
  const { course, isCurrentUser } = props
  const [open, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const dispatch = useDispatch()
  const matches = useMediaQuery('(min-width:500px)')

  return (
    <MainContainer>
      <CourseAvatar />

      <DetailsContainer>
        <Name>University of Connecticut</Name>
        <Name>{course.title}</Name>
        <Location>
          {course.season} {course.year}
        </Location>
      </DetailsContainer>
    </MainContainer>
  )
}

export default Header
