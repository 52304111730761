import { combineReducers } from 'redux'
import accoladesReducer from './reducers/accolades.js'
import myAccoladesReducer from './reducers/myAccolades.js'
import actionResponseReducer from './reducers/action-response.js'
import appLoadedReducer from './reducers/app-loaded.js'
import assignedRolesReducer from './reducers/assigned-roles'
import categoriesReducer from './reducers/categories.js'
import bulletinsReducer from './reducers/bulletins.js'
import currentProfileReducer from './reducers/current-profile.js'
import currentUserReducer from './reducers/current-user.js'
import currentUserAcceptedTermsReducer from './reducers/current-user-accepted-terms.js'
import firebaseAuthReducer from './reducers/firebase-auth.js'
import { firebaseReducer } from 'react-redux-firebase'
import { firestoreReducer } from 'redux-firestore'
import followsReducer from './reducers/follows.js'
import isAuthenticatedReducer from './reducers/is-authenticated.js'
import inviteFriendsReducer from './reducers/invite-friends.js'
import loadedDiscussionsReducer from './reducers/loaded-discussions.js'
import loadedProfilesReducer from './reducers/loaded-profiles.js'
import loadedRebuttalsReducer from './reducers/loaded-rebuttals.js'
import loadedTakesReducer from './reducers/loaded-takes.js'
import loadedTopicsReducer from './reducers/loaded-topics.js'
import loaderReducer from './reducers/loader.js'
import mobileDrawerReducer from './reducers/mobile-drawer.js'
import myCoursesReducer from './reducers/my-courses.js'
import myNotificationsReducer from './reducers/my-notifications.js'
import notificationOrganizationsReducer from './reducers/notification-organizations.js'
import notificationProfilesReducer from './reducers/notification-profiles.js'
import outgoingDiscussionsReducer from './reducers/outgoing-discussions.js'
import organizationsReducer from './reducers/organizations.js'
import pollsReducer from './reducers/polls.js'
import pollVotesReducer from './reducers/poll-votes.js'
import popularTagsReducer from './reducers/popular-tags.js'
import postDraftReducer from './reducers/post-draft.js'
import refreshPromiseReducer from './reducers/refresh-promise.js'
import relatedTagsReducer from './reducers/related-tags.js'
import rolesReducer from './reducers/roles'
import searchBarResultsReducer from './reducers/search-bar-results.js'
import searchResultsReducer from './reducers/search-results.js'
import suggestedProfilesReducer from './reducers/suggested-profiles.js'
import tagResultsReducer from './reducers/tag-results.js'
import testimonialsReducer from './reducers/testimonials.js'
import tokenExpReducer from './reducers/token-exp.js'
import tokensTransactionModalReducer from './reducers/tokens-transaction-modal.js'
import verificationAttemptsReducer from './reducers/verification-attempts.js'
import videosReducer from './reducers/videos.js'
import suggestedOrganizationsReducer from './reducers/suggested-organizations.js'
import transActionHistoryReducer from './reducers/transAction-history.js'
import OrganizationtransActionHistoryReducer from './reducers/organization-transaction-history.js'
import organizationsPostsReducer from './reducers/organization-posts.js'
import connectOrganizationsReducer from './reducers/connect-organizations.js'
// import myCourses from '../components/main/my-courses/my-courses.jsx'
import termsVersionsReducer from './reducers/terms-versions.js'

// Combine All Reducers
const reducer = combineReducers({
  accolades: accoladesReducer,
  myAccolades: myAccoladesReducer,
  actionResponse: actionResponseReducer,
  appLoaded: appLoadedReducer,
  assignedRoles: assignedRolesReducer,
  categories: categoriesReducer,
  bulletins: bulletinsReducer,
  currentProfile: currentProfileReducer,
  currentUser: currentUserReducer,
  currentUserAcceptedTerms: currentUserAcceptedTermsReducer,
  firebaseAuth: firebaseAuthReducer,
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  follows: followsReducer,
  isAuthenticated: isAuthenticatedReducer,
  inviteFriends: inviteFriendsReducer,
  loadedDiscussions: loadedDiscussionsReducer,
  loadedProfiles: loadedProfilesReducer,
  loadedRebuttals: loadedRebuttalsReducer,
  loadedTakes: loadedTakesReducer,
  loadedTopics: loadedTopicsReducer,
  loader: loaderReducer,
  mobileDrawer: mobileDrawerReducer,
  myCourses: myCoursesReducer,
  myNotifications: myNotificationsReducer,
  notificationOrganizations: notificationOrganizationsReducer,
  notificationProfiles: notificationProfilesReducer,
  outgoingDiscussions: outgoingDiscussionsReducer,
  organizations: organizationsReducer,
  polls: pollsReducer,
  pollVotes: pollVotesReducer,
  popularTags: popularTagsReducer,
  postDraft: postDraftReducer,
  refreshPromise: refreshPromiseReducer,
  relatedTags: relatedTagsReducer,
  roles: rolesReducer,
  searchBarResults: searchBarResultsReducer,
  searchResults: searchResultsReducer,
  suggestedProfiles: suggestedProfilesReducer,
  suggestedOrganizations: suggestedOrganizationsReducer,
  tagResults: tagResultsReducer,
  termsVersions: termsVersionsReducer,
  testimonials: testimonialsReducer,
  tokenExp: tokenExpReducer,
  tokensTransactionModal: tokensTransactionModalReducer,
  verificationAttempts: verificationAttemptsReducer,
  videos: videosReducer,
  transActionHistory: transActionHistoryReducer,
  Org_transActionHistory: OrganizationtransActionHistoryReducer,
  organizationsPosts: organizationsPostsReducer,
  connectOrganizations: connectOrganizationsReducer
})

export default reducer
