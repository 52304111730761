//////////////////
// ACTION TYPES //
//////////////////

export const currentUserAcceptedTermsTypes = {
  SET_TERMS: 'SET_TERMS',
  UPDATE_TERMS: 'UPDATE_TERMS',
  CLEAR_TERMS: 'CLEAR_TERMS'
}

/////////////
// ACTIONS //
/////////////

const set = (currentUserTerms) => {
  return {
    type: currentUserAcceptedTermsTypes.SET_TERMS,
    currentUserTerms
  }
}

const update = (currentUserTerms) => {
  return {
    type: currentUserAcceptedTermsTypes.UPDATE_TERMS,
    currentUserTerms
  }
}

const clear = () => {
  return {
    type: currentUserAcceptedTermsTypes.CLEAR_TERMS
  }
}

export const currentUserAcceptedTermsActions = {
  set,
  update,
  clear
}
