import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import find from 'lodash/find'
import { updateOrganizationByID } from '../../../../../redux/thunks/organizations/updateOrganizationByID'
import updateProfileValidation from './validations/update-profile'
import FormError from '../../../../../components/alerts/form-error'
import TagsSearch from '../../../../partials/tags-search/tags-search'
import Tags from '../../../../partials/tags/tags'
import makeStyles from '@mui/styles/makeStyles'
import Button from '@mui/material/Button'
import {
  ItemContainer,
  InputLabel,
  InputsContainer,
  Textarea,
  TextCount
} from './styled/organization-details-comps'

const useStyles = makeStyles((theme) => ({
  button: {
    textTransform: 'capitalize',
    display: 'block',
    width: '50%',
    margin: '0 auto',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.light
    },
    '@media (max-width: 500px)': {
      width: '100%'
    }
  }
}))

function OrganizationDetails() {
  const classes = useStyles()
  const { orgId } = useParams()
  const [about, setAbout] = useState('')
  const [missionAndVision, setMissionAndVision] = useState('')
  const [tags, setTags] = useState([])
  const [error, setError] = useState('')
  const [field, setField] = useState('')
  const organizations = useSelector(({ organizations }) => organizations)
  const organization = find(organizations, { _id: orgId })
  const dispatch = useDispatch()

  useEffect(() => {
    if (organization) {
      setAbout(organization.about)
      setMissionAndVision(organization.missionAndVision)
      setTags([...organization.tagIds])
    }
  }, [organization])

  const handleAbout = (e) => {
    setAbout(e.target.value)
  }

  const handleMissionAndVision = (e) => {
    setMissionAndVision(e.target.value)
  }

  const handleTags = (tags) => {
    setTags(tags)
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    const validationError = updateProfileValidation({
      about,
      missionAndVision,
      tags
    })

    if (validationError.error !== error) {
      console.log('[VALIDATION ERROR]: ', validationError.error)

      setError(validationError.error)
      setField(validationError.field)
    } else {
      let fieldsToUpdate = {}

      if (organization.about !== about) {
        fieldsToUpdate.about = about
      }

      if (organization.missionAndVision !== missionAndVision) {
        fieldsToUpdate.missionAndVision = missionAndVision
      }

      if (organization.tagIds.length !== tags.length) {
        fieldsToUpdate.tagIds = tags
      }

      if (Object.keys(fieldsToUpdate).length > 0) {
        dispatch(
          updateOrganizationByID({
            fieldsToUpdate,
            organizationId: orgId
          })
        )
          .then((data) => {
            if (!data?.error) {
              console.log('[SUCCESS]: ', 'Successfully updated organization details.')
            } else {
              console.log('[FAIL]: ', data)

              setError(data?.error)
            }
          })
          .catch((error) => {
            console.log('Error', error)

            setError(error)
          })
      } else {
        setError("You haven't made any changes yet.")
      }
    }
  }

  if (!organization) return null

  return (
    <form onSubmit={handleSubmit}>
      <ItemContainer style={{ marginTop: '0' }}>
        <InputLabel style={{ verticalAlign: 'top' }}>Mission and Vision:</InputLabel>
        <InputsContainer>
          {field === 'missionAndVision' && <FormError message={error} />}
          <Textarea
            name="missionAndVision"
            value={missionAndVision}
            wrap="soft"
            maxLength="750"
            onChange={handleMissionAndVision}
          />
          <TextCount>{`${missionAndVision.length} / 750`}</TextCount>
        </InputsContainer>
      </ItemContainer>

      <ItemContainer>
        <InputLabel style={{ verticalAlign: 'top' }}>About:</InputLabel>
        <InputsContainer>
          {field === 'about' && <FormError message={error} />}
          <Textarea name="about" value={about} wrap="soft" maxLength="750" onChange={handleAbout} />
          <TextCount>{`${about.length} / 750`}</TextCount>
        </InputsContainer>
      </ItemContainer>

      <ItemContainer>
        <InputLabel>Tags:</InputLabel>

        <InputsContainer>
          <TagsSearch
            tagIds={tags}
            setTagIds={handleTags}
            style={{ marginTop: '0' }}
            variant={'transparent'}
          />
          <Tags tagIds={tags} setTagIds={handleTags} hideTagAdd={true} />
        </InputsContainer>
      </ItemContainer>

      <ItemContainer>
        <Button type="submit" variant="contained" color="primary" className={classes.button}>
          Save Changes
        </Button>
      </ItemContainer>
    </form>
  )
}

export default OrganizationDetails
