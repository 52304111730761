import React, { useState, useEffect, useCallback, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import _ from 'lodash'
import load from './functions/load'
import { getRelatedTags } from '../../../redux/thunks/tags/getRelatedTags'
import arraysEqual from '../../../utils/functions/arraysEqual'
import Tags from '../../partials/tags/tags'
import {
  MainContainer,
  HeadingPrimary,
  //   HeadingSecondary,
  Subheading
} from './styled/related-tags-comps'

// This is used to prevent reloading of the relatedTags component when tagIds
// is updated and the values of array are the same.
function usePrevious(value) {
  const ref = useRef()

  useEffect(() => {
    ref.current = value
  })

  return ref.current
}

function RelatedTags(props) {
  const {
    bgColor,
    color,
    tagIds,
    setTagIds,
    setError,
    limit,
    hideHeader,
    hideReferenceTag,
    hideOnNoResults,
    enableFullClick,
    customHeading,
    style,
    tagStyle
  } = props
  const newStyles = style || {}
  const newTagStyles = tagStyle || {}
  const [tags, setTags] = useState(tagIds ? [...tagIds] : [])
  const prevTags = usePrevious(tags)
  const [isLoading, setIsLoading] = useState(true)
  const relatedTags = useSelector(({ relatedTags }) => relatedTags)
  const dispatch = useDispatch()

  const loadRelatedTags = useCallback(
    (tags) => {
      const loadActions = {
        getRelatedTags: (params) => dispatch(getRelatedTags(params))
      }

      load(tags, limit, setIsLoading, setTags, loadActions)
    },
    [dispatch, limit]
  )

  useEffect(() => {
    if (isLoading) {
      loadRelatedTags([...tags])
    }
  }, [isLoading, loadRelatedTags, tags])

  useEffect(() => {
    if (!isLoading) {
      if (tagIds) {
        if (!arraysEqual(tagIds, prevTags)) {
          loadRelatedTags([...tagIds])
        }
      }
    }
  }, [isLoading, loadRelatedTags, prevTags, tagIds])

  const convertedTags = relatedTags.map((tag) => tag.tagId)

  const filteredTags = _.filter(convertedTags, (tag) => {
    if (_.includes(tagIds, tag)) {
      return false
    } else {
      return true
    }
  })

  if (isLoading) return null

  if (!isLoading && hideOnNoResults && filteredTags.length === 0) return null

  return (
    <MainContainer style={{ ...newStyles }}>
      {!customHeading && !hideHeader && <HeadingPrimary>Top Related Topics</HeadingPrimary>}
      {customHeading}

      {relatedTags.length > 0 && (
        <>
          {!hideReferenceTag && (
            <Tags
              tagIds={tags || []}
              style={hideHeader ? {} : { marginTop: '.5rem' }}
              tagStyle={{ padding: '.4rem .5rem' }}
              bgColor={bgColor}
              color={color}
            />
          )}

          <Tags
            existingTagIds={tagIds}
            tagIds={filteredTags}
            setTagIds={setTagIds}
            setError={setError}
            hideTagRemove={true}
            enableFullClick={enableFullClick}
            // style={{ marginTop: '.5rem' }}
            style={hideReferenceTag && !hideHeader ? { marginTop: '.5rem' } : {}}
            tagStyle={{ ...newTagStyles, padding: '.4rem .5rem' }}
            bgColor={bgColor}
            color={color}
          />
        </>
      )}

      {relatedTags.length === 0 && (
        <Subheading style={{ marginTop: '1.5rem' }}>No related tags found</Subheading>
      )}
    </MainContainer>
  )
}

export default RelatedTags
